import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  gap: 8px !important;
  flex-basis: 100%;

  .section {
    flex-basis: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .header {
      width: fit-content;
      color: #d9bfd4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-basis: 50%;

      .separator {
        background: #867083;
        height: 1px;
        width: 100%;
      }

      .list-item-onyxia {
        border-radius: 4px;
        background: #483646;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 8px;

        .list-item-text {
          overflow: hidden;
          color: #fff;
          text-overflow: ellipsis;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
`
