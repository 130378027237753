import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const SearchSelect = styled(Box)`
  border-radius: 4px;
  border: 1px solid #998d96;
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;

  &:hover {
    cursor: text;
  }

  .svg-dropdown {
    width: 24px;
    height: 24px;

    path {
      fill: #fff;
    }

    position: absolute;
    top: 8px;
    right: 8px;
  }

  .MuiTypography-root {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
