import { CPISeverity } from './CPILibrary'
import { CPIManifest } from './Manifest'

export interface InsightDataRedirectionParam {
  cpi_name: string
  section: CPISeverity | null
  frequency: string
  time_period: string
}

export interface InsightDataInstance {
  id: string
  logo: string
  name: string
}

export interface InsightTrendsItemValue {
  date: string
  value: number
}

export interface InsightTrendsItem {
  value: number
  end_date: string
  start_date: string
  values?: InsightTrendsItemValue[]
}

export interface InsightPredictionItem {
  end_date: string
  start_date: string
  values?: InsightTrendsItemValue[]
}

export interface InsightData {
  title: string
  section: CPISeverity | null
  section_card_display_name: string | null
  cpi_name: string
  cpi_display_name: string
  description: string
  instances: InsightDataInstance[]
  header: string
  change_value: number
  sla_value: number
  number_of_weeks_in_previous_zone: number
  comparison_period_end_date: string | null
  comparison_period_start_date: string | null
  card_image: string
  card_icon: string
  redirection_params: string
  cpi_ui_trend_chart_areas: [
    {
      color: string
      range: string
    },
    {
      color: string
      range: string
    }
  ]
  cpi_ui_trend_chart_unit: string
  insight_date: string | null
  section_card_label: string
  missing_coverage?: string[]
  missing_coverage_text?: string
  trend_data?: InsightTrendsItem[]
  prediction_trend_data?: InsightPredictionItem[]
  sla: number
}

export enum InsightSubType {
  Improvement = 'improvement',
  Reduction = 'reduction',
  CrossedIntoRed = 'cross_into_red',
  CrossedIntoGreen = 'cross_into_green',
  CpiSlaOptimization = 'cpi_sla_optimization',
  MissingAssetTypeCoverage = 'missing_asset_type_coverage',
  MissingCategoryCoverage = 'missing_category_coverage',
  MissingStrategicCoverage = 'missing_strategic_coverage'
}

export interface Insight {
  id: string
  type: string
  sub_type: string
  created_at: string
  data?: InsightData
  read: boolean
}

export enum InsightSortBy {
  InsightDate = 'insight_date',
  CPIName = 'cpi_name',
  Type = 'type'
}
