import { FC } from 'react'
import { Typography, IconButton, DialogContent, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { Dialog } from '../framework/confirmDeleteFramework.styles'
import ThemeButton from '../../../../components/buttons/primary/ThemeButton'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

const DisconnectJiraProject: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    if (modalProps?.props?.cancelNavigation) {
      modalProps?.props?.cancelNavigation()
    }
    dispatch(closeModal())
  }

  const cancelChanges = () => {
    if (modalProps?.props?.confirmNavigation) {
      modalProps?.props?.confirmNavigation()
    }

    handleClose()

    if (modalProps?.props?.callback) {
      modalProps.props.callback()
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="enlarge-image-dialog" open={modalProps.open}>
      <Box className="header">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.33337 28.6668H30.6667L16 3.3335L1.33337 28.6668ZM17.3334 24.6668H14.6667V22.0002H17.3334V24.6668ZM17.3334 19.3335H14.6667V14.0002H17.3334V19.3335Z"
            fill="#FFA9FC"
          />
        </svg>

        <Typography className="header-title">Warning</Typography>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: '#FFA9FC',

          '&:hover': {
            background: '#590060',
            svg: {
              path: {
                fill: '#fff'
              }
            }
          }
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography className="content-header">
          Are you sure you want to disconnect this project from Onyxia?
        </Typography>
        <Typography className="content-description">
          Data from Atlassian Jira for CPIs connected to this project will be deleted, and cannot be recovered
        </Typography>
      </DialogContent>

      <Box className="footer">
        <ThemeButton themevariant="secondary" onClick={handleClose} sx={{ minWidth: '95px' }}>
          Cancel
        </ThemeButton>
        <ThemeButton themevariant="primary" onClick={() => cancelChanges()} sx={{ minWidth: '95px' }}>
          Disconnect
        </ThemeButton>
      </Box>
    </Dialog>
  )
}

export default DisconnectJiraProject
