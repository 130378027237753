import { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

/* Utils */
import { IntegrationInstance, CPILibraryItem } from '../../../../../../models'
import { IntegrationsCloseIcon, IntegrationsEditIcon } from '../../../../../components/svg'

/* Components */
import { WarningMessageContainer } from '../integration-card/integrationCard.styles'

interface Props {
  instance: IntegrationInstance
  handleOpenDetails: () => void
  detailsOpen: boolean
  affectedCpis: CPILibraryItem[][]
  index: number
  loading: boolean
  hasFailed?: boolean
}

const InstanceCardContent: FC<Props> = ({ instance, hasFailed, detailsOpen, index, handleOpenDetails, affectedCpis, loading }) => {
  const cpis = affectedCpis.map(n => n.map(n => n.display_name))

  const handleEdit = () => {
    handleOpenDetails()
  }

  const handleClose = () => {
    handleOpenDetails()
  }

  return (
    <Box className="instance-card-wrapper">
      <Box className="description">
        <Typography className="header">{instance.name}</Typography>
        {loading && <Typography className="subheader-empty">a</Typography>}
        {!loading && (
          <>
            {!affectedCpis.length ? (
              <Typography className="subheader">There are no CPIs configured with this instance.</Typography>
            ) : (
              <Typography className="subheader">
                CPIs configured with this instance: {cpis[index] ? cpis[index].join(', ') : ''}
              </Typography>
            )}
          </>
        )}
        {hasFailed && (
          <WarningMessageContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M7.99967 1.33331C4.31967 1.33331 1.33301 4.31998 1.33301 7.99998C1.33301 11.68 4.31967 14.6666 7.99967 14.6666C11.6797 14.6666 14.6663 11.68 14.6663 7.99998C14.6663 4.31998 11.6797 1.33331 7.99967 1.33331ZM8.66634 11.3333H7.33301V9.99998H8.66634V11.3333ZM8.66634 8.66665H7.33301V4.66665H8.66634V8.66665Z" fill="#FF8A80" />
            </svg>
            {/* <Typography className='warning-text'>[Warning Message]</Typography> */}
          </WarningMessageContainer>
        )}
      </Box>
      <Box className="actions">
        {detailsOpen ? (
          <IconButton className="icon-button-close" onClick={handleClose}>
            <IntegrationsCloseIcon />
          </IconButton>
        ) : (
          <IconButton className="icon-button" onClick={handleEdit}>
            <IntegrationsEditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default InstanceCardContent
