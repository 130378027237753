import React, { ChangeEvent, Dispatch, FC, Fragment, SetStateAction, useState } from 'react'
import {
  Box,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import isEqual from 'lodash.isequal'
import axios from '../../../../../../lib/axios'

/* Utils */
import { schema } from '../../../library.constants'
import { Checked } from '../../LibraryDetails'
import {
  ChartData,
  ConfiguredCPI,
  CPILibraryItem,
  CPIManifest,
  CPIManifestUIFormulaParam,
  CPIManifestUIFormulaParamSection,
  IFramework,
  IntegrationInstance,
  IntegrationStatusMessage
} from '../../../../../../models'
import { hasPermission, Permission } from '../../../../../../lib/utils'
import { useAppDispatch } from '../../../../../../store/hooks'
import { closeToast, openToast, ToastVariant } from '../../../../../../store/toasts/toastSlice'
import { ModalType, openModal } from '../../../../../../store/modals/modalSlice'
import { getSlaFullUnit } from '../../../utils'
import { multiplicativeValueCpis } from '../trends-tab/chart-utils'

/* Components */
import { Drawer, IntegrationInstanceCard, IntegrationTextItem } from './settingsDrawer.styles'
import { ThemeButton } from '../../../../../components/buttons'
import {
  InstanceCheckedIcon,
  InstanceUncheckedIcon,
  LibraryTooltipActiveIcon,
  LibraryTooltipIcon
} from '../../../../../components/svg'
import ControlledTextField from '../../../../../components/form-components/ControlledTextField'
import { LibraryDetailsInfoTooltip } from '../../components/LibraryDetailsTooltip'
import DataSourceErrorTooltip from '../../../../../components/data-source-error-tooltip'
import ControlledTrendsChartSelectField from '../../../../../components/form-components/ControlledTrendsChartSelectField'
import ArrowUpTooltip from '../../../../../components/svg/ArrowUpTooltip'
import { tooltipClasses } from '@mui/material/Tooltip'
import { InstanceTooltip } from './InstanceTooltip'

interface Props {
  drawerOpen: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setShowMessage: Dispatch<SetStateAction<boolean>>
  active: boolean
  manifestData: CPIManifest | null
  defaultValues: Record<string, unknown>
  cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  checked: Checked
  getDefaultChecked: (integration: IntegrationInstance) => boolean
  handleChangeInstance: (isChecked: boolean, name: string) => void
  getIntegrationLogo: (inetgrationName: string) => string
  setEmptyInfoTooltipOpen: Dispatch<SetStateAction<boolean>>
  emptyInfoTooltipOpen: boolean
  handleEmptyInfoClick: () => void
  cpi: CPILibraryItem | null
  cpiConfiguration: ConfiguredCPI | null
  userConfiguredIntegrations: IntegrationInstance[]
  setChartData: Dispatch<SetStateAction<ChartData | null>>
  deactivateCpi: () => void
  setTabValue: Dispatch<SetStateAction<number>>
  authUser: any
  fetchCpiConfig: (
    currentCpiName: string,
    manifestData: CPIManifest | null,
    userConfiguredIntegrations: IntegrationInstance[],
    cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  ) => void
  fetchAll: (name?: string) => void
  setCriticalSla: Dispatch<SetStateAction<number>>
  fw: string
  customFramework: IFramework | null
  slaDescriptionTooltipOpen: boolean
  setSlaDescriptionTooltipOpen: Dispatch<SetStateAction<boolean>>
  handleSlaDescriptionTooltipClick: () => void
}

const CPILibrarySettingsDrawer: FC<Props> = ({
  drawerOpen,
  setDrawerOpen,
  active,
  manifestData,
  defaultValues,
  cpiSupportedAndUserConfiguredList,
  checked,
  getDefaultChecked,
  handleChangeInstance,
  getIntegrationLogo,
  setEmptyInfoTooltipOpen,
  emptyInfoTooltipOpen,
  handleEmptyInfoClick,
  cpi,
  cpiConfiguration,
  userConfiguredIntegrations,
  setChartData,
  deactivateCpi,
  setTabValue,
  authUser,
  fetchCpiConfig,
  fetchAll,
  setCriticalSla,
  setShowMessage,
  fw,
  customFramework,
  slaDescriptionTooltipOpen,
  setSlaDescriptionTooltipOpen,
  handleSlaDescriptionTooltipClick
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleDeactivate = () => {
    if (!cpi) return

    const callback = () => {
      setChartData(null)
      setDrawerOpen(false)
      deactivateCpi()
      setTabValue(0)
    }

    const closeback = () => {
      const findItem = cpiSupportedAndUserConfiguredList.find((item) => !item.deleted)

      return dispatch(
        openModal({
          type: ModalType.deleteDeactivation,
          props: {
            integrationName: findItem?.integration_name || '',
            cpiSupportedAndUserConfiguredList,
            manifestData: manifestData,
            callback: () => handleDeactivate()
          }
        })
      )
    }

    dispatch(openModal({ type: ModalType.deactivateCpi, props: { cpiName: cpi.name, callback, closeback } }))
  }

  const handleUpdate = async (values: any) => {
    if (!cpi || !manifestData || !active) return

    const formValues = { ...values }
    for (const [key, value] of Object.entries(values)) {
      formValues[key] = Number(value)
    }
    delete formValues.cpiCategoryValue

    let previousCheckedIntegrationNames: string[] = []
    const configured = cpiConfiguration?.integrations.map((n) => n.id)
    const userConfiguredIntegrationNames = cpiSupportedAndUserConfiguredList.map((n) => n.integration_name)
    if (active && !!configured) {
      const cpiSupportedAndConfigured = cpiConfiguration?.integrations.filter((n) =>
        userConfiguredIntegrationNames.includes(n.name)
      )
      if (cpiSupportedAndConfigured) {
        previousCheckedIntegrationNames = cpiSupportedAndConfigured.map((n) => n.name)
      }
    }

    const checkedIntegrationsNames: string[] = []
    for (const [key, value] of Object.entries(checked)) {
      if (value) {
        checkedIntegrationsNames.push(key)
      }
    }

    if (!checkedIntegrationsNames.length) {
      return handleDeactivate()
    }

    const hasFormValueChanges = !isEqual(defaultValues, formValues)
    const hasIntegrationChanges = !isEqual(previousCheckedIntegrationNames, checkedIntegrationsNames)

    if (!hasFormValueChanges && !hasIntegrationChanges) {
      setSaveDisabled(true)
      return
    }

    const selectedIntegrations =
      checkedIntegrationsNames.length > 0
        ? cpiSupportedAndUserConfiguredList.map((configured) =>
            checkedIntegrationsNames.includes(configured.name) ? configured.id : ''
          )
        : ['']
    const pruned = selectedIntegrations.length > 0 ? selectedIntegrations.filter((n) => n !== '') : selectedIntegrations

    const formulaParams: Array<Pick<CPIManifestUIFormulaParam, 'name' | 'sections' | 'value'>> = []
    const formulaSections: CPIManifestUIFormulaParamSection[] = []

    if (
      (manifestData.ui.trend_chart.user_input.formula_params.length > 0 &&
        manifestData.ui.trend_chart.user_input.formula_params[0].sections.length > 0) ||
      manifestData.ui.trend_chart.user_input.visualization_param.sections.length > 1
    ) {
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'sla') {
          const result: CPIManifestUIFormulaParamSection = {
            name: key,
            value: Number(value)
          }
          formulaSections.push(result)
        }
      }
    }

    if (
      manifestData.ui.trend_chart.user_input.formula_params &&
      manifestData.ui.trend_chart.user_input.formula_params.length > 0
    ) {
      manifestData.ui.trend_chart.user_input.formula_params.forEach((n) => {
        const result: Pick<CPIManifestUIFormulaParam, 'name' | 'sections' | 'value'> = {
          name: n.name,
          sections: formulaSections
        }

        if (n.value) {
          result.value = formValues[n.name]
        }

        formulaParams.push(result)
      })
    }

    const manifestUserInput = {
      formula_params: formulaParams.length > 0 ? formulaParams : undefined,
      visualization_param: {
        identical_section_values: manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values,
        identical_value: manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values
          ? formValues.sla
          : undefined,
        sections: !manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values
          ? formulaSections
          : undefined
      }
    }

    if (!manifestUserInput.formula_params) {
      delete manifestUserInput.formula_params
    }

    /* Refactor into better way to determine which cpis are allowed to have 0 values in SLA */
    const whiteList = ['Training & Awareness', 'Cloud Security', 'Identity & Access Management', 'Detection & Response']
    const allowZeroSLA = cpi.name === 'cpi997' || whiteList.includes(manifestData.categories[0])
    if (!manifestUserInput.visualization_param.identical_value && !allowZeroSLA) {
      delete manifestUserInput.visualization_param.identical_value
    }

    const payloadSections = []
    if (
      !manifestUserInput.visualization_param.identical_section_values &&
      manifestUserInput.visualization_param.sections &&
      manifestUserInput.visualization_param.sections.length > 0
    ) {
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'sla') {
          payloadSections.push({
            name: key,
            value
          })
        }
      }
    }

    if (
      !manifestUserInput.visualization_param.sections ||
      manifestUserInput.visualization_param.identical_section_values
    ) {
      delete manifestUserInput.visualization_param.sections
    }

    const payload = {
      user_input: {
        ...manifestUserInput
      },
      integration_ids: pruned
    }

    try {
      setIsUpdating(true)
      const res = await axios.put(`/api/v3/cpis/${cpi.name}`, payload)

      if (res.status === 200) {
        if (
          res.data.data.user_input?.visualization_param?.sections &&
          res.data.data.user_input.visualization_param.sections.length > 0
        ) {
          const defaultSection = manifestData.ui.trend_chart.user_input.visualization_param?.default_section
          const findItem = res.data.data.user_input.visualization_param.sections.find(
            (item: { name: string }) => item.name === defaultSection
          )
          setCriticalSla(findItem?.value || res.data.data.user_input.visualization_param.sections[1].value)
        } else if (res.data.data.user_input.visualization_param.identical_section_values) {
          setCriticalSla(res.data.data.user_input.visualization_param.identical_value)
        }

        await fetchAll(cpi.name)
        await fetchCpiConfig(cpi.name, manifestData, userConfiguredIntegrations, cpiSupportedAndUserConfiguredList)
        setShowMessage(true)
        setDrawerOpen(false)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Update Successful',
              description: 'Your CPI SLA has been updated.'
            }
          })
        )
      }
    } catch (e) {
      console.error(e)
      let message = 'Failed updating CPI.'
      if ((e as any).response.data.message && (e as any).response.data.message.includes('Invalid uuid')) {
        message = 'Please select at least 1 data source'
      }
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Error',
            description: message
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setIsUpdating(false)
    }
  }

  const handleSubmit = async (values: any) => {
    if (!manifestData || !cpi) return
    setIsUpdating(true)

    if (!active && fw === 'custom' && customFramework) {
      const cat = values.cpiCategoryValue
      try {
        const allCategoriesRes = await axios.get(`/api/v3/frameworks/categories?framework=${customFramework.id}`)

        if (allCategoriesRes.status.toString().includes('20')) {
          const allCategories = allCategoriesRes.data.data.categories

          const found = allCategories.find((n: any) => n.title === cat)

          if (found) {
            const categoryId = found.id

            const cpisRes = await axios.get(
              `/api/v3/frameworks/cpis/${categoryId}/weights?framework=${customFramework.id}`
            )

            if (cpisRes.status.toString().includes('20')) {
              const cpisData = cpisRes.data.data
              const { cpis } = cpisData
              const cpisWeight = cpis.reduce((acc: any, obj: any) => (acc + !isNaN(obj.weight) ? obj.weight : 0), 0)
              const newList = [
                ...cpis,
                {
                  name: manifestData.name,
                  displayName: manifestData.display_name,
                  title: manifestData.title,
                  isActive: true,
                  weight: cpis.some((n: any) => n.weight && n.weight === 100)
                    ? 0
                    : cpisWeight < 100
                    ? 100 - cpisWeight
                    : 0
                }
              ]

              await axios.post(`/api/v3/frameworks/custom/${categoryId}/cpis`, {
                cpis: newList
              })
            }
          }
        }
      } catch (err) {
        console.error(err)
        const message = active ? 'Failed updating CPI' : 'Failed activating CPI.'
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Error',
              description: message
            }
          })
        )

        return
      }
    }

    const formValues = {
      ...values
    }
    for (const [key, value] of Object.entries(values)) {
      if (key !== 'cpiCategoryValue') {
        formValues[key] = Number(value)
      }
    }
    delete formValues.cpiCategoryValue

    const checkedIntegrationsNames: string[] = []
    for (const [key, value] of Object.entries(checked)) {
      if (value) {
        checkedIntegrationsNames.push(key)
      }
    }
    const selectedIntegrations =
      checkedIntegrationsNames.length > 0
        ? userConfiguredIntegrations.map((configured) =>
            checkedIntegrationsNames.includes(configured.name) ? configured.id : ''
          )
        : ['']
    if (selectedIntegrations.length < 0 || (selectedIntegrations.length === 1 && selectedIntegrations[0] === '')) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Error',
            description: 'Please select at least 1 data source'
          }
        })
      )
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setIsUpdating(false)
      return
    }

    const formulaParams: Array<Pick<CPIManifestUIFormulaParam, 'name' | 'sections' | 'value'>> = []
    const formulaSections: CPIManifestUIFormulaParamSection[] = []

    if (
      (manifestData.ui.trend_chart.user_input.formula_params.length > 0 &&
        manifestData.ui.trend_chart.user_input.formula_params[0].sections.length > 0) ||
      manifestData.ui.trend_chart.user_input.visualization_param.sections.length > 1
    ) {
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'sla') {
          const result: CPIManifestUIFormulaParamSection = {
            name: key,
            value: Number(value)
          }
          formulaSections.push(result)
        }
      }
    }
    if (
      manifestData.ui.trend_chart.user_input.formula_params &&
      manifestData.ui.trend_chart.user_input.formula_params.length > 0
    ) {
      manifestData.ui.trend_chart.user_input.formula_params.forEach((n) => {
        const result: Pick<CPIManifestUIFormulaParam, 'name' | 'sections' | 'value'> = {
          name: n.name,
          sections: formulaSections
        }

        if (n.value) {
          result.value = formValues[n.name]
        }

        formulaParams.push(result)
      })
    }

    const manifestUserInput = {
      formula_params: formulaParams.length > 0 ? formulaParams : undefined,
      visualization_param: {
        identical_section_values: manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values,
        identical_value: manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values
          ? formValues.sla
          : undefined,
        sections: !manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values
          ? formulaSections
          : undefined
      }
    }

    if (!manifestUserInput.formula_params) {
      delete manifestUserInput.formula_params
    }
    if ([null, undefined].includes(manifestUserInput.visualization_param.identical_value)) {
      delete manifestUserInput.visualization_param.identical_value
    }
    const payloadSections = []
    if (
      !manifestUserInput.visualization_param.identical_section_values &&
      manifestUserInput.visualization_param.sections &&
      manifestUserInput.visualization_param.sections.length > 0
    ) {
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'sla') {
          payloadSections.push({
            name: key,
            value
          })
        }
      }
    }
    if (
      !manifestUserInput.visualization_param.sections ||
      manifestUserInput.visualization_param.identical_section_values
    ) {
      delete manifestUserInput.visualization_param.sections
    }

    const payload = {
      user_input: {
        ...manifestUserInput
      },
      integration_ids: selectedIntegrations.filter((n) => n !== '')
    }

    try {
      const res = await axios.post(`/api/v3/cpis/${cpi.name}`, payload)

      if (res.status === 201) {
        setDrawerOpen(false)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'CPI Activated',
              description: 'You can now see the CPI details in your library.'
            }
          })
        )
        navigate('/library', { state: { refetchCpis: true } })
      }
    } catch (e) {
      console.error(e)
      let message = active ? 'Failed updating CPI' : 'Failed activating CPI.'
      if ((e as any).response.data.message && (e as any).response.data.message.includes('Invalid uuid')) {
        message = 'Please select at least 1 data source'
      }
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Error',
            description: message
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setIsUpdating(false)
    }
  }

  const renderVisualizationParam = (handleChange: any, errors: any, values: any) => {
    if (!manifestData) return null
    const { user_input: userInput } = manifestData.ui.trend_chart

    if (userInput.visualization_param.identical_section_values) {
      return (
        <>
          <Box className="benchmarks-title-wrapper">
            <Box className="benchmarks-title">
              <Box className="benchmarks-title">
                <Typography className="benchmark-header">
                  {userInput.visualization_param.header} <span>*</span>
                </Typography>
                {userInput.visualization_param.sub_header && (
                  <Typography className="benchmark-subheader">{userInput.visualization_param.sub_header}</Typography>
                )}
              </Box>
              {multiplicativeValueCpis.includes(manifestData.name) && (
                <Box className="benchmarks-title-tooltip-wrapper">
                  <ClickAwayListener onClickAway={() => setSlaDescriptionTooltipOpen(false)}>
                    <div>
                      <LibraryDetailsInfoTooltip
                        PopperProps={{
                          disablePortal: true,
                          sx: {
                            marginTop: '-10px !important'
                          }
                        }}
                        open={slaDescriptionTooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={() => setSlaDescriptionTooltipOpen(false)}
                        title={
                          <>
                            <Box className="tooltip-wrapper">
                              <Typography className="tooltip-text">
                                This configuration is for daily SLAs. Weekly and monthly SLAs will be derived from this
                                value.
                              </Typography>
                            </Box>
                          </>
                        }
                      >
                        <IconButton className="tooltip-icon" onClick={handleSlaDescriptionTooltipClick}>
                          {slaDescriptionTooltipOpen ? <LibraryTooltipActiveIcon /> : <LibraryTooltipIcon />}
                        </IconButton>
                      </LibraryDetailsInfoTooltip>
                    </div>
                  </ClickAwayListener>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="benchmarks-wrapper">
            <Box className="benchmarks-body">
              <Box className="benchmarks-row">
                <ControlledTextField
                  name="sla"
                  helperText={errors.sla}
                  type="number"
                  fullWidth
                  label="SLA"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {typeof values.sla !== 'number' || values.sla < 0
                          ? ''
                          : getSlaFullUnit(userInput.visualization_param.unit)}
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    if (e.target.value) {
                      setSaveDisabled(false)
                    }
                    handleChange(e)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )
    }
    if (!userInput.visualization_param.identical_section_values) {
      return (
        <>
          <Box className="benchmarks-title-wrapper">
            <Box className="benchmarks-title">
              <Typography className="benchmark-header">
                {userInput.visualization_param.header} <span>*</span>
              </Typography>
              {userInput.visualization_param.sub_header && (
                <Typography className="benchmark-subheader">{userInput.visualization_param.sub_header}</Typography>
              )}
            </Box>
            {multiplicativeValueCpis.includes(manifestData.name) && (
              <Box className="benchmarks-title-tooltip-wrapper">
                <ClickAwayListener onClickAway={() => setSlaDescriptionTooltipOpen(false)}>
                  <div>
                    <LibraryDetailsInfoTooltip
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          marginTop: '-10px !important'
                        }
                      }}
                      open={slaDescriptionTooltipOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={() => setSlaDescriptionTooltipOpen(false)}
                      title={
                        <>
                          <Box className="tooltip-wrapper">
                            <Typography className="tooltip-text">
                              This configuration is for daily SLAs. Weekly and monthly SLAs will be derived from this
                              value.
                            </Typography>
                          </Box>
                        </>
                      }
                    >
                      <IconButton className="tooltip-icon" onClick={handleSlaDescriptionTooltipClick}>
                        {slaDescriptionTooltipOpen ? <LibraryTooltipActiveIcon /> : <LibraryTooltipIcon />}
                      </IconButton>
                    </LibraryDetailsInfoTooltip>
                  </div>
                </ClickAwayListener>
              </Box>
            )}
          </Box>
          {userInput.visualization_param.sections.length > 0 && (
            <>
              <Box className="benchmarks-wrapper">
                <Box className="benchmarks-body">
                  {userInput.visualization_param.sections.map((n, i) => (
                    <Box className="benchmarks-row" key={i}>
                      <ControlledTextField
                        name={n.name}
                        helperText={errors[n.name]}
                        type="number"
                        fullWidth
                        label={`${n.display_name} ${userInput.visualization_param.fields_suffix} `}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {typeof values[n.name] !== 'number' || values[n.name] < 0
                                ? ''
                                : getSlaFullUnit(userInput.visualization_param.unit)}
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          if (e.target.value) {
                            setSaveDisabled(false)
                          }
                          handleChange(e)
                        }}
                      />
                      {n.name === 'all' && (
                        <ClickAwayListener onClickAway={() => setEmptyInfoTooltipOpen(false)}>
                          <div>
                            <LibraryDetailsInfoTooltip
                              PopperProps={{
                                disablePortal: true,
                                sx: {
                                  marginTop: '-10px !important'
                                }
                              }}
                              open={emptyInfoTooltipOpen}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              onClose={() => setEmptyInfoTooltipOpen(false)}
                              title={
                                <>
                                  <Box className="tooltip-wrapper">
                                    <Typography className="tooltip-text">
                                      The “All” severity category is a value representing the sum of your data
                                      regardless of its designated severity.
                                      <br />
                                      <br />
                                      For example, data without a designated severity would be included in this count.
                                    </Typography>
                                  </Box>
                                </>
                              }
                            >
                              <IconButton className="tooltip-icon" onClick={handleEmptyInfoClick}>
                                {emptyInfoTooltipOpen ? <LibraryTooltipActiveIcon /> : <LibraryTooltipIcon />}
                              </IconButton>
                            </LibraryDetailsInfoTooltip>
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </>
      )
    }

    return null
  }

  const renderFormulaParams = (handleChange: any, errors: any, values: any) => {
    if (!manifestData) return null
    const { formula_params: formulaParams } = manifestData.ui.trend_chart.user_input
    if (formulaParams.length > 0) {
      return formulaParams.map((param, i) => (
        <Fragment key={i}>
          <Box className="benchmarks-title">
            <Typography className="benchmark-header">
              {param.header} <span>*</span>
            </Typography>
            {param.sub_header && <Typography className="benchmark-subheader">{param.sub_header}</Typography>}
          </Box>
          <Box className="benchmarks-wrapper">
            <Box className="benchmarks-body">
              {param.sections.length > 0 ? (
                <>
                  {param.sections.map((section, idx) => (
                    <Box className="benchmarks-row" key={idx}>
                      <ControlledTextField
                        name={section.name}
                        helperText={errors[section.name]}
                        type="number"
                        fullWidth
                        label={`${section.display_name} ${param.fields_suffix} `}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {!values[section.name] ? '' : getSlaFullUnit(param.unit)}
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          if (e.target.value) {
                            setSaveDisabled(false)
                          }
                          handleChange(e)
                        }}
                      />
                      {section.name === 'all' && (
                        <ClickAwayListener onClickAway={() => setEmptyInfoTooltipOpen(false)}>
                          <div>
                            <LibraryDetailsInfoTooltip
                              PopperProps={{
                                disablePortal: true,
                                sx: {
                                  marginTop: '-10px !important'
                                }
                              }}
                              open={emptyInfoTooltipOpen}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              onClose={() => setEmptyInfoTooltipOpen(false)}
                              title={
                                <>
                                  <Box className="tooltip-wrapper">
                                    <Typography className="tooltip-text">
                                      The “All” severity category is a value representing the sum of your data
                                      regardless of its designated severity.
                                      <br />
                                      <br />
                                      For example, data without a designated severity would be included in this count.
                                    </Typography>
                                  </Box>
                                </>
                              }
                            >
                              <IconButton className="tooltip-icon" onClick={handleEmptyInfoClick}>
                                {emptyInfoTooltipOpen ? <LibraryTooltipActiveIcon /> : <LibraryTooltipIcon />}
                              </IconButton>
                            </LibraryDetailsInfoTooltip>
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>
                  ))}
                </>
              ) : (
                <Box className="benchmarks-row">
                  <ControlledTextField
                    name={param.name}
                    helperText={errors[param.name]}
                    type="number"
                    fullWidth
                    placeholder={`${param.value}` ?? ''}
                    label={`${param.sub_header}`}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {!values[param.name] ? '' : getSlaFullUnit(param.unit)}
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        setSaveDisabled(false)
                      }
                      handleChange(e)
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Fragment>
      ))
    }
    return null
  }

  const changeHandler = (e: ChangeEvent<HTMLInputElement>, integration: any) => {
    const isChecked = e.target.checked

    if (!isChecked && integration.deleted) {
      return dispatch(
        openModal({
          type: ModalType.deselectNotification,
          props: {
            integrationName: integration.name,
            callback: () => updateIntegration(isChecked, integration.name)
          }
        })
      )
    }

    updateIntegration(isChecked, integration.name)

    const filterItems = cpiSupportedAndUserConfiguredList.filter((item) => !item.deleted)
    if (!isChecked && !integration.deleted && filterItems.length === 1) {
      return dispatch(
        openModal({
          type: ModalType.deleteDeactivation,
          props: {
            integrationName: integration.name,
            cpiSupportedAndUserConfiguredList,
            manifestData: manifestData,
            callback: () => handleDeactivate()
          }
        })
      )
    }
  }

  const updateIntegration = (isChecked: boolean, name: string) => {
    setSaveDisabled(false)
    handleChangeInstance(isChecked, name)
  }

  const handleClickAvailableInstance = (instance: string) => {
    navigate(`/settings/integrations/${instance}/add`)
  }

  const getAvailableInstancesText = () => {
    if (!manifestData) return
    const uniqueIntegrationsUserHasConfigured: string[] = []
    userConfiguredIntegrations.forEach((n) => {
      if (!uniqueIntegrationsUserHasConfigured.includes(n.integration_name)) {
        uniqueIntegrationsUserHasConfigured.push(n.integration_name)
      }
    })
    const cpiSupportedNames: string[] = manifestData.supported_integrations.map((n) => n.name)
    let formattedAlsoAvailableWith: string[] = []
    let formattedAndOther: string[] = []

    if (!isEqual(cpiSupportedNames, uniqueIntegrationsUserHasConfigured)) {
      const alsoAvailableWith = cpiSupportedNames.filter((n) => !uniqueIntegrationsUserHasConfigured.includes(n))
      formattedAlsoAvailableWith = alsoAvailableWith.map((n) => n.replace('_', ' '))
      const andOther = cpiSupportedNames.filter((n) => uniqueIntegrationsUserHasConfigured.includes(n))
      formattedAndOther = andOther.map((n) => n.replace('_', ' '))
    }

    return (
      <Box className="also-available-wrapper">
        <Typography className="also-available-text">
          {formattedAlsoAvailableWith.length > 0 && (
            <>
              Available with{' '}
              {formattedAlsoAvailableWith.map((n, index) => (
                <Fragment key={n}>
                  <span
                    className="also-available-link"
                    onClick={() => handleClickAvailableInstance(n.replace(' ', '_').toLowerCase())}
                  >
                    {n}
                  </span>
                  {index < formattedAlsoAvailableWith.length - 1 ? ', ' : ''}
                </Fragment>
              ))}
            </>
          )}

          {formattedAndOther.length > 0 && (
            <>
              {formattedAlsoAvailableWith.length ? <span> &</span> : `Available with`}
              {formattedAndOther.map((n, index) => (
                <Fragment key={n}>
                  <span
                    className="also-available-link"
                    onClick={() => handleClickAvailableInstance(n.replace(' ', '_').toLowerCase())}
                  >
                    {' '}
                    {n}
                  </span>
                  {index === formattedAndOther.length - 2 ? ' & ' : index < formattedAndOther.length - 1 ? ', ' : ''}
                </Fragment>
              ))}{' '}
            </>
          )}
        </Typography>
      </Box>
    )
  }

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box sx={{ width: 270 }} role="presentation">
        <Box className="wrapper-inner">
          <Box className="header">
            <Typography className="title">{active ? 'Configuration Settings ' : 'Activating '}</Typography>
            <IconButton className="close-icon" onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box className="cpi-wrapper">
            <Typography>{manifestData?.display_name}</Typography>
          </Box>

          <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid, values, handleChange, errors }) => (
              <Form autoComplete="off">
                <Box className="content">
                  <>
                    {!active && fw === 'custom' && customFramework && (
                      <Box className="select-category-wrapper">
                        <Box className="select-category-header">
                          <Typography>Select the category you would like to assign the CPI to</Typography>
                        </Box>
                        <Box className="select-category-container">
                          <Typography className="select-category-text">Category</Typography>
                          <ControlledTrendsChartSelectField
                            options={[...customFramework.data.functions.map((n) => n.title)]}
                            name="cpiCategoryValue"
                            label="Select"
                            onChange={(e) => handleChange(e)}
                            disabled={false}
                            minWidth="121px !important"
                            hideSelected
                          />
                        </Box>
                      </Box>
                    )}

                    <Typography className="text">
                      Select data source(s):
                      <Typography className="red-required">*</Typography>
                    </Typography>
                    {getAvailableInstancesText()}
                    {isUpdating ? (
                      <CircularProgress size="16px" color="secondary" />
                    ) : (
                      cpiSupportedAndUserConfiguredList.length > 0 &&
                      cpiSupportedAndUserConfiguredList.map((integration) => {
                        return (
                          <IntegrationInstanceCard
                            key={integration.name}
                            isFailed={
                              integration.syncStatus &&
                              integration.syncStatus.status === IntegrationStatusMessage.failed
                            }
                          >
                            <Checkbox
                              inputProps={{ 'aria-label': 'Checkbox' }}
                              checked={checked[integration.name]}
                              defaultChecked={getDefaultChecked(integration)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => changeHandler(e, integration)}
                              icon={<InstanceUncheckedIcon />}
                              checkedIcon={<InstanceCheckedIcon />}
                            />
                            {integration.syncStatus &&
                            integration.syncStatus.status === IntegrationStatusMessage.failed ? (
                              <DataSourceErrorTooltip
                                key={integration.id}
                                displayName={integration.name}
                                errorMessage={integration.syncStatus.errorMessage}
                                logoPath={getIntegrationLogo(integration.integration_name).replace(
                                  '/icons/rounded/',
                                  ''
                                )}
                                instanceName={integration.name}
                                logoSize={40}
                                id={integration.id}
                                useSquareLogo
                                integrationName={integration.integration_name}
                              />
                            ) : (
                              <img
                                src={getIntegrationLogo(integration.integration_name)}
                                alt={`${integration.name} logo`}
                                width="40px"
                                height="40px"
                              />
                            )}
                            <IntegrationTextItem active={!integration.deleted}>
                              <InstanceTooltip
                                title={
                                  <>
                                    <Box className="tooltip-wrapper">
                                      <ArrowUpTooltip />
                                      <Typography className="tooltip-text">{integration.name}</Typography>
                                    </Box>
                                  </>
                                }
                                sx={{
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    width: 'fit-content !important'
                                  }
                                }}
                              >
                                <Typography>{integration.name}</Typography>
                              </InstanceTooltip>
                              <Box className="box-wrapper">
                                <span className="circle"></span>
                                <Typography className="text">
                                  {!integration.deleted ? 'Active' : 'Non active'}
                                </Typography>
                              </Box>
                            </IntegrationTextItem>
                          </IntegrationInstanceCard>
                        )
                      })
                    )}

                    {renderVisualizationParam(handleChange, errors, values)}
                    {renderFormulaParams(handleChange, errors, values)}

                    <Box className="drawer-actions">
                      {active ? (
                        <>
                          <ThemeButton
                            type="submit"
                            onClick={() => handleUpdate(values)}
                            disabled={
                              !isValid ||
                              isSubmitting ||
                              saveDisabled ||
                              isUpdating ||
                              !hasPermission(Permission.CPIConfigIntegrationUpdate, authUser)
                            }
                            endIcon={isUpdating && <CircularProgress size="16px" color="secondary" />}
                            sx={{ minWidth: '80px' }}
                          >
                            Save
                          </ThemeButton>
                          <ThemeButton
                            themevariant="secondary"
                            type="button"
                            onClick={handleDeactivate}
                            className="deactivate-button"
                          >
                            Deactivate CPI
                          </ThemeButton>
                        </>
                      ) : (
                        <ThemeButton
                          type="submit"
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            Boolean(fw === 'custom' && customFramework && !values.cpiCategoryValue)
                          }
                          endIcon={isUpdating && <CircularProgress size="16px" color="secondary" />}
                        >
                          Activate CPI
                        </ThemeButton>
                      )}
                      <ThemeButton themevariant="tertiary" onClick={() => setDrawerOpen(false)}>
                        Cancel
                      </ThemeButton>
                    </Box>
                  </>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Drawer>
  )
}

export default CPILibrarySettingsDrawer
