import {
  FC,
  useLayoutEffect,
  useState,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  KeyboardEvent,
  useMemo,
  useEffect,
  useRef
} from 'react'
import { Box, IconButton } from '@mui/material'
import { FormikProvider, FieldArray } from 'formik'
import { useAutocomplete } from '@mui/base/useAutocomplete'
import debounce from 'lodash.debounce'

/* Utils */
import axios from 'lib/axios'
import { DataFilter, JiraField, JiraOperator, FieldOption, JiraUser, IPreviewData } from 'models'
import { emptyFormikField, emptyDataFilter, emptyUser } from './dataFilteringControls.constants'

/* Components */
import JiraDropdown from '../jira-dropdowns/JiraDropdown'
import JiraDropdownMultiple from '../jira-dropdowns/JiraDropdownMultiple'
import { DeleteOutlinedIcon } from '../../../../../components/svg'
import ThemeButton from '../../../../../components/buttons/primary/ThemeButton'
import SearchSelectDropdown from './SearchSelectDropdown'
import { Wrapper } from './dataFilteringControls.styles'
import DataFilteringLoading from './DataFilteringLoading'
import useResizeObserver from '../../../../../../hooks/useResizeObserver'

interface Props {
  formik: any
  jiraFields: JiraField[]
  jiraOperators: JiraOperator[]
  initialLoad: boolean
  instanceId: string
  searchByJiraField: (
    instanceId: string,
    apiName: string
  ) => Promise<null | { field: string; jiraField: string; apiName: string; options: any[] }>
  fetchJiraPreview: (fields?: DataFilter[]) => Promise<void>
  dataFilters: DataFilter[]
  setDataFilters: Dispatch<SetStateAction<DataFilter[]>>
  fieldOptions: FieldOption[]
  setFieldOptions: Dispatch<SetStateAction<FieldOption[]>>
  jiraFiltersLoading: boolean
  setPreviewData: Dispatch<SetStateAction<IPreviewData | null>>
  valueAssignee: JiraUser[]
  setValueAssignee: Dispatch<SetStateAction<JiraUser[]>>
  valueReporter: JiraUser[]
  setValueReporter: Dispatch<SetStateAction<JiraUser[]>>
  inputValueAssignee: string
  setInputValueAssignee: Dispatch<SetStateAction<string>>
  inputValueReporter: string
  setInputValueReporter: Dispatch<SetStateAction<string>>
  vendorsAssignee: JiraUser[]
  setVendorsAssignee: Dispatch<SetStateAction<JiraUser[]>>
  vendorsReporter: JiraUser[]
  setVendorsReporter: Dispatch<SetStateAction<JiraUser[]>>
  setDataMappingValues: Dispatch<SetStateAction<Record<string, string[]>>>
  selectedOptionsAssignee: JiraUser[]
  setSelectedOptionsAssignee: Dispatch<SetStateAction<JiraUser[]>>
  selectedOptionsReporter: JiraUser[]
  setSelectedOptionsReporter: Dispatch<SetStateAction<JiraUser[]>>
  setDataMappingDefaultsValues: Dispatch<SetStateAction<Record<string, unknown>>>
}

const DataFilteringControls: FC<Props> = ({
  formik,
  jiraFields,
  jiraOperators,
  searchByJiraField,
  initialLoad,
  instanceId,
  fetchJiraPreview,
  dataFilters,
  setDataFilters,
  fieldOptions,
  setFieldOptions,
  jiraFiltersLoading,
  setPreviewData,
  valueAssignee,
  setValueAssignee,
  valueReporter,
  setValueReporter,
  inputValueAssignee,
  setInputValueAssignee,
  inputValueReporter,
  setInputValueReporter,
  vendorsAssignee,
  setVendorsAssignee,
  vendorsReporter,
  setVendorsReporter,
  setDataMappingValues,
  selectedOptionsAssignee,
  setSelectedOptionsAssignee,
  selectedOptionsReporter,
  setSelectedOptionsReporter,
  setDataMappingDefaultsValues
}) => {
  const operatorOptions = useMemo(() => jiraOperators.map((c) => c.displayName), [jiraOperators])
  const firstFieldOptions = useMemo(
    () => jiraFields.filter((c) => c.isForFilter).map((c) => c.displayName),
    [jiraFields]
  )
  const previewDataDisabled = useMemo(() => {
    return !(
      dataFilters.length > 0 &&
      dataFilters[0].field &&
      dataFilters[0].values.length > 0 &&
      dataFilters[0].apiName &&
      dataFilters[0].operator
    )
  }, [dataFilters])

  const dataFilteringGroupRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useResizeObserver(dataFilteringGroupRef)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    if (dimensions) {
      setWidth(dimensions.width)
    }
  }, [dimensions])

  const searchJiraFields = async () => {
    try {
      const promises: any[] = []

      jiraFields.forEach((field) => {
        const { apiName } = field

        const promise = async () => {
          return await searchByJiraField(instanceId, apiName)
        }
        promises.push(promise())
      })

      const resp: any[] = await Promise.all(promises)
      setFieldOptions(resp.filter((c) => !!c))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (instanceId && jiraFields.length > 0) {
      searchJiraFields().catch((e) => e)
    }
  }, [instanceId, jiraFields])

  const handleChangeFilters = (
    value: string | { id: string; name: string }[],
    prop: string,
    dataFilterProp: string,
    jqlField: string,
    id: string,
    apiName: string,
    index: number
  ) => {
    if (dataFilterProp === 'field') {
      const newField = jiraFields.find((c) => c.displayName === value)
      const newFields = [...formik.values.fields].map((c, i) => (i === index ? newField : c))
      formik.setValues({
        ...formik.values,
        fields: newFields
      })
      const newFilters = dataFilters.map((c, i) =>
        i === index
          ? {
              ...c,
              apiName: newField?.apiName || '',
              field: newField?.displayName || '',
              values: [],
              operator: '',
              id: newField?.id || id
            }
          : c
      )
      if (newFilters.length <= 1 && !newFilters.values.length) {
        setDataMappingValues({
          closed_status: [],
          critical_severity: [],
          high_severity: [],
          medium_severity: [],
          low_severity: []
        })
      }
      setDataFilters(newFilters)
    } else if (dataFilterProp === 'operator') {
      setDataFilters(
        dataFilters.map((c, i) =>
          i === index
            ? {
                ...c,
                operator: value as string
              }
            : c
        )
      )
    } else {
      setDataFilters(
        dataFilters.map((c, i) =>
          i === index
            ? {
                ...c,
                values: value as { id: string; name: string }[]
              }
            : c
        )
      )
      setDataMappingDefaultsValues((pre: any) => ({
        ...pre,
        status: []
      }))
      formik.setValues({
        ...formik.values,
        [prop]: {
          ...formik.values[prop],
          jqlField,
          id,
          [dataFilterProp]: value
        }
      })
    }
  }

  const addButtonRef = useRef<HTMLButtonElement>(null)

  const [addProductOpenAssignee, setAddProductOpenAssignee] = useState(false)
  const [loadingAssignee, setLoadingAssignee] = useState(false)
  // const [backspacingInputAssignee, setBackspacingInputAssignee] = useState(false)
  const [initialSearchedAssignee, setInitialSearchedAssignee] = useState(false)

  const [addProductOpenReporter, setAddProductOpenReporter] = useState(false)
  const [loadingReporter, setLoadingReporter] = useState(false)
  // const [backspacingInputReporter, setBackspacingInputReporter] = useState(false)
  const [initialSearchedReporter, setInitialSearchedReporter] = useState(false)

  const getIntegrationLogo = (urlPath: string) => {
    if (urlPath.includes('https://')) return urlPath

    const logoHost = process.env.REACT_APP_LOGO_HOST
    const url = `${logoHost}/${urlPath}`

    return urlPath ? url : 'default-vendor-logo.svg'
  }

  const handleClearSearchAssignee = () => {
    if (!inputValueAssignee) {
      return
    }

    setInputValueAssignee('')
    // setVendorsAssignee([emptyUser])
    // setValueAssignee([emptyUser])
    setLoadingAssignee(false)
  }

  const handleClearSearchReporter = () => {
    if (!inputValueReporter) {
      return
    }

    setInputValueReporter('')
    // setVendorsReporter([emptyUser])
    // setValueReporter([emptyUser])
    setLoadingReporter(false)
  }

  const handleToggleAddAssignee = () => {
    setAddProductOpenAssignee((addProductOpenAssignee) => !addProductOpenAssignee)
    setTimeout(() => {
      setInitialSearchedAssignee(false)

      const ref = getInputPropsAssignee().ref
      if (ref && (ref as any).current) {
        ;(ref as any).current.focus()
      }
    }, 50)
  }

  const handleToggleAddReporter = () => {
    setAddProductOpenReporter((addProductOpenReporter) => !addProductOpenReporter)
    setTimeout(() => {
      setInitialSearchedReporter(false)

      const ref = getInputPropsReporter().ref
      if (ref && (ref as any).current) {
        ;(ref as any).current.focus()
      }
    }, 50)
  }

  const handleCloseAddAssignee = (event: Event | SyntheticEvent) => {
    if (addButtonRef.current && addButtonRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setAddProductOpenAssignee(false)
  }

  const handleCloseAddReporter = (event: Event | SyntheticEvent) => {
    if (addButtonRef.current && addButtonRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setAddProductOpenReporter(false)
  }

  function handleListKeyDownAssignee(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setAddProductOpenAssignee(false)
    } else if (event.key === 'Escape') {
      setAddProductOpenAssignee(false)
    }
  }

  function handleListKeyDownReporter(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setAddProductOpenReporter(false)
    } else if (event.key === 'Escape') {
      setAddProductOpenReporter(false)
    }
  }

  const handleSearchVendorsAssignee = async (newValue: string | null, field: string): Promise<void> => {
    setInputValueAssignee(newValue || '')
    setInitialSearchedAssignee(true)

    if (!newValue) {
      setValueAssignee([emptyUser])
      setVendorsAssignee([emptyUser])
      setLoadingAssignee(false)

      return
    }

    if (newValue.length < 2) return

    try {
      const res = await axios.get(`/api/v3/integrations/jira/config/${instanceId}/search/${field}?query=${newValue}`)

      if (res.status === 200) {
        const users = res.data.data

        setVendorsAssignee(users.length > 0 ? users : [emptyUser])
      }
    } catch (e) {
      console.error('Failed searching vendors', e)
      setVendorsAssignee([emptyUser])
    } finally {
      setLoadingAssignee(false)
    }
  }

  const handleSearchVendorsReporter = async (newValue: string | null, field: string): Promise<void> => {
    setInputValueReporter(newValue || '')
    setInitialSearchedReporter(true)

    if (!newValue) {
      setValueReporter([emptyUser])
      setVendorsReporter([emptyUser])
      setLoadingReporter(false)

      return
    }

    if (newValue.length < 2) return

    try {
      const res = await axios.get(`/api/v3/integrations/jira/config/${instanceId}/search/${field}?query=${newValue}`)

      if (res.status === 200) {
        const users = res.data.data

        setVendorsReporter(users.length > 0 ? users : [emptyUser])
      }
    } catch (e) {
      console.error('Failed searching vendors', e)
      setVendorsReporter([emptyUser])
    } finally {
      setLoadingReporter(false)
    }
  }

  const debouncedSearchAssignee = useMemo(() => debounce(handleSearchVendorsAssignee, 200), [])
  const debouncedSearchReporter = useMemo(() => debounce(handleSearchVendorsReporter, 200), [])

  const {
    getRootProps: getRootPropsAssignee,
    getInputProps: getInputPropsAssignee,
    getListboxProps: getListboxPropsAssignee,
    getOptionProps: getOptionPropsAssignee,
    focused: focusedAssignee
  } = useAutocomplete({
    id: 'search-jira-users-input-assignee',
    options: vendorsAssignee,
    getOptionLabel: (option) => option.displayName,
    value: valueAssignee,
    multiple: true,
    onChange: (e, newValue) => setValueAssignee(newValue),
    inputValue: inputValueAssignee,
    onInputChange: (e, newInputValue) => setInputValueAssignee(newInputValue)
    // blurOnSelect: true
  })

  const {
    getRootProps: getRootPropsReporter,
    getInputProps: getInputPropsReporter,
    getListboxProps: getListboxPropsReporter,
    getOptionProps: getOptionPropsReporter,
    focused: focusedReporter
  } = useAutocomplete({
    id: 'search-jira-users-input-reporter',
    options: vendorsReporter,
    getOptionLabel: (option) => option.displayName,
    value: valueReporter,
    multiple: true,
    onChange: (e, newValue) => setValueReporter(newValue),
    inputValue: inputValueReporter,
    onInputChange: (e, newInputValue) => setInputValueReporter(newInputValue)
    // blurOnSelect: true,
  })

  const handleResetDataFiltering = () => {
    formik.setValues({
      fields: [emptyFormikField]
    })
    setDataFilters([emptyDataFilter])
    setDataMappingValues({
      closed_status: [],
      critical_severity: [],
      high_severity: [],
      medium_severity: [],
      low_severity: []
    })
    setPreviewData(null)
    setVendorsReporter([])
    setVendorsAssignee([])
    setInputValueReporter('')
    setInputValueAssignee('')
    setValueAssignee([emptyUser])
    setValueReporter([emptyUser])
    setSelectedOptionsAssignee([])
    setSelectedOptionsReporter([])
    setDataMappingDefaultsValues((pre) => ({ ...pre, status: [] }))
  }

  if (!initialLoad || jiraFiltersLoading) return <DataFilteringLoading sx={{ gap: '24px !important' }} />
  return (
    <Wrapper className="data-filtering-controls-container">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <FieldArray
            name="fields"
            render={(arrayHelpers) => (
              <>
                {formik.values.fields.map((field: any, index: any) => {
                  const apiName = field.apiName
                  const thisDataFilter = dataFilters[index]
                  const thisField = fieldOptions.find((c: any) => c.field === apiName)
                  const thisJiraField = jiraFields.find((c) => c.id === field.id)
                  const idProp = thisJiraField?.apiIdField || 'accountId'
                  const nameProp =
                    thisJiraField?.apiNameField === 'email'
                      ? 'emailAddress'
                      : thisJiraField?.apiNameField || 'emailAddress'
                  const thisFieldOptions =
                    thisField && thisField.options && thisField.options.length > 0 && thisField?.options[0].name
                      ? thisField.options.map((c: any) => ({
                          id: c[idProp],
                          name: c[nameProp],
                          label: c[nameProp]
                        }))
                      : thisField?.options.map((c) => ({
                          id: c,
                          name: c,
                          label: c
                        })) || []

                  if (!thisDataFilter) return null
                  return (
                    <Box className="data-filtering-group" key={`field-${index}`} ref={dataFilteringGroupRef}>
                      <JiraDropdown
                        placeholder="Select..."
                        options={firstFieldOptions}
                        disabledOptions={dataFilters
                          .filter((c) => !!c.field && c.field !== thisDataFilter.field)
                          .map((c) => c.field)}
                        value={thisDataFilter.field}
                        handleChange={(e: any) => {
                          if (field.id === 'assignee_accountId') {
                            setValueAssignee([emptyUser])
                            setInputValueAssignee('')
                            setVendorsAssignee([])
                          }
                          if (field.id === 'reporter_accountId') {
                            setValueReporter([emptyUser])
                            setInputValueReporter('')
                            setVendorsReporter([])
                          }
                          handleChangeFilters(
                            e.target.value,
                            apiName,
                            'field',
                            field.jqlField,
                            field.id,
                            apiName,
                            index
                          )
                        }}
                        handleReset={() => {
                          //
                        }}
                        width="353px"
                        inputWidth="250px"
                        wrapperSx={{
                          flexBasis: '40%',
                          width: '100%',
                          // maxWidth: `${(width - 64) * 0.3}px`
                          maxWidth: 'unset'
                        }}
                      />

                      <JiraDropdown
                        options={operatorOptions}
                        value={thisDataFilter.operator}
                        handleChange={(e: any) => {
                          handleChangeFilters(
                            e.target.value,
                            apiName,
                            'operator',
                            field.jqlField,
                            field.id,
                            apiName,
                            index
                          )
                        }}
                        handleReset={() => {
                          //
                        }}
                        width="120px"
                        inputWidth="120px"
                        inputPadding="24px !important"
                        hideSearch
                        wrapperSx={{
                          flexBasis: '20%',
                          width: '100%',
                          minWidth: '125px',
                          maxWidth: `${(width - 64) * 0.1}px`
                        }}
                      />

                      {field.listType !== 'search' ? (
                        <JiraDropdownMultiple
                          options={thisFieldOptions}
                          multiple
                          value={thisDataFilter.values.map((c: any) => c.name)}
                          handleChange={(e: any) => {
                            const thisValue = e.target.value.map((c: any) => (typeof c !== 'string' ? c.name : c))
                            const theseOptions = thisFieldOptions.filter((c) => thisValue.includes(c.name))

                            handleChangeFilters(
                              theseOptions.map((c) => ({
                                id: c.id,
                                name: c.name
                              })),
                              apiName,
                              'values',
                              field.jqlField,
                              field.id,
                              apiName,
                              index
                            )
                          }}
                          minWidth="250px"
                          wrapperSx={{
                            flexBasis: '40%',
                            width: '100%'
                          }}
                          maxWidth={`${(width - 64) * 0.3}px !important`}
                        />
                      ) : (
                        <div style={{ position: 'relative', flexBasis: '40%', width: '100%' }}>
                          {field.id === 'assignee_accountId' ? (
                            <SearchSelectDropdown
                              addProductOpen={addProductOpenAssignee}
                              addButtonRef={addButtonRef}
                              handleCloseAdd={handleCloseAddAssignee}
                              initialSearched={initialSearchedAssignee}
                              value={valueAssignee}
                              loading={loadingAssignee}
                              vendors={vendorsAssignee}
                              getRootProps={getRootPropsAssignee}
                              focused={focusedAssignee}
                              inputValue={inputValueAssignee}
                              getInputProps={getInputPropsAssignee}
                              setVendors={setVendorsAssignee}
                              setLoading={setLoadingAssignee}
                              field={apiName}
                              debouncedSearch={debouncedSearchAssignee}
                              handleClearSearch={handleClearSearchAssignee}
                              emptyUser={emptyUser}
                              setInputValue={setInputValueAssignee}
                              getListboxProps={getListboxPropsAssignee}
                              handleListKeyDown={handleListKeyDownAssignee}
                              getOptionProps={getOptionPropsAssignee}
                              getIntegrationLogo={getIntegrationLogo}
                              handleAddVendor={(newValue: JiraUser) => {
                                const idProp = thisJiraField?.apiIdField || 'accountId'
                                const nameProp =
                                  thisJiraField?.apiNameField === 'email'
                                    ? 'emailAddress'
                                    : thisJiraField?.apiNameField || 'emailAddress'
                                handleChangeFilters(
                                  [
                                    {
                                      ...newValue,
                                      id: (newValue as any)[idProp],
                                      name: (newValue as any)[nameProp]
                                    }
                                  ],
                                  apiName,
                                  'values',
                                  field.jqlField,
                                  field.id,
                                  apiName,
                                  index
                                )
                                let nextValue = []
                                const valueExists = valueAssignee.find((c) => c.emailAddress === newValue.emailAddress)
                                if (valueExists) {
                                  nextValue = [...valueAssignee]
                                    .filter((c) => c.emailAddress !== newValue.emailAddress)
                                    .filter((c) => !!c.emailAddress)
                                } else {
                                  nextValue = [...valueAssignee, newValue].filter((c) => !!c.emailAddress)
                                }
                                setValueAssignee(nextValue)
                                setSelectedOptionsAssignee(nextValue)
                                setVendorsAssignee(nextValue)
                                // setInputValueAssignee('')
                                // setAddProductOpenAssignee(false)
                              }}
                              handleToggleAdd={handleToggleAddAssignee}
                              minWidth="100%"
                              maxWidth={`${(width - 64) * 0.3}px`}
                              selectedOptions={selectedOptionsAssignee}
                            />
                          ) : (
                            <SearchSelectDropdown
                              addProductOpen={addProductOpenReporter}
                              addButtonRef={addButtonRef}
                              handleCloseAdd={handleCloseAddReporter}
                              initialSearched={initialSearchedReporter}
                              value={valueReporter}
                              loading={loadingReporter}
                              vendors={vendorsReporter}
                              getRootProps={getRootPropsReporter}
                              focused={focusedReporter}
                              inputValue={inputValueReporter}
                              getInputProps={getInputPropsReporter}
                              setVendors={setVendorsReporter}
                              setLoading={setLoadingReporter}
                              field={apiName}
                              debouncedSearch={debouncedSearchReporter}
                              handleClearSearch={handleClearSearchReporter}
                              emptyUser={emptyUser}
                              setInputValue={setInputValueReporter}
                              getListboxProps={getListboxPropsReporter}
                              handleListKeyDown={handleListKeyDownReporter}
                              getOptionProps={getOptionPropsReporter}
                              getIntegrationLogo={getIntegrationLogo}
                              handleAddVendor={(newValue: JiraUser) => {
                                const idProp = thisJiraField?.apiIdField || 'accountId'
                                const nameProp =
                                  thisJiraField?.apiNameField === 'email'
                                    ? 'emailAddress'
                                    : thisJiraField?.apiNameField || 'emailAddress'
                                handleChangeFilters(
                                  [
                                    {
                                      id: (newValue as any)[idProp],
                                      name: (newValue as any)[nameProp]
                                    }
                                  ],
                                  apiName,
                                  'values',
                                  field.jqlField,
                                  field.id,
                                  apiName,
                                  index
                                )
                                let nextValue = []
                                const valueExists = valueReporter.find((c) => c.emailAddress === newValue.emailAddress)
                                if (valueExists) {
                                  nextValue = [...valueReporter]
                                    .filter((c) => c.emailAddress !== newValue.emailAddress)
                                    .filter((c) => !!c.emailAddress)
                                } else {
                                  nextValue = [...valueReporter, newValue].filter((c) => !!c.emailAddress)
                                }
                                setValueReporter(nextValue)
                                setSelectedOptionsReporter(nextValue)
                                setVendorsReporter([emptyUser])
                                // setInputValueReporter('')
                                // setAddProductOpenReporter(false)
                              }}
                              handleToggleAdd={handleToggleAddReporter}
                              minWidth="100%"
                              maxWidth={`${(width - 64) * 0.3}px`}
                              selectedOptions={selectedOptionsReporter}
                            />
                          )}
                        </div>
                      )}

                      <IconButton
                        className="delete-button"
                        onClick={() => {
                          arrayHelpers.remove(index)
                          const thisFilter = dataFilters[index]
                          setDataFilters([...dataFilters.filter((c, i) => i !== index)])

                          if (thisFilter.id === 'assignee_accountId') {
                            setVendorsAssignee([])
                            setInputValueAssignee('')
                            setValueAssignee([emptyUser])
                            setSelectedOptionsAssignee([])
                          }
                          if (thisFilter.id === 'reporter_accountId') {
                            setVendorsReporter([])
                            setInputValueReporter('')
                            setValueReporter([emptyUser])
                            setSelectedOptionsReporter([])
                          }
                        }}
                        disabled={dataFilters.length < 2}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Box>
                  )
                })}

                <Box className="data-filtering-actions-wrapper">
                  <Box className="data-filtering-actions-wrapper__left">
                    <ThemeButton
                      themevariant="secondary"
                      onClick={() => {
                        setDataFilters([...dataFilters, emptyDataFilter])
                        arrayHelpers.push(emptyFormikField)
                      }}
                    >
                      <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22 13.4286H13.4286V22H10.5714V13.4286H2V10.5714H10.5714V2H13.4286V10.5714H22V13.4286Z"
                            fill="#FFA9FC"
                          />
                        </svg>
                        Add Filter
                      </>
                    </ThemeButton>
                    <ThemeButton
                      themevariant="secondary"
                      onClick={() => fetchJiraPreview()}
                      disabled={previewDataDisabled}
                    >
                      <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                            fill="#FFA9FC"
                          />
                        </svg>
                        Preview Data
                      </>
                    </ThemeButton>
                  </Box>
                  <ThemeButton themevariant="tertiary" onClick={handleResetDataFiltering}>
                    Reset
                  </ThemeButton>
                </Box>
              </>
            )}
          />
        </form>
      </FormikProvider>
    </Wrapper>
  )
}

export default DataFilteringControls
