import { FC, useState } from 'react'
import { Button, Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { SelectDropdownIcon } from '../../../../components/svg'
import { AuditLogFormControl, AuditLogSelect, CategoryMenuProps, Wrapper } from './auditLogSelectField.styles'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  noborder?: string
  labeltext?: string
  handleReset?: () => void
}

const AuditLogSelectField: FC<Props> = ({
  options,
  labeltext,
  handleChange,
  placeholder,
  noborder,
  minWidth,
  value,
  handleReset,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Wrapper>
      <Typography className="label-text">{labeltext}</Typography>
      <AuditLogFormControl
        sx={{
          minWidth
        }}
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== 'mmmm')) ? 'true' : 'false'
        }
      >
        <AuditLogSelect
          noborder={noborder}
          isselected={
            placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== 'pp')) ? 'true' : 'false'
          }
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null),
            style: { display: anchorEl ? 'block' : 'none' }
          }}
          sx={{
            minWidth,
            '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
          }}
          IconComponent={(props) => <SelectDropdownIcon {...props} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned.length} selected`
            }

            if (labeltext) {
              return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
            }
            return typeof noborder !== 'undefined' ? `Sort by: ${selected}` : selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
              disableRipple
            >
              {rest.multiple && <Checkbox checked={value.indexOf(option) > -1} />}
              <ListItemText primary={option} />
            </MenuItem>
          ))}
          <MenuItem className="reset-button-wrapper" disableRipple disabled={value.length === 1 && value[0] === ''}>
            <Button className="reset-button" onClick={handleReset}>
              Reset
            </Button>
          </MenuItem>
        </AuditLogSelect>
      </AuditLogFormControl>
    </Wrapper>
  )
}

export default AuditLogSelectField
