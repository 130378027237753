import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  .data-source-image {
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  .sources-count-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
