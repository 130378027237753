import * as Yup from 'yup'

export interface FormValues {
  name: string
  description: string
}

export const defaultValues = {
  name: '',
  description: ''
}

export const schema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  api_id: Yup.string().required('Required'),
  client_id: Yup.string().required('Required'),
  secret: Yup.string().required('Required')
})

export const stepsImages: { [key: string]: number[] } = {
  microsoft_sentinel: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  microsoft_defender365: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  microsoft_defendervm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  crowdstrike_falcon: [1, 2, 3, 4, 5, 6, 7],
  crowdstrike_spotlight: [2, 3, 4, 5],
  knowbe4_kmsat: [1, 2, 3, 4],
  qualys_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  rapid7_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  tenable_vm: [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17],
  microsoft_entraid: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  google_workspace: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  orca: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  microsoft_defenderforcloud: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  cofense_phishme: [1, 2, 3, 4, 5],
  proofpoint_sat: [2, 3, 4, 5],
  sentinelone_singularity: [1, 2, 3, 4, 5, 6, 7, 8],
  okta: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  microsoft_intune: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  cisco_duo: [1, 2, 3, 4, 5],
  cisco_umbrella: [1, 2, 3, 4],
  jamf_pro: [1, 2, 3, 4, 5, 6, 7, 8],
  jumpcloud: [1, 2, 3, 4, 5],
  cloudflare: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  mimecast: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  rapid7_appsec: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  vmware_workspaceone: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  checkmarx_one: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  knowbe4_phisher: [2, 3, 4, 5, 6, 7],
  proofpoint_tap: [1, 2, 3, 4],
  jira: [1, 2, 3, 4, 5, 6]
}

export const jiraFieldsImportOrder = [
  'assignee_accountId',
  'project_id',
  'reporter_accountId',
  'priority_id',
  'issuetype_type',
  'resolution_id',
  'label',
  'components_id'
]
