import { FC, SyntheticEvent, useMemo, useState } from 'react'
import { Box, Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../../../components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './jiraDropdownDefaults.styles'
import JiraDropdownTabs, { JiraDropdownTabPanel } from './JiraDropdownTabs'
import ThemeButton from '../../../../../components/buttons/primary/ThemeButton'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
  action?: () => void
  id?: string
  integration_name?: string
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  minWidth?: string
  iscustom?: string
  label?: string
  handleApply: any
  customOptions: any[]
}

const JiraDropdownDefaults: FC<Props> = ({
  label,
  options,
  handleChange,
  iscustom,
  placeholder,
  minWidth,
  value,
  handleApply,
  customOptions,
  ...rest
}) => {
  const [tabValue, setTabValue] = useState(0)
  const [savedTabValue, setSavedTabValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [useDefaultValues, setUseDefaultValues] = useState(false)

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 1 && savedTabValue === 0) {
      handleChange({ target: { value: [] } })
    }
    setTabValue(newValue)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    if (tabValue === 0 && useDefaultValues) return 'Default'

    const pruned = value.filter((s: string) => s !== '')
    const option = options.length > 0 && pruned.length > 0 ? options.find((n) => n.name === pruned[0].name) : undefined

    if (option) {
      if (pruned.length === 1) {
        return option.label
      }

      if (placeholder && pruned.length === 0) {
        return placeholder
      }

      return savedTabValue === 0 ? 'Default' : `${pruned.length} selected`
    } else {
      if (tabValue === 1) {
        const customOption = customOptions.find((c) => c.name === pruned[0])

        if (customOption) {
          if (pruned.length === 1) {
            return customOption.name
          }

          if (placeholder && pruned.length === 0) {
            return placeholder
          }

          return `${pruned.length} selected`
        }
      }
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}

      <LibraryFormControl
        sx={{ minWidth }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length) ? 'true' : 'false'}
        iscustom={iscustom}
      >
        <LibrarySelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                width: '300px',
                minWidth: '303px !important',
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => {
              setTabValue(savedTabValue)
              setAnchorEl(null)
            }
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          <Box className="jira-dropdown-tabs-wrapper">
            <JiraDropdownTabs value={tabValue} handleChange={handleTabChange} />
            <JiraDropdownTabPanel value={tabValue} index={0}>
              <Typography className="total-results-text">Results ({options.length})</Typography>

              <Box className="list-wrapper">
                {options.map((option) => {
                  return (
                    <MenuItem
                      disableRipple
                      autoFocus={false}
                      key={option.name}
                      value={option.name}
                      sx={{
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important'
                      }}
                    >
                      {rest.multiple && (
                        <Checkbox
                          checked={true}
                          readOnly
                          icon={<InstanceUncheckedIcon />}
                          checkedIcon={<InstanceCheckedIcon />}
                          sx={{
                            borderRadius: 'none',
                            input: {
                              borderRadius: 'none'
                            },
                            svg: {
                              path: {
                                fill: '#483646'
                              },
                              background: 'rgba(127, 116, 124, 1)',
                              borderRadius: '4px'
                            }
                          }}
                        />
                      )}
                      <ListItemText
                        primary={option.label}
                        sx={{
                          span: {
                            borderRadius: '4px !important',
                            background: '#ACFBC5 !important',
                            color: '#211920 !important',
                            fontSize: '10px !important',
                            padding: '4px 8px !important',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontStyle: 'normal !important',
                            fontWeight: 'bold !important',
                            lineHeight: 'normal !important',
                            letterSpacing: '1px !important',
                            textTransform: 'uppercase !important',
                            width: 'fit-content'
                          }
                        }}
                      />
                    </MenuItem>
                  )
                })}
              </Box>

              <Box className="divider" />
              <ThemeButton
                className="apply-button"
                themevariant="secondary"
                onClick={() => {
                  handleApply(
                    options.map((c) => ({
                      id: c.id,
                      name: c.name
                    })),
                    true
                  )
                  setSavedTabValue(0)
                  handleChange({ target: { value: options.map((c) => c.name) } }, true)
                  setAnchorEl(null)
                  setUseDefaultValues(true)
                }}
              >
                Apply
              </ThemeButton>
            </JiraDropdownTabPanel>
            <JiraDropdownTabPanel value={tabValue} index={1}>
              <Typography className="total-results-text">Results ({customOptions.length})</Typography>

              <Box className="list-wrapper">
                {customOptions.map((option) => {
                  const { colorName } = option.statusCategory

                  return (
                    <MenuItem
                      disableRipple
                      autoFocus={false}
                      key={option.name}
                      value={option.name}
                      onClick={() => {
                        let val = [...value]
                        if (value.includes(option.name)) {
                          val = value.filter((c: string) => c !== option.name)
                        } else {
                          val.push(option.name)
                        }
                        handleChange({ target: { value: val } })
                        if (!rest.multiple) {
                          setAnchorEl(null)
                        }
                      }}
                      sx={{
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important'
                      }}
                    >
                      {rest.multiple && (
                        <Checkbox
                          checked={value.indexOf(option.name) > -1}
                          icon={<InstanceUncheckedIcon />}
                          checkedIcon={<InstanceCheckedIcon />}
                        />
                      )}
                      <ListItemText
                        primary={option.label}
                        sx={{
                          span: {
                            borderRadius: '4px !important',
                            background:
                              colorName === 'green'
                                ? '#ACFBC5 !important'
                                : colorName === 'blue-gray'
                                ? '#C0DCFF !important'
                                : colorName === 'yellow'
                                ? '#EFEFEF'
                                : `gray !important`,
                            color:
                              colorName === 'green'
                                ? '#211920 !important'
                                : colorName === 'blue-gray'
                                ? '#0659CD !important'
                                : colorName === 'yellow'
                                ? '#44546F !important'
                                : `${colorName} !important`,
                            fontSize: '10px !important',
                            padding: '4px 8px !important',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontStyle: 'normal !important',
                            fontWeight: 'bold !important',
                            lineHeight: 'normal !important',
                            letterSpacing: '1px !important',
                            textTransform: 'uppercase !important',
                            width: 'fit-content'
                          }
                        }}
                      />
                    </MenuItem>
                  )
                })}
              </Box>

              <Box className="divider" />
              <ThemeButton
                className="apply-button"
                themevariant="secondary"
                onClick={() => {
                  handleApply(
                    customOptions
                      .filter((c) => value.includes(c.name))
                      .map((c: any) => ({
                        id: c.id,
                        name: c.name
                      })),
                    false
                  )
                  setSavedTabValue(1)
                  setUseDefaultValues(false)
                  setAnchorEl(null)
                }}
              >
                Apply
              </ThemeButton>
            </JiraDropdownTabPanel>
          </Box>
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default JiraDropdownDefaults
