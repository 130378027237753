import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { TableHeaders, IPreviewData, PreviewDataIssue } from 'models'
import { Container } from './previewData.styles'
import TableContent from './TableContent'
import debounce from 'lodash.debounce'

export const defaultTableHeaders: TableHeaders[] = [
  { name: 'Type', dataKey: 'type', sort: false, sortType: 'asc', show: true },
  { name: 'Key', dataKey: 'key', sort: false, sortType: 'asc', show: true },
  { name: 'Summary', dataKey: 'summary', sort: false, sortType: 'asc', show: true },
  { name: 'Priority', dataKey: 'priority', sort: false, sortType: 'asc', show: true },
  { name: 'Status', dataKey: 'status', sort: true, sortType: 'asc', show: true },
  { name: 'Assignee', dataKey: 'assignee', sort: false, sortType: 'asc', show: true }
]

interface Props {
  previewData: IPreviewData
  loadingPreviewData: boolean
  setLoadingPreviewData: Dispatch<SetStateAction<boolean>>
  initialLoad: boolean
}

const PreviewData: FC<Props> = ({ previewData, loadingPreviewData, setLoadingPreviewData, initialLoad }) => {
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const [columns, setColumns] = useState<TableHeaders[]>([...defaultTableHeaders])
  const [search, setSearch] = useState('')
  const [rows, setRows] = useState<PreviewDataIssue[]>(previewData.issues)

  useEffect(() => {
    setRows(
      previewData.issues.map((n: any) => {
        return {
          type: n.type,
          key: n.key,
          summary: n.summary,
          priority: n.priority,
          status: n.status,
          assignee: n.assignee,
          iconUrl: n.iconUrl
        }
      })
    )
    setLoadingPreviewData(false)
  }, [previewData])

  const handleSearch = async () => {
    // await fetchAnalysisData({ integrationIds, searchTerm, filters, operatingSystem })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    // setLoadingPreviewData(true)
    debouncedSearch()
  }, [search])

  return (
    <Container>
      <TableContent
        ref={contentWrapperRef}
        search={search}
        setSearch={setSearch}
        columns={columns}
        setColumns={setColumns}
        loadingPreviewData={loadingPreviewData}
        initialLoad={initialLoad}
        rows={rows}
        setRows={setRows}
        previewData={previewData}
      />
    </Container>
  )
}

export default PreviewData
