import { FC } from 'react'
import { CardSkeleton, CardSkeletonWrapper } from './dataFilteringLoading.styles'
import { Skeleton } from '@mui/material'

interface Props {
  sx?: Record<string, unknown>
  length?: number
}

const DataFilteringLoading: FC<Props> = ({ sx, length }) => (
  <CardSkeletonWrapper sx={{ gap: '8px !important', ...sx }}>
    {Array.from(Array(typeof length !== 'undefined' ? length : 2).keys()).map((n) => (
      <CardSkeleton sx={{ height: '40px !important' }} key={n}>
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner"
        />
      </CardSkeleton>
    ))}
  </CardSkeletonWrapper>
)

export default DataFilteringLoading
