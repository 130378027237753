import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #998d96;
  position: relative;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    border-color: #FFA9FC;
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: #FFA9FC !important;
      }
    }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    min-width: 122px;
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.6em);
    right: 13px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: #ffa9fc !important;
    }
  }
`

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: 8px;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    white-space: nowrap;
  }
`

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
  minwidth?: string
}>`
  outline: none;
  min-width: 121px !important;
  height: 40px;
  border-radius: 4px;
  display: flex;
  position: relative;
  max-width: ${({ isSort }) => (isSort ? 'auto' : '121px')};

  @media (min-width: 1170px) {
    max-width: 200px !important;
  }

  ${({ noborder }) =>
    noborder !== 'true' &&
    `
    border: 1px solid #998d96;
  `};

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
      border-color: #FFA9FC;
  `};

  ${({ minwidth }) =>
    minwidth &&
    `
      min-width: ${minwidth};
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: white;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${({ isSort }) => (isSort ? '#fff' : '#d0c3cc')};
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    gap: 2px;

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
      }

      @media (max-width: 1170px) {
        display: none;
      }
    }
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #ffa9fc;
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      minWidth: '321px !important',
      maxHeight: '372px !important',
      overflowY: 'hidden',

      '.MuiList-root': {
        padding: '8px !important',

        '.all-option-form-control': {
          paddingLeft: '9px'
        },

        '.divider': {
          background: '#4D444B',
          width: '100%',
          height: '1px',
          margin: '4px 0 12px'
        },

        '.list-wrapper': {
          maxHeight: '190px',
          height: '100%',
          overflowY: 'auto',

          '&::-webkit-scrollbar-track': {
            background: '#4D444B',
            borderRadius: '8px'
          },

          '&::-webkit-scrollbar-thumb': {
            background: '#ffa9fc',
            height: '4px',
            width: '4px',
            border: '4px solid transparent',
            backgroundClip: 'content-box'
          }
        }
      },

      '.MuiMenuItem-root': {
        height: '40px',
        padding: '0 !important',
        borderRadius: '4px',
        maxWidth: 'calc(100% - 8px) !important',
        width: '100%',
        display: 'flex',
        alignItems: 'center',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: '#573F56 !important'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          color: '#E9E0E4',
          paddingLeft: '8px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },

        '.sources-count-text': {
          color: '#fff',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          marginLeft: '8px'
        },

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#483646'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#ffa9fc',
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
