import { FC } from 'react'
import { NewIntegrationIcon } from '../../../../components/svg'
import { NewIntegrationMarkerContainer } from './integration-card/integrationCard.styles'

interface Props {
  expanded: boolean
  sx?: Record<string, unknown>
}

const NewIntegrationMarker: FC<Props> = ({ expanded, sx }) => (
  <NewIntegrationMarkerContainer expanded={expanded ? 'true' : 'false'} sx={sx}>
    <NewIntegrationIcon />
    NEW
  </NewIntegrationMarkerContainer>
)

export default NewIntegrationMarker
