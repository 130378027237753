import { FC } from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  summary: string
  mappingSummary: string[]
}

const MainSectionSummary: FC<Props> = ({ summary, mappingSummary }) => (
  <Box className="main-section-summary">
    <Typography className="main-section-summary-title">Summary</Typography>
    <Typography className="main-section-summary-subtitle">{summary}</Typography>
    {mappingSummary.map((c) => (
      <Typography className="main-section-summary-subtitle mapping-summary">{c}</Typography>
    ))}
  </Box>
)

export default MainSectionSummary
