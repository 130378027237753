import { FC, Ref, InputHTMLAttributes, HTMLAttributes } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'

/* Utils */
import { getIntegrationLogo } from '../../../../coverage-analyzer/utils'
import { ConfiguredDataSource, IntegrationInstance } from '../../../../../../models'

/* Components */
import { Root, InputWrapper, Listbox, ListWrapper, StyledTag } from './SearchDataSourcesComponents'
import { SearchIcon, CloseChipIcon } from '../../../../../components/svg'
import { AutocompleteGetTagProps, UseAutocompleteRenderedOption } from '@mui/base/useAutocomplete/useAutocomplete'

export interface DataSourceOption {
  name: string
  id: string
  integration_name: string
}

interface Props {
  options: DataSourceOption[]
  getRootProps: (externalProps?: any) => HTMLAttributes<HTMLDivElement>
  getTagProps: AutocompleteGetTagProps
  getListboxProps: () => HTMLAttributes<HTMLUListElement>
  getOptionProps: (renderedOption: UseAutocompleteRenderedOption<any>) => HTMLAttributes<HTMLLIElement>
  focused: boolean
  setAnchorEl: () => void
  value: IntegrationInstance[]
  groupedOptions: ConfiguredDataSource[]
  groupedFilteredOptions: ConfiguredDataSource[]
  getInputProps: () => InputHTMLAttributes<HTMLInputElement> & {
    ref: Ref<HTMLInputElement>
  }
  selectedValueNames: string[]
  getClearProps: any
  setAllChecked?: any
  uniqueConfiguredIntegrations: ConfiguredDataSource[]
}

const SearchDataSources: FC<Props> = ({
  options,
  getRootProps,
  getTagProps,
  getListboxProps,
  getOptionProps,
  focused,
  setAnchorEl,
  value,
  groupedOptions,
  groupedFilteredOptions,
  getInputProps,
  selectedValueNames,
  getClearProps,
  setAllChecked,
  uniqueConfiguredIntegrations
}) => {
  const { color, size, ...inputProps } = getInputProps()
  const uniqueNames = uniqueConfiguredIntegrations.map((c) => c.integration_name)

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? 'focused' : ''}
          sx={{ height: value.length > 0 ? 'auto' : '40px' }}
        >
          {value.map((option: DataSourceOption, index: number) => {
            const { key, ...tagProps } = getTagProps({ index })

            return (
              <StyledTag
                key={key}
                {...tagProps}
                label={option.name}
                icon={getIntegrationLogo(option.name, 'rounded')}
                onDelete={(e) => {
                  tagProps.onDelete(e)
                  const nextValue = value.filter((c) => c.name !== option.name)
                  const length = nextValue.filter((c: any) => uniqueNames.includes(c.name))
                  if (nextValue.length === uniqueNames.length && length.length === nextValue.length) {
                    setAllChecked(true)
                  } else {
                    setAllChecked(false)
                  }
                }}
              />
            )
          })}
          {value.length > 0 ? (
            <>
              <input {...getInputProps()} color={color} size={size} />
            </>
          ) : (
            <TextField
              variant="outlined"
              placeholder="Search..."
              InputProps={{
                ...inputProps,
                color: 'primary',
                size: 'medium',
                startAdornment: <SearchIcon />,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ padding: 0 }} onClick={() => getClearProps().onClick()}>
                      <CloseChipIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          )}
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} sx={{ display: !groupedFilteredOptions.length ? 'none' : 'block' }}>
          <ListWrapper>
            {(groupedOptions as typeof options).map((option, index) => {
              const { ...optionProps } = getOptionProps({ option, index })

              if (selectedValueNames.includes(option.name)) return null
              return (
                <li
                  key={index}
                  {...optionProps}
                  onClick={(e) => {
                    if (optionProps && optionProps.onClick) {
                      optionProps.onClick(e)
                    }
                    const nextValue = [...value, option]
                    const length = nextValue.filter((c: any) => uniqueNames.includes(c.name))
                    if (nextValue.length === uniqueNames.length && length.length === nextValue.length) {
                      setAllChecked(true)
                    } else {
                      setAllChecked(false)
                    }
                  }}
                >
                  <span>{option.name}</span>
                </li>
              )
            })}
          </ListWrapper>
        </Listbox>
      ) : null}
    </Root>
  )
}

export default SearchDataSources
