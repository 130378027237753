import { FC, useMemo } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

/* Components */
import { Container } from './jiraHeader.styles'
import ThemeButton from '../../../../components/buttons/primary/ThemeButton'
import SaveSvg from '../../../../components/svg/labels/SaveSvg'
import { TestStatus } from './SetupJiraInstance'
import BetaIntegrationMarker from '../components/BetaIntegrationMarker'

interface Props {
  headerTitle: string
  handleSave: () => void
  handleDiscard: () => void
  handleBack?: () => void
  testStatus: any
  isSubmitting: any
  isValid: any
  loading: any
  schema: any
  allowSave: boolean
  discardDisabled: boolean
  disabledP2: boolean
  tabValue: number
}

const JiraHeader: FC<Props> = ({
  headerTitle,
  handleSave,
  handleDiscard,
  handleBack,
  testStatus,
  isSubmitting,
  isValid,
  loading,
  schema,
  allowSave,
  discardDisabled,
  disabledP2,
  tabValue
}) => {
  const disabled = useMemo(() => {
    if (tabValue === 0) {
      if (testStatus !== TestStatus.successful) return true
      if (allowSave) return loading || !isValid || !schema || isSubmitting
      if (!allowSave) return true

      // return !allowSave && (loading || !isValid || !schema || isSubmitting)
      return loading || !isValid || !schema || isSubmitting
    }

    return disabledP2
  }, [tabValue, disabledP2, testStatus, allowSave, loading, isValid, schema, isSubmitting])

  return (
    <Container>
      <Box className="wrapper" sx={{ backgroundColor: '#1E1A1D !important' }}>
        {typeof handleBack !== 'undefined' && (
          <Box className="back-arrow-wrapper">
            <IconButton onClick={handleBack}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 10.75H6.7875L13.775 3.7625L12 2L2 12L12 22L13.7625 20.2375L6.7875 13.25H22V10.75Z"
                  fill="#FFA9FC"
                />
              </svg>
            </IconButton>
          </Box>
        )}

        <Box className="header-content">
          <Typography className="header-title">{headerTitle}</Typography>
          <BetaIntegrationMarker expanded={false} sx={{ marginTop: '2px !important' }} />
        </Box>

        <Box className="header-actions">
          <ThemeButton themevariant="primary" onClick={handleSave} disabled={disabled}>
            <>
              <SaveSvg />
              Save
            </>
          </ThemeButton>
          <ThemeButton themevariant="secondary" onClick={handleDiscard} disabled={discardDisabled}>
            <>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                  fill="#FFA9FC"
                />
              </svg>
              Discard
            </>
          </ThemeButton>
        </Box>
      </Box>
    </Container>
  )
}

export default JiraHeader
