import { FC, Fragment, useMemo } from 'react'
import { Box, Link as MuiLink, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'react-router-dom'

/* Utils */
import { CPIFrequency, CPISeverity, Insight } from 'models'
import { payloadToEnumTimePeriod } from '../../../library/utils'

/* Components */
import { ContentInner, InsightsImageWrapper, MainSectionContent } from './mainSection.styles'
import DataSources from '../../../../components/data-sources'
import InsightsTrendsData from '../trends-data'

interface Props {
  insight: Insight
  getHeaderBackground: (text: string) => string
  isFetching: boolean
  loadingMarking: boolean
  search: string
}

const MainSection: FC<Props> = ({ insight, loadingMarking, getHeaderBackground, isFetching, search }) => {
  const handleClick = () => {
    if (insight.type === 'ssm_insight') return

    if (insight.data && Array.isArray(insight.data.trend_data)) return

    const payload = JSON.parse(insight?.data?.redirection_params || '').redirection_params

    if (!payload.time_period) {
      localStorage.setItem(
        'insightFilters',
        JSON.stringify({
          dates: {
            dateFrom: payload.date_from,
            dateTo: payload.date_to
          },
          frequency: payload.frequency || CPIFrequency.Monthly,
          severity: payload.section || CPISeverity.High
        })
      )
    } else {
      localStorage.setItem(
        'insightFilters',
        JSON.stringify({
          timePeriod: payloadToEnumTimePeriod(payload.time_period),
          frequency: payload.frequency || CPIFrequency.Monthly,
          severity: payload.section || CPISeverity.High
        })
      )
    }
  }

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: '#FFA9FC' }}>
          {part}
        </span>
      ) : (
        <Fragment key={index}>{part}</Fragment>
      )
    )
  }

  const renderInsightEvidences = useMemo(() => {
    if (insight.data?.trend_data && insight.data?.trend_data.length > 0) {
      return (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography className="accordion-title">Performance Trend</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InsightsTrendsData insight={insight} />
          </AccordionDetails>
        </Accordion>
      )
    }

    return null
  }, [insight])

  const getInsightImage = () => {
    if (insight.data?.card_image) {
      if (insight.data?.card_image.includes('svg') && insight.data.card_image.includes('cpi_trend_prediction')) {
        return insight.data.card_image.replace('.svg', '.png')
      }

      return insight.data.card_image || ''
    }

    return ''
  }

  if (!loadingMarking && isFetching) {
    return (
      <MainSectionContent sx={{ height: '100%' }} bg={getHeaderBackground(insight.sub_type)} id="mainSection">
        <Box className="loading-wrapper">
          <svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.79077 1.79077 0 4 0H96C98.2092 0 100 1.79077 100 4V10V80H0V10V4ZM96 14H4V76H96V14ZM61.322 38.1221L57.2 34H68V44.8L63.8779 40.678L52.5381 52L45.3379 44.8L34.5381 55.6001L32 53.062L45.3379 39.7061L52.5381 46.906L61.322 38.1221Z"
              fill="#4D444B"
            />
          </svg>
          <svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.79077 1.79089 0 4 0H96C98.2092 0 100 1.79077 100 4V10V80H0V10V4ZM96 14H4V76H96V14ZM62.609 43.1909C61.8131 37.6553 57.4352 33.2773 51.8995 32.4814V27C60.4382 27.8503 67.2402 34.6523 68.0905 43.1909H62.609ZM51.8995 57.5186C57.4352 56.7227 61.8131 52.3447 62.609 46.8091H68.0905C67.2402 55.3477 60.4382 62.1497 51.8995 63V57.5186ZM37.4271 45C37.4271 51.3679 42.1488 56.6321 48.2814 57.5186V63C39.1458 62.0955 32 54.3889 32 45C32 35.6111 39.1458 27.9045 48.2814 27V32.4814C42.1488 33.3679 37.4271 38.6321 37.4271 45Z"
              fill="#4D444B"
            />
          </svg>
          <svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.79077 1.79077 0 4 0H96C98.2092 0 100 1.79077 100 4V10V80H0V10V4ZM96 14H4V76H96V14ZM40.1428 38H33V61.3333H40.1428V38ZM53 28H46.3333V61.3333H53V28ZM66.3333 47.0476H59.6667V61.3333H66.3333V47.0476Z"
              fill="#4D444B"
            />
          </svg>
          <svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.79077 1.79089 0 4 0H96C98.2092 0 100 1.79077 100 4V10V80H0V10V4ZM96 14H4V76H96V14ZM33 57.6296V53.926H44.1111V57.6296H33ZM33 35.4075V31.7036H51.5186V35.4075H33ZM51.5186 57.6296V61.3333H47.8148V50.2222H51.5186V53.926H66.3334V57.6296H51.5186ZM40.4073 42.8147V39.1111H44.1111V50.2222H40.4073V46.5186H33V42.8147H40.4073ZM66.3334 42.8147V46.5186H47.8148V42.8147H66.3334ZM58.9259 39.1111H55.2222V28H58.9259V31.7036H66.3334V35.4075H58.9259V39.1111Z"
              fill="#4D444B"
            />
          </svg>
        </Box>
      </MainSectionContent>
    )
  }
  return (
    <MainSectionContent sx={{ height: '100%' }} bg={getHeaderBackground(insight.sub_type)} id="mainSection">
      <Box className="main-section-header">
        <Box className="main-section-header__left">
          <Typography className="title">{highlightMatch(insight.data?.title || '')}</Typography>
          <Typography className="description">{highlightMatch(insight.data?.description || '')}</Typography>

          {insight.type !== 'ssm_insight' && (
            <Box className="details-wrapper">
              <Box className="details-item">
                <Typography className="details-item-title">CPI ID</Typography>
                <Typography className="details-item-text">
                  {highlightMatch(insight.data?.cpi_display_name ? insight.data?.cpi_display_name || '' : '')}
                </Typography>
              </Box>
              <Box className="section-divider" />

              {insight.data?.section && (
                <>
                  <Box className="details-item second">
                    <Typography className="details-item-title">Severity</Typography>
                    <Typography className="details-item-text no-bold">
                      {highlightMatch(insight.data?.section)}
                    </Typography>
                  </Box>
                  <Box className="section-divider" />
                </>
              )}

              <Box className="details-item last">
                <Typography className="details-item-title">Data Sources</Typography>
                <DataSources dataSources={(insight?.data?.instances as any) || []} isInsights />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="main-section-header__right">
          <Box className="main-section-header__right-wrapper">
            <MuiLink
              component={Link}
              to={
                insight.type === 'ssm_insight'
                  ? '/security-stack-map'
                  : `/library/cpis/${insight?.data?.cpi_display_name.toLowerCase() || ''}`
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.7778 19.7778H4.22222V4.22222H12V2H4.22222C2.98889 2 2 3 2 4.22222V19.7778C2 21 2.98889 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V12H19.7778V19.7778ZM14.2222 2V4.22222H18.2111L7.28889 15.1444L8.85556 16.7111L19.7778 5.78889V9.77778H22V2H14.2222Z"
                  fill="#590060"
                />
              </svg>
              {insight.type === 'ssm_insight' ? 'Open SSM' : 'Open CPI'}
            </MuiLink>
          </Box>
        </Box>
      </Box>
      {!insight?.data?.trend_data && (
        <ContentInner>
          <InsightsImageWrapper
            sx={{
              padding: insight.type === 'ssm_insight' ? '0 !important' : '40px 0'
            }}
          >
            <img src={getInsightImage()} alt="" />
          </InsightsImageWrapper>
        </ContentInner>
      )}
      {renderInsightEvidences}
    </MainSectionContent>
  )
}

export default MainSection
