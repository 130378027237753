import { FC, useMemo, Dispatch, SetStateAction, useState } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'

/* Utils */
import axios from 'lib/axios'
import {
  DataFilter,
  DataMappingDefaultValues,
  DataMappingField,
  DataMappingOption,
  FieldOption,
  IPreviewData,
  JiraField,
  JiraOperator,
  JiraUser
} from 'models'
import { closeToast, openToast, ToastVariant } from 'store/toasts/toastSlice'
import { useAppDispatch } from 'store/hooks'

/* Components */
import MainSectionTitle from '../components/MainSectionTitle'
import MainSectionSummary from '../components/MainSectionSummary'
import { ThemeButton } from 'ui/components/buttons'
import DataFilteringControls from '../data-filtering/DataFilteringControls'
import DataMapping from '../data-mapping/DataMapping'
import PreviewData from '../preview-data/PreviewData'
import {
  ConnectedProjectsWrapper,
  MainSectionWrapper,
  PreviewSectionWrapper,
  SectionsListWrapper
} from '../../integrations.styles'
import { ModalType, openModal } from '../../../../../../store/modals/modalSlice'
import { emptyFormikField } from '../data-filtering/dataFilteringControls.constants'

interface Props {
  instanceLoaded: boolean
  instanceId: string
  activeSection: number
  initialLoadProject: boolean
  hasSavedDataFilters: boolean
  dataFilters: DataFilter[]
  setDataFilters: Dispatch<SetStateAction<DataFilter[]>>
  setHasSavedDataFilters: Dispatch<SetStateAction<boolean>>
  setPreviewData: Dispatch<SetStateAction<IPreviewData | null>>
  filtersFormik: any
  jiraFields: JiraField[]
  jiraOperators: JiraOperator[]
  searchByJiraField: (
    instanceId: string,
    apiName: string
  ) => Promise<null | { field: string; jiraField: string; apiName: string; options: any[] }>
  fetchJiraPreview: (fields?: DataFilter[]) => Promise<void>
  dataMappingFields: DataMappingField[]
  dataMappingOptions: DataMappingOption[]
  dataMappingValues: Record<string, string[]>
  setDataMappingValues: Dispatch<SetStateAction<Record<string, string[]>>>
  dataMappingDefaults: DataMappingDefaultValues[]
  dataMappingDefaultsValues: any
  setDataMappingDefaultsValues: Dispatch<SetStateAction<any>>
  previewData: IPreviewData | null
  initialLoadPreview: boolean
  setProjectDisconnected: Dispatch<SetStateAction<boolean>>
  handleChangeSection: (newSection: number) => void
  jiraFiltersLoading: boolean
  noDataMappingFields: boolean
  setupType: string
  valueAssignee: JiraUser[]
  setValueAssignee: Dispatch<SetStateAction<JiraUser[]>>
  valueReporter: JiraUser[]
  setValueReporter: Dispatch<SetStateAction<JiraUser[]>>
  inputValueAssignee: string
  setInputValueAssignee: Dispatch<SetStateAction<string>>
  inputValueReporter: string
  setInputValueReporter: Dispatch<SetStateAction<string>>
  vendorsAssignee: JiraUser[]
  setVendorsAssignee: Dispatch<SetStateAction<JiraUser[]>>
  vendorsReporter: JiraUser[]
  setVendorsReporter: Dispatch<SetStateAction<JiraUser[]>>
  selectedOptionsAssignee: JiraUser[]
  setSelectedOptionsAssignee: Dispatch<SetStateAction<JiraUser[]>>
  selectedOptionsReporter: JiraUser[]
  setSelectedOptionsReporter: Dispatch<SetStateAction<JiraUser[]>>
}

const ConnectedProjects: FC<Props> = ({
  instanceLoaded,
  instanceId,
  activeSection,
  initialLoadProject,
  hasSavedDataFilters,
  dataFilters,
  setDataFilters,
  setHasSavedDataFilters,
  setPreviewData,
  filtersFormik,
  jiraFields,
  jiraOperators,
  searchByJiraField,
  fetchJiraPreview,
  dataMappingFields,
  dataMappingOptions,
  dataMappingValues,
  setDataMappingValues,
  dataMappingDefaults,
  dataMappingDefaultsValues,
  setDataMappingDefaultsValues,
  previewData,
  initialLoadPreview,
  setProjectDisconnected,
  handleChangeSection,
  jiraFiltersLoading,
  // noDataMappingFields,
  setupType,
  valueAssignee,
  setValueAssignee,
  valueReporter,
  setValueReporter,
  inputValueAssignee,
  setInputValueAssignee,
  inputValueReporter,
  setInputValueReporter,
  vendorsAssignee,
  setVendorsAssignee,
  vendorsReporter,
  setVendorsReporter,
  selectedOptionsAssignee,
  setSelectedOptionsAssignee,
  selectedOptionsReporter,
  setSelectedOptionsReporter
}) => {
  const dispatch = useAppDispatch()
  const [loadingPreviewData, setLoadingPreviewData] = useState(false)
  const [fieldOptions, setFieldOptions] = useState<FieldOption[]>([])
  const checkFilters = useMemo(() => {
    if (dataFilters.length > 0) {
      return (
        dataFilters[0].apiName && dataFilters[0].field && dataFilters[0].operator && dataFilters[0].values.length > 0
      )
    }

    return false
  }, [dataFilters])

  const handleDisconnect = async () => {
    try {
      setProjectDisconnected(true)
      const section = activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities'
      await axios.post(`/api/v3/integrations/jira/config/${instanceId}/disconnect/${section}`)

      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: 'Project disconnected.',
            description: ''
          }
        })
      )
      const thisDataFilterApiNames = dataFilters.map((c) => c.apiName)
      const newJiraFields = jiraFields.filter((c) => thisDataFilterApiNames.includes(c.apiName))
      filtersFormik.setValues((pre: any) => ({
        ...pre,
        fields: newJiraFields.length > 0 ? newJiraFields : [emptyFormikField]
      }))
      setHasSavedDataFilters(false)
      setDataMappingDefaultsValues((pre: any) => ({
        ...pre,
        status: dataMappingValues.closed_status
      }))
      setPreviewData(null)
    } catch (err) {
      console.error(err)
      setProjectDisconnected(false)
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const handleClickDisconnect = () => {
    dispatch(
      openModal({
        type: ModalType.disconnectJiraProject,
        props: { callback: handleDisconnect }
      })
    )
  }

  const handleApplyDefaultValues = (values: string[], isDefault?: boolean) => {
    for (const [key] of Object.entries(dataMappingDefaultsValues)) {
      const dataMappingDefault = dataMappingDefaults.find((c) => c.jiraField.apiName === key)

      if (dataMappingDefault) {
        const { id } = dataMappingDefault.dataMappingField
        const newDataMappingValue = values
        setDataMappingValues((pre) => ({
          ...pre,
          [id]: newDataMappingValue,
          isDefault: Boolean(isDefault) as any
        }))
      }
    }
  }

  const getSummary = () => {
    let string = ''

    dataFilters.forEach((filter) => {
      const operator = jiraOperators.find((c) => c.name === filter.operator)
      string +=
        string.length === 0
          ? `${filter.field} ${
              filter.operator === 'equals'
                ? 'is'
                : filter.operator === 'not_equals'
                ? 'is not'
                : operator?.displayName || filter.operator
            } ${filter.values.map((value) => (value.name ? `'${value.name}'` : `'${value}'`)).join(' OR ')}`
          : ` AND ${filter.field} ${
              filter.operator === 'equals'
                ? 'is'
                : filter.operator === 'not_equals'
                ? 'is not'
                : operator?.displayName || filter.operator
            } ${filter.values.map((value) => (value.name ? `'${value.name}'` : `'${value}'`)).join(' OR ')}`
    })

    return string
  }

  const getMappingSummary = () => {
    const arr = []
    for (const [key, value] of Object.entries(dataMappingValues)) {
      if (value.length > 0) {
        const keyName =
          key === 'closed_status'
            ? 'Closed Status'
            : key === 'critical_severity'
            ? 'Critical Severity'
            : key === 'high_severity'
            ? 'High Severity'
            : key === 'medium_severity'
            ? 'Medium Severity'
            : 'Low Severity'
        arr.push(`${keyName}: ${value.map((c: any) => c.name).join(', ')}`)
      }
    }

    return arr
  }

  return (
    <>
      {instanceLoaded && !instanceId && setupType === 'add' ? (
        <ConnectedProjectsWrapper className="connected-projects-wrapper no-connection">
          <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="2" width="124" height="124" rx="62" stroke="#4D444B" strokeWidth="4" strokeDasharray="8 8" />
            <path
              d="M52.8889 46L44 54.8889L52.8889 63.7778V57.1111H61.7778V52.6667H52.8889V46ZM68.4444 52.6667C69.6667 52.6667 70.6667 53.6667 70.6667 54.8889C70.6667 56.1111 69.6667 57.1111 68.4444 57.1111C67.2222 57.1111 66.2222 56.1111 66.2222 54.8889C66.2222 53.6667 67.2222 52.6667 68.4444 52.6667ZM77.3333 52.6667C78.5556 52.6667 79.5556 53.6667 79.5556 54.8889C79.5556 56.1111 78.5556 57.1111 77.3333 57.1111C76.1111 57.1111 75.1111 56.1111 75.1111 54.8889C75.1111 53.6667 76.1111 52.6667 77.3333 52.6667ZM75.1111 74.8889H66.2222V70.4444H75.1111V63.7778L84 72.6667L75.1111 81.5556V74.8889ZM59.5556 74.8889C58.3333 74.8889 57.3333 73.8889 57.3333 72.6667C57.3333 71.4444 58.3333 70.4444 59.5556 70.4444C60.7778 70.4444 61.7778 71.4444 61.7778 72.6667C61.7778 73.8889 60.7778 74.8889 59.5556 74.8889ZM50.6667 74.8889C49.4444 74.8889 48.4444 73.8889 48.4444 72.6667C48.4444 71.4444 49.4444 70.4444 50.6667 70.4444C51.8889 70.4444 52.8889 71.4444 52.8889 72.6667C52.8889 73.8889 51.8889 74.8889 50.6667 74.8889Z"
              fill="#D9BFD4"
            />
          </svg>
          <Box className="no-connection-wrapper">
            <Typography className="title">No Connection Found</Typography>
            <Typography className="text">
              Connection to Jira instance must be established first, before connecting a project
            </Typography>
          </Box>
        </ConnectedProjectsWrapper>
      ) : (
        <ConnectedProjectsWrapper className="connected-projects-wrapper">
          <SectionsListWrapper className="sections-list-wrapper">
            <List>
              <ListItem
                disablePadding
                sx={{
                  cursor: jiraFiltersLoading ? 'wait' : ''
                }}
              >
                <ListItemButton
                  onClick={() => handleChangeSection(0)}
                  sx={{
                    background: activeSection === 0 ? '#573f56' : 'transparent',

                    '&.Mui-disabled': {
                      opacity: 1
                    }
                  }}
                  disabled={jiraFiltersLoading}
                >
                  <ListItemText primary="Application Security" />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  cursor: jiraFiltersLoading ? 'wait' : ''
                }}
              >
                <ListItemButton
                  onClick={() => handleChangeSection(1)}
                  sx={{
                    background: activeSection === 1 ? '#573f56' : 'transparent',

                    '&.Mui-disabled': {
                      opacity: 1
                    }
                  }}
                  disabled={jiraFiltersLoading}
                >
                  <ListItemText primary="Security Incidents" />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  cursor: jiraFiltersLoading ? 'wait' : ''
                }}
              >
                <ListItemButton
                  onClick={() => handleChangeSection(2)}
                  sx={{
                    background: activeSection === 2 ? '#573f56' : 'transparent',

                    '&.Mui-disabled': {
                      opacity: 1
                    }
                  }}
                  disabled={jiraFiltersLoading}
                >
                  <ListItemText primary="Vulnerabilities" />
                </ListItemButton>
              </ListItem>
            </List>
          </SectionsListWrapper>
          <Box className="main-content-container">
            {initialLoadProject && hasSavedDataFilters && !jiraFiltersLoading ? (
              <MainSectionWrapper className="main-section-wrapper saved-filters">
                <MainSectionTitle activeSection={activeSection} />
                <MainSectionSummary summary={getSummary()} mappingSummary={getMappingSummary()} />

                <Box className="disconnect-project-wrapper">
                  <ThemeButton themevariant="secondary" onClick={handleClickDisconnect}>
                    <>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM18.31 16.9L7.1 5.69C8.45 4.63 10.15 4 12 4C16.42 4 20 7.58 20 12C20 13.85 19.37 15.55 18.31 16.9Z"
                          fill="#FFA9FC"
                        />
                      </svg>
                      Disconnect Project
                    </>
                  </ThemeButton>
                </Box>
              </MainSectionWrapper>
            ) : (
              <MainSectionWrapper className="main-section-wrapper unsaved-filters">
                <MainSectionTitle activeSection={activeSection} />

                <Box className="data-filtering-wrapper">
                  <Box className="data-filtering-header">
                    <Typography className="data-filtering-title">Data Filtering</Typography>
                    <Typography className="data-filtering-subtitle">
                      Specify the criteria to filter and refine the data for accurate results
                    </Typography>
                  </Box>
                  <Box className="data-filtering-controls-wrapper">
                    <DataFilteringControls
                      dataFilters={dataFilters}
                      setDataFilters={setDataFilters}
                      formik={filtersFormik}
                      jiraFields={jiraFields}
                      jiraOperators={jiraOperators}
                      initialLoad={initialLoadProject}
                      instanceId={instanceId}
                      searchByJiraField={searchByJiraField}
                      fetchJiraPreview={fetchJiraPreview}
                      fieldOptions={fieldOptions}
                      setFieldOptions={setFieldOptions}
                      jiraFiltersLoading={jiraFiltersLoading}
                      setPreviewData={setPreviewData}
                      valueAssignee={valueAssignee}
                      setValueAssignee={setValueAssignee}
                      valueReporter={valueReporter}
                      setValueReporter={setValueReporter}
                      inputValueAssignee={inputValueAssignee}
                      setInputValueAssignee={setInputValueAssignee}
                      inputValueReporter={inputValueReporter}
                      setInputValueReporter={setInputValueReporter}
                      vendorsAssignee={vendorsAssignee}
                      setVendorsAssignee={setVendorsAssignee}
                      vendorsReporter={vendorsReporter}
                      setVendorsReporter={setVendorsReporter}
                      setDataMappingValues={setDataMappingValues}
                      selectedOptionsAssignee={selectedOptionsAssignee}
                      setSelectedOptionsAssignee={setSelectedOptionsAssignee}
                      selectedOptionsReporter={selectedOptionsReporter}
                      setSelectedOptionsReporter={setSelectedOptionsReporter}
                      setDataMappingDefaultsValues={setDataMappingDefaultsValues}
                    />
                  </Box>
                </Box>

                <Box className="data-mapping-wrapper">
                  <Box className="data-mapping-header">
                    <Typography className="data-mapping-title">Data Mapping</Typography>
                    <Typography className="data-mapping-subtitle">
                      Match the Jira data fields to their corresponding Onyxia target fields for proper alignment
                    </Typography>
                  </Box>
                  {/*    {dataMappingFields.length > 0 && !noDataMappingFields ? ( */}
                  {dataMappingFields.length > 0 && checkFilters ? (
                    <DataMapping
                      dataMappingFields={dataMappingFields}
                      dataMappingOptions={dataMappingOptions}
                      jiraFields={jiraFields}
                      dataMappingValues={dataMappingValues}
                      setDataMappingValues={setDataMappingValues}
                      dataMappingDefaults={dataMappingDefaults}
                      dataMappingDefaultsValues={dataMappingDefaultsValues}
                      setDataMappingDefaultsValues={setDataMappingDefaultsValues}
                      handleApplyDefaultValues={handleApplyDefaultValues}
                      fieldOptions={fieldOptions}
                      jiraFiltersLoading={jiraFiltersLoading}
                    />
                  ) : (
                    <Box className="no-mapping-wrapper">
                      <Typography className="no-mapping-title">No Data Filters Found</Typography>
                      <Typography className="no-mapping-subtitle">
                        You must have at least one filter in order to map data fields
                      </Typography>
                    </Box>
                  )}
                </Box>
              </MainSectionWrapper>
            )}

            <PreviewSectionWrapper>
              {!previewData && !jiraFiltersLoading ? (
                <Box className="empty-preview">
                  <svg
                    className="empty-preview-image"
                    width="128"
                    height="129"
                    viewBox="0 0 128 129"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="2"
                      y="2.5"
                      width="124"
                      height="124"
                      rx="62"
                      stroke="#4D444B"
                      strokeWidth="4"
                      strokeDasharray="8 8"
                    />
                    <path
                      d="M64 53.5C71.58 53.5 78.34 57.76 81.64 64.5C78.34 71.24 71.58 75.5 64 75.5C56.42 75.5 49.66 71.24 46.36 64.5C49.66 57.76 56.42 53.5 64 53.5ZM64 49.5C54 49.5 45.46 55.72 42 64.5C45.46 73.28 54 79.5 64 79.5C74 79.5 82.54 73.28 86 64.5C82.54 55.72 74 49.5 64 49.5ZM64 59.5C66.76 59.5 69 61.74 69 64.5C69 67.26 66.76 69.5 64 69.5C61.24 69.5 59 67.26 59 64.5C59 61.74 61.24 59.5 64 59.5ZM64 55.5C59.04 55.5 55 59.54 55 64.5C55 69.46 59.04 73.5 64 73.5C68.96 73.5 73 69.46 73 64.5C73 59.54 68.96 55.5 64 55.5Z"
                      fill="#D9BFD4"
                    />
                  </svg>

                  <Box className="empty-preview-header">
                    <Typography className="empty-preview-title">No Preview Available</Typography>
                    <Typography className="empty-preview-subtitle">
                      Add at least one data filter to preview project sample
                    </Typography>
                  </Box>
                </Box>
              ) : (
                previewData && (
                  <PreviewData
                    previewData={previewData}
                    loadingPreviewData={loadingPreviewData}
                    setLoadingPreviewData={setLoadingPreviewData}
                    initialLoad={initialLoadPreview}
                  />
                )
              )}
            </PreviewSectionWrapper>
          </Box>
        </ConnectedProjectsWrapper>
      )}
    </>
  )
}

export default ConnectedProjects
