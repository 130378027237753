import { Box, Tabs } from '@mui/material'
import styled from '@emotion/styled/macro'
import { TestStatus } from './SetupIntegrationInstance'

export const IntegrationsContainer = styled(Box)<{ hasPadding?: boolean }>`
  width: 100%;
  height: 100%;

  form {
    height: 100%;
    overflow-y: hidden;
    max-height: calc(100vh - 100px);
  }
`

export const IntegrationsHeaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-left: 0;
  transition: width 0.5s;

  .header-inner {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    > div {
      width: 100%;
    }
  }
`

export const DetailsOpenContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 8px;
  width: 100%;
  margin-top: 24px;

  .left-side {
    padding: 0 4px 0 8px;
    max-width: 920px;
    width: 100%;
    overflow-y: auto;
    margin-right: 24px;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }

    .MuiBox-root:first-of-type {
      height: auto;

      .header-inner {
        justify-content: flex-start;
      }
    }
  }
`

export const InstanceDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 558px;
  min-width: 558px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  padding: 24px;
  height: calc(100vh - 323px);
  overflow-y: auto;
  overflow-x: hidden;

  .analyzer-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;

    .title {
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #fff;
    }
  }

  .right-side__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-side__title-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .header-container {
        display: flex;
        align-items: center;
        width: 100%;

        .header-main {
          display: flex;
          align-items: center;
          flex-grow: 1;
          width: 100%;
        }
      }

      .title {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #e9e0e4;
        margin-right: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex-grow: 1;
      }

      .tooltip-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 16px;
        background: rgba(255, 138, 128, 0.2);
        border: 1px solid #ff8a80;
        border-radius: 8px;
        margin-top: 16px;
        margin-bottom: 4px;

        .tooltip-icon {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          align-self: flex-start;
        }

        img {
          margin-right: 8px;
          border-radius: 50%;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .tooltip-title {
            color: #fff;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          .description-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            .tooltip-text {
              color: #fff;
              font-family: 'Quicksand', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.25px;
            }
          }
        }
      }
    }
  }

  .subtitle,
  .body {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #d0c3cc;
    white-space: pre-line;
  }

  .subtitle {
    font-weight: bold;
    margin-top: 12px;
  }

  .right-side-box {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 16px;
    margin-top: 12px;

    .box-title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-bottom: 4px;
      color: white;
    }

    .box-sub-title {
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: white;
      margin-bottom: 10px;
    }

    .box-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #e9e0e4;
      margin-top: 4px;
      white-space: pre-line;

      &.spacing-top {
        margin-top: 20px;
      }
    }
  }
`

export const IntegrationsTabsWrapper = styled(Box)<{ custom?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin: ${({ custom }) => (custom ? '16px 24px 0' : '16px 0 0')};
`

export const TabPanelWrapper = styled(Box)`
  .card {
    background: transparent;
    padding: 16px 16px 16px 0;
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(147, 143, 153, 0.12);
    margin-left: 24px;
    max-width: calc(100% - 465px);

    .data-source-wrapper {
      display: flex;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 12px;
      margin-left: 16px;
    }

    .card-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #ffffff;
      padding-left: 16px;
    }

    .source-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-left: 16px;
    }

    .title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #e9e0e4;
      margin-bottom: 4px;
    }

    .description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      color: #e9e0e4;
      letter-spacing: 0.25px;
      font-size: 14px;
      line-height: 20px;
    }

    img:not(:last-of-type) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 20px;
    }
  }

  .cpi-short-description {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #e9e0e4;
    margin: 8px auto 0;
    max-width: 1686px;
    padding-left: 24px;
  }
`

export const IntegrationsTabs = styled(Tabs)<{ isdetails?: string }>`
  background: transparent;
  height: auto;

  .MuiTabs-scroller {
    height: 50px !important;

    .MuiTabs-flexContainer {
      height: 50px !important;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    width: 94px;
    height: 50px;
    min-height: 50px;
    margin-right: 40px;
    padding-bottom: 30px;

    &.Mui-selected {
      color: #fff;
      border-bottom: 4px solid #ffa9fc;
      padding-bottom: 38px;
      padding-top: 9px;
    }

    &.Mui-disabled {
      color: #debdd9;
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 50px;
    min-height: 50px;
    
    .MuiTabs-scroller {
      height: 50px;
      
      .MuiTabs-flexContainer {
        height: 50px;
      }
    }
    
    .MuiButtonBase-root {
      height: 50px;
      white-space: nowrap;
      width: fit-content;
    }
  `};
`

export const JiraTabsWrapper = styled(Box)<{ custom?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  background: #1e1a1d;
`

export const JiraTabs = styled(Tabs)<{ isdetails?: string }>`
  background: transparent;
  height: auto;
  width: 100%;
  border-bottom: 1px solid #4d444b;
  margin: 0 24px;

  .MuiTabs-scroller {
    height: 64px !important;

    .MuiTabs-flexContainer {
      height: 64px !important;
      align-items: flex-end;

      .MuiButtonBase-root {
        border-radius: 0;
        font-family: 'Quicksand', sans-serif;
        display: flex;
        align-items: center;
        text-align: center;
        border: none;
        text-transform: none;
        padding: 0 0 24px 0;
        margin-right: 40px;
        height: 40px;
        min-height: 40px;
        white-space: nowrap;
        width: fit-content;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.Mui-selected {
          color: #fff;
          border-bottom: 4px solid #ffa9fc;
        }

        &.Mui-disabled {
          color: #d9bfd4;
        }
      }
    }
  }
`

export const ConnectedProjectsWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;

  .main-content-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-basis: 100%;
    height: 100%;
    overflow-x: auto;
  }

  &.no-connection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;

    .no-connection-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .text {
        max-width: 400px;
        color: #d9bfd4;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`

export const SectionsListWrapper = styled(Box)`
  width: 244px;
  min-width: 244px;
  height: 100%;
  padding-right: 24px;
  border-right: 1px solid #4d444b;

  .MuiList-root {
    padding: 0;

    .MuiListItem-root {
      height: 40px;

      .MuiListItemButton-root {
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          background: #573f56;
        }
      }
    }
  }
`

export const MainSectionWrapper = styled(Box)`
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 24px;
  border-right: 1px solid #4d444b;
  height: 100%;
  flex-basis: 50%;
  overflow-y: auto;

  .disconnect-project-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .main-section-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;

    .main-section-summary-title {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
    }

    .main-section-summary-subtitle {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.mapping-summary {
        margin: -8px 0;
      }
    }
  }

  .main-section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    width: 100%;

    .main-section-title {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .main-section-subtitle {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .data-filtering-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 100%;

    .data-filtering-header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      .data-filtering-title {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }

      .data-filtering-subtitle {
        color: #d9bfd4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .data-filtering-controls-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .data-filtering-actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        .data-filtering-actions-wrapper__left {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
    }
  }

  .data-mapping-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    width: 100%;

    .data-mapping-header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      .data-mapping-title {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }

      .data-mapping-subtitle {
        color: #d9bfd4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .no-mapping-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: 120px;
      gap: 8px;
      background: #292128;

      .no-mapping-title {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }

      .no-mapping-subtitle {
        color: #d9bfd4;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`

export const PreviewSectionWrapper = styled(Box)`
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 0 0 24px;
  height: 100%;
  width: 100%;
  flex-basis: 50%;
  overflow-y: auto;
  overflow-x: hidden;

  .empty-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 32px;

    .empty-preview-image {
      min-width: 128px;
      min-height: 128px;
    }

    .empty-preview-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 8px;

      .empty-preview-title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .empty-preview-subtitle {
        color: #d9bfd4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`

export const SelectorsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;

  .filters-wrapper {
    display: flex;
    align-items: center;
    gap: 64px;

    .filters-block {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  .sort-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (min-width: 1280px) {
      padding: 0;
    }

    .sort-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #d0c3cc;
      margin-right: 8px;
    }

    .results-text {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;
      z-index: 10;
      white-space: nowrap;
    }
  }
`

export const IntegrationsListWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  max-width: 920px;
  width: 100%;
`

export const ErrorSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #23053a;
`

export const ActionSpan = styled.span`
  display: inline;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #23053a;
  text-decoration: underline;
  cursor: pointer;
`

export const EditIntegrationContainer = styled(Box)<{ testStatus: TestStatus; isopen?: string }>`
  display: flex;
  gap: 24px;
  height: 100%;
  padding: 24px 0 39px;
  max-height: calc(100vh - 100px); // header height
  width: 100%;

  ${({ isopen }) =>
    isopen === 'false' &&
    `
      justify-content: center;
  `};

  .left {
    width: 100%;
    flex-basis: 50%;
    overflow-y: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 24px;

    ${({ isopen }) =>
      isopen === 'false' &&
      `
      flex-basis: 100%;
      max-width: 752px;
  `};

    .info {
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      gap: 24px;

      .connection-details-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .connection-details-description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 8px;

          .connection-details-title {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            white-space: nowrap;
          }

          .connection-details-text {
            color: #d9bfd4;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
          }
        }
      }

      .MuiInputLabel-root {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .MuiOutlinedInput-root {
        border-radius: 4px;
        height: 40px;
        padding: 0 8px;

        &.MuiInputBase-multiline {
          height: 96px;
        }

        .MuiOutlinedInput-input {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;

          &.MuiInputBase-inputMultiline {
            margin-top: 8px;
          }
        }

        fieldset {
          border-radius: 4px;
          border: 1px solid #998d96;
          padding: 0 8px;
        }
      }

      .MuiInputAdornment-root {
        svg {
          path {
            fill: #debdd9;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: auto;
      width: 100%;
      border-top: 1px solid #4d444b;
      margin-top: 40px;
      padding-top: 24px;

      ${({ testStatus }) =>
        testStatus === TestStatus.successful &&
        `
            .MuiButtonBase-root:first-of-type {
              color: #FFA9FC !important;
            }
            
            svg {
              path {
                fill: #FFA9FC;
              }
            }
            
            &.Mui-disabled {
            color: #7F747C !important;
            
              svg {
                path {
                  fill: #7F747C !important;
                }
              }
            }
        `};
    }

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }
  }

  .right {
    width: 100%;
    flex-basis: 50%;
    padding: 0 24px;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    //height: auto;
    //max-height: calc(100vh - 84px);
    height: 100%;
    border-left: 1px solid #4d444b;
    transition: all 0.2s ease-in-out;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }

    .right-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      margin-bottom: 16px;

      .instructions-text {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .instructions-description {
        color: #d9bfd4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .instructions-inner {
      padding: 24px 0;
      height: 100%;
      flex: 1 1 auto;
      width: 100%;
      border-bottom: 1px solid #4d444b;
      display: flex;
      align-items: flex-start;
      gap: 24px;

      .empty-image {
        width: 160px;
        min-width: 160px;
        height: 100%;
      }

      .instructions-right-side {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;

        a {
          color: rgb(255, 169, 252) !important;

          &:visited,
          &:focus,
          &:focus-visible,
          &:active,
          &:default {
            color: rgb(255, 169, 252) !important;
          }
        }

        p {
          margin: 0;
        }

        .step-text {
          color: #ffa9fc;
          font-size: 16px;
          font-style: normal;
          font-weight: 510;
          line-height: normal;
          margin: 0;
        }

        .section-list-item {
          padding: 0 0 16px 0;
          width: 100%;
          border-bottom: 1px solid #4d444b;

          &.with-circule-bullet {
            margin-top: -32px;
          }

          .list-bullet {
            color: #ffa9fc;
          }

          a {
            color: rgb(255, 169, 252) !important;

            &:visited,
            &:focus,
            &:focus-visible,
            &:active,
            &:default {
              color: rgb(255, 169, 252) !important;
            }
          }
        }
      }

      .instructions-image {
        width: 160px;
        height: 90px;
        min-width: 160px;

        &:hover {
          cursor: pointer;
        }
      }

      ol {
        margin: 0;
        padding: 0 0 0 20px;
        line-break: auto;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          color: rgb(255, 169, 252);

          &:visited {
            color: rgb(255, 169, 252);
          }
        }

        li {
          &::marker {
            color: #ffa9fc;
          }
        }

        li:not(:last-of-type) {
          margin-bottom: 16px;
        }

        li .MuiLink-root {
          color: #ffa9fc;
          text-decoration: underline;
          margin: 0;
          padding: 0;
          font-weight: 600;
        }

        li {
          ul {
            li {
              margin-bottom: 0 !important;
            }
          }
        }

        .tooltip-icon-padded {
          margin.: 8px;
        }

        p {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      .MuiTypography-root {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #fbd8f5;
        margin-left: 4px;
      }

      .copy-text {
        font-family: 'IBM Plex Mono', monospace;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #ffa9fc;
        display: flex;
        align-items: center;
        margin-top: 8px;

        svg {
          margin-left: 4px;
        }
      }
    }
  }
`
