import * as d3 from 'd3'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import { endOfWeek, startOfWeek } from 'date-fns'
import {
  ChartData,
  ChartDataExtended,
  ChartDataValue,
  ChartDataValueExtended,
  CPIFrequency,
  CPIIntegrationItem,
  CPIManifest,
  CPISeverity,
  CPITimePeriod,
  CustomTrendsDataPayload,
  D3Position,
  DataPoint,
  DataStatus,
  FrameworkCategoryOption,
  HistoryBenchmarkDataValues,
  HistoryPerformanceDataExtended,
  IntegrationInstance,
  Label,
  LibraryFilters,
  PerformanceScoreCategoryDetailsDataSources,
  PerformanceScoreCpi,
  PredictionChartData,
  PredictionDataExtended,
  SystemEventName,
  VisibleLabel
} from '../../../models'
import { Checked } from './library-details/LibraryDetails'
import { dateFormat } from '../../../lib/utils'
import axios from '../../../lib/axios'
import { useQuery } from 'react-query'

dayjs.extend(weekday)

export const CUSTOM_LABEL_ID = 'io_onyxia_events_cpi_custom'

export function useSearchCpis({
  value,
  framework,
  filters,
  categoryOptions,
  reset,
  keepPreviousData,
  enabled,
  staleTime
}: {
  value: string
  framework?: string
  filters: LibraryFilters
  categoryOptions: FrameworkCategoryOption[]
  reset: boolean
  keepPreviousData?: boolean
  enabled?: boolean
  staleTime?: any
}) {
  const sortByFormatted =
    filters.sortBy === 'Newest to Oldest' ? 'desc' : filters.sortBy === 'Oldest to Newest' ? 'asc' : 'favorites'
  let url = `/api/v3/cpis/search?sortBy=${sortByFormatted}`
  if (framework) {
    url += `&framework=${framework}`
  }

  if (!reset) {
    if (value.length) {
      url += `&term=${value}`
    }
    if (filters.status.length) {
      const statuses = filters.status.join(',')
      url += `&status=${statuses}`
    }
    if (filters.source.length) {
      const sources = filters.source.join(',')
      url += `&ds=${sources}`
    }
    if (filters.frameworkCategory) {
      const { frameworkCategory } = filters
      url += `&category=${frameworkCategory}`
    }
    if (filters.category.length) {
      const filterCategories = filters.category.join(',')
      const selectedCategories = categoryOptions.filter((n) => filterCategories.includes(n.title))
      const selectedIds = selectedCategories.map((n) => n.id)
      url += `&category=${selectedIds}`
    }
  }

  return useQuery(['cpis', { value }, filters], () => axios.get(url), {
    keepPreviousData,
    /* ONYXIA-3562 */
    // staleTime: 5 * 60 * 1000,
    initialData: undefined,
    // enabled: !keepPreviousData
    enabled,
    staleTime
  })
}

export const monthDiff = (d1: Date, d2: Date) => {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()

  return months < 0 ? 0 : months + 1
}

export const numDays = (y: number, m: number) => new Date(y, m, 0).getDate()

export const extractMonth = (period: string) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const startMonth = period.slice(0, 3)
  const startYearString = period.slice(9)
  let startYear = Number(`20${startYearString}`)
  const endMonth = period.length > 11 ? period.slice(8, 11) : period.slice(4, 7)
  let endYear = startYear
  const startMonthIndex = months.indexOf(startMonth)
  const endMonthIndex = months.indexOf(endMonth)

  if (period.length > 11) {
    const n = period.slice(5, 7)
    startYear = Number(`20${n}`)
    const c = period.slice(-2)
    endYear = Number(`20${c}`)
  }

  const dateFrom = `${startYear}-${startMonthIndex < 9 ? '0' : ''}${startMonthIndex + 1}-01`
  const endDay = numDays(endYear, endMonthIndex + 1)
  const dateTo = `${endYear}-${endMonthIndex < 9 ? '0' : ''}${endMonthIndex + 1}-${endDay}`

  return {
    dateFrom,
    dateTo
  }
}

export const getPayloadTrendsChart = ({
  severity,
  selectedDataSourceIds,
  selectedTimePeriod,
  selectedFrequency,
  customTimePeriodValue,
  type
}: {
  severity: CPISeverity
  selectedDataSourceIds: string[]
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  customTimePeriodValue?: string
  type?: string
}) => {
  const timePeriod = getPayloadTimePeriod(selectedTimePeriod)

  const payload: CustomTrendsDataPayload = {
    section: severity,
    integration_ids: selectedDataSourceIds,
    frequency: selectedFrequency,
    type
  }

  if (customTimePeriodValue) {
    const { dateFrom, dateTo } = extractMonth(customTimePeriodValue)
    const dateToTemp = new Date(dateTo).getTime()
    const today = new Date().getTime()
    const dateToFormatted = dateToTemp > today ? dayjs().format('YYYY-MM-DD') : dateTo
    payload.dateFrom = dateFrom
    payload.dateTo = dateToFormatted
    payload.stripes = monthDiff(new Date(dateFrom), new Date(dateTo))
  } else {
    payload.timePeriod = timePeriod
  }

  return payload
}

export const getUtcDateAndTime = (date: string, timeZone = 'UTC') => {
  const now = new Date(date)

  const defaultDate = {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
    milliseconds: 0,
    formattedDateTime: date,
    formattedDate: date,
    utcFormattedDate: date,
    isoFormattedDate: date,
    labelFormatDate: date
  }

  if (isNaN(now.getTime())) {
    return defaultDate
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }

  const formattedDateTime = now.toLocaleString('en-US', options)
  const formattedDate = now.toLocaleDateString('en-US')
  const utcFormattedDate = new Date(now).toUTCString()
  const isoFormattedDate = new Date(now).toISOString()
  const labelFormatDate = isoFormattedDate.slice(0, 10)
  const milliseconds = new Date(formattedDate).getTime()

  const [datePart, timePart] = formattedDateTime.split(', ')
  const [monthStr, dayStr, yearStr] = datePart.split('/')
  const [hourStr, minuteStr, secondStr] = timePart.split(':')

  const year = parseInt(yearStr, 10)
  const month = parseInt(monthStr, 10)
  const day = parseInt(dayStr, 10)
  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)
  const second = parseInt(secondStr, 10)

  if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hour) || isNaN(minute) || isNaN(second)) {
    return defaultDate
  }

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
    milliseconds,
    formattedDateTime,
    formattedDate,
    utcFormattedDate,
    isoFormattedDate,
    labelFormatDate
  }
}

export const getSystemEventValue = (event: SystemEventName): string => {
  const prunedEvent = event.replaceAll('.', '_')
  switch (prunedEvent) {
    case SystemEventName.CpiActivated:
      return 'CPI Activated'
    case SystemEventName.SlaChangedEvent:
      return 'SLA Changed'
    case SystemEventName.CpiCustom:
      return 'Custom Event'
    case SystemEventName.CpiInstanceAdded:
      return 'Instance Added'
    case SystemEventName.CpiInstanceRemoved:
      return 'Instance Removed'
    default:
      return ''
  }
}

export const getPayloadTimePeriod = (period: CPITimePeriod): '1w' | '1m' | '3m' | '6m' | '1y' => {
  if (period === CPITimePeriod.Week) {
    return '1w'
  }
  if (period === CPITimePeriod.Month) {
    return '1m'
  }
  if (period === CPITimePeriod.ThreeMonths) {
    return '3m'
  }
  if (period === CPITimePeriod.SixMonths) {
    return '6m'
  }
  return '1y'
}

export const payloadToEnumTimePeriod = (period: string): CPITimePeriod => {
  if (period === '1w') {
    return CPITimePeriod.Week
  }
  if (period === '1m') {
    return CPITimePeriod.Month
  }
  if (period === '3m') {
    return CPITimePeriod.ThreeMonths
  }
  if (period === '6m') {
    return CPITimePeriod.SixMonths
  }
  return CPITimePeriod.PastYear
}

export const getSlaUnit = (unit?: string) => {
  if (unit) {
    if (unit === 'seconds') {
      return 's'
    }
    if (unit === 'hours') {
      return 'h'
    }
    if (unit === 'percent') {
      return '%'
    }
    if (unit === 'days') {
      return 'd'
    }
  }

  return unit === undefined ? '' : ` ${unit}`
}

export const between = (x: number, min: number, max: number) => {
  return x >= min && x < max
}

export const getSlaFullUnit = (unit?: string) => {
  if (!unit) {
    return ''
  }

  if (unit === 'percent') {
    return '%'
  }

  return unit
}

export const formatPerformanceUnit = (unit?: string, hideTexts = false, capitalize = false) => {
  let suffix = ''

  if (unit) {
    if (hideTexts && ['assets', 'alerts', 'incidents'].includes(unit)) return ''

    switch (unit) {
      case 'percent':
        suffix = '%'
        break
      case 'days':
        suffix = capitalize ? 'D' : 'd'
        break
      case 'seconds':
        suffix = 's'
        break
      case 'hours':
        suffix = 'h'
        break
      default:
        suffix = ` ${unit}`
        break
    }
  }

  return suffix
}

export const urlToCategory = (category: string) => {
  return category.replaceAll('-', ' ').replaceAll('and', '&')
}

export const urlToCategoryCapitalized = (category: string) => {
  const cat = category || ''
  const text = cat.replaceAll('-', ' ').replaceAll('and', '&')
  const split = text.split(' ')

  return split.map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(' ')
}

export const categoryToUrl = (category: string, isCategory = false) => {
  if (isCategory && category === 'Identity & Access Management') return 'iam'
  const part1 = category.replaceAll(' ', '-')
  const part2 = part1.replaceAll('&', 'and')

  return part2.toLowerCase()
}

export const getEnabledInstances = (
  cpiConfigInstances: CPIIntegrationItem[],
  userConfiguredIntegrations: IntegrationInstance[]
) => {
  const configuredIntegrationsIds = cpiConfigInstances.map((n: CPIIntegrationItem) => n.id)
  const configuredInstancesApplicableToCpi = userConfiguredIntegrations.filter((n) =>
    configuredIntegrationsIds.includes(n.id)
  )
  const configuredIntegrationsNames = configuredInstancesApplicableToCpi.map((n: CPIIntegrationItem) => n.name)
  const newChecked: Checked = {}
  configuredIntegrationsNames.forEach((n) => {
    newChecked[n] = true
  })

  return newChecked
}

export const getConfiguredInstancesApplicableToCpi = (
  configuredIntegrations: CPIIntegrationItem[],
  userConfiguredIntegrations: IntegrationInstance[]
) => {
  const configuredIntegrationsIds = configuredIntegrations.map((n: CPIIntegrationItem) => n.id)

  return userConfiguredIntegrations.filter((n) => configuredIntegrationsIds.includes(n.id))
}

export const getNotConfiguredInstancesApplicableToCpi = (
  manifestData: CPIManifest,
  configuredIntegrations: CPIIntegrationItem[],
  userConfiguredIntegrations: IntegrationInstance[]
) => {
  const configuredIntegrationsIds = configuredIntegrations.map((n: CPIIntegrationItem) => n.id)
  const supportedIntegrationNames = manifestData.supported_integrations.map((n) => n.name)
  const userConfiguredAndCpiSupportedInstances = userConfiguredIntegrations.filter((n) =>
    supportedIntegrationNames.includes(n.integration_name)
  )

  return userConfiguredAndCpiSupportedInstances.filter((n) => !configuredIntegrationsIds.includes(n.id))
}

export const addStartEndWeekly = (data: ChartData): ChartDataExtended => {
  const groupedData: {
    [key: string]: {
      xValue: string
      yValue: number
      start: string
      end: string
      numerator: number
      denominator: number
    }
  } = {}

  const today = new Date()
  const todayDay = today.getUTCDate()
  const todayMonth = today.getUTCMonth() + 1
  const todayYear = today.getUTCFullYear()

  data.values.forEach((entry) => {
    const date = new Date(entry.xValue)
    const weekStartDay = 1
    const year = parseInt(entry.xValue.slice(0, 4), 10)

    const startOfWeekDate = new Date(date)
    const diffStart = date.getUTCDay() - weekStartDay
    startOfWeekDate.setUTCDate(date.getUTCDate() - diffStart)

    const endOfWeekDate = new Date(startOfWeekDate)
    const diffEnd = 7 - startOfWeekDate.getUTCDay()
    endOfWeekDate.setUTCDate(startOfWeekDate.getUTCDate() + diffEnd)

    let startDay = startOfWeekDate.getUTCDate()
    const startMonth = startOfWeekDate.getUTCMonth() + 1
    if (todayDay < startDay && todayMonth === startMonth && year === todayYear) {
      startDay = todayDay - 6
    }

    const endDay = endOfWeekDate.getUTCDate()
    const endMonth = endOfWeekDate.getUTCMonth() + 1
    const endYear = endOfWeekDate.getUTCFullYear()

    const groupKey = `${endYear}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        xValue: entry.xValue,
        yValue: entry.yValue,
        numerator: entry.numerator,
        denominator: entry.denominator,
        start: `${endYear}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
      }
    }
  })

  const result: ChartDataValueExtended[] = []
  for (const key in groupedData) {
    result.push({
      xValue: groupedData[key].xValue || '',
      yValue: groupedData[key].yValue,
      start: groupedData[key].start || '',
      end: groupedData[key].end || '',
      numerator: groupedData[key].numerator,
      denominator: groupedData[key].denominator
    })
  }

  return { ...data, values: result }
}

export const groupByWeeks = (data: ChartData): ChartDataExtended => {
  const groupedData: {
    [key: string]: {
      xValue: string
      yValue: number
      start: string
      end: string
      numerator: number
      denominator: number
    }
  } = {}

  const today = new Date()
  const todayDay = today.getUTCDate()
  const todayMonth = today.getUTCMonth() + 1
  const todayYear = today.getUTCFullYear()
  // const lastDataPoint = data.values[data.values.length - 1].xValue

  data.values.forEach((entry) => {
    const date = new Date(entry.xValue)
    const weekStartDay = 1
    const year = parseInt(entry.xValue.slice(0, 4), 10)

    const startOfWeekDate = new Date(date)
    const diffStart = date.getUTCDay() - weekStartDay
    startOfWeekDate.setUTCDate(date.getUTCDate() - diffStart)

    const endOfWeekDate = new Date(startOfWeekDate)
    const diffEnd = 7 - startOfWeekDate.getUTCDay()
    endOfWeekDate.setUTCDate(startOfWeekDate.getUTCDate() + diffEnd)

    let startDay = startOfWeekDate.getUTCDate()
    const startMonth = startOfWeekDate.getUTCMonth() + 1
    if (todayDay < startDay && todayMonth === startMonth && year === todayYear) {
      startDay = todayDay - 6
    }

    const endDay = endOfWeekDate.getUTCDate()
    const endMonth = endOfWeekDate.getUTCMonth() + 1
    const endYear = endOfWeekDate.getUTCFullYear()

    // const groupKey = `${endYear}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`
    // let groupKey = ''
    // const isLast = startOfWeekDate.toDateString() === new Date(lastDataPoint).toDateString()
    // if ((isLast && entry.yValue) || !isLast) {
    const groupKey = `${endYear}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`
    // }
    const startGroupKey = groupKey.replace('W', '')

    if (new Date(startGroupKey).getTime() > new Date(entry.xValue).getTime()) return
    // if (!groupedData[groupKey]) {
    if (groupKey && !groupedData[groupKey]) {
      groupedData[groupKey] = {
        xValue: entry.xValue,
        yValue: entry.yValue,
        numerator: entry.numerator,
        denominator: entry.denominator,
        start: `${endYear}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
      }
    }
  })

  const result: ChartDataValueExtended[] = []
  for (const key in groupedData) {
    const prunedXValue = key.replace('W', '')
    const utcValue = getUtcDateAndTime(prunedXValue)

    result.push({
      xValue: utcValue?.isoFormattedDate || '',
      yValue: groupedData[key].yValue,
      start: groupedData[key].start || '',
      end: groupedData[key].end || '',
      numerator: groupedData[key].numerator,
      denominator: groupedData[key].denominator
    })
  }

  return { ...data, values: result }
}

export const groupByMonths = (data: ChartData): ChartData => {
  const groupedData: {
    [key: string]: {
      xValue: string
      yValue: number
      denominator: number
      numerator: number
      year: number
      month: number
      week?: number
    }
  } = {}

  data.values.forEach((entry) => {
    const { month, year } = getUtcDateAndTime(entry.xValue)
    const key = `${year}-${month < 10 ? '0' : ''}${month}`

    if (!groupedData[key]) {
      groupedData[key] = {
        year,
        month,
        xValue: entry.xValue || '',
        yValue: entry.yValue || 0,
        denominator: entry.denominator || 0,
        numerator: entry.numerator || 0
      }
    }
  })

  const result: ChartDataValue[] = []
  for (const key in groupedData) {
    const groupEntries = data.values.filter((entry) => entry.xValue.startsWith(key))

    result.push({ ...groupEntries[0] })
  }

  return { ...data, values: result }
}

export function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}
export function convertDateToUTCString(date: Date) {
  return convertDateToUTC(date).toUTCString()
}

export const filterDateByMonth = (
  data: ChartDataValue[],
  month: string
): ChartDataValue[] | ChartDataValueExtended[] => {
  const curDat = data.filter((n) => n.xValue.includes(month))
  if (!curDat.length) return []
  const currentDate = getUtcDateAndTime(curDat[0].xValue).utcFormattedDate
  const { month: currentMonth, year: currentYear } = getUtcDateAndTime(currentDate)

  return data.filter((e) => {
    const thisMonth = getUtcDateAndTime(e.xValue).month
    const thisYear = getUtcDateAndTime(e.xValue).year

    return thisMonth === currentMonth && thisYear === currentYear
  })
}

export const filterDateByMonthPredictions = (
  data: PredictionDataExtended[],
  month: string
): PredictionDataExtended[] => {
  const curDat = data.filter((n) => n.xValue.includes(month))
  if (!curDat.length) return []
  const currentDate = getUtcDateAndTime(curDat[0].xValue).utcFormattedDate
  const { month: currentMonth, year: currentYear } = getUtcDateAndTime(currentDate)

  return data.filter((e) => {
    const thisMonth = getUtcDateAndTime(e.xValue).month
    const thisYear = getUtcDateAndTime(e.xValue).year

    return thisMonth === currentMonth && thisYear === currentYear
  })
}

/*
 * add start and end to each date in data set.
 * return original dataset with start and end prop to each item in dataset
 * */
export const addWeekBreakpoints = (data: ChartDataValue[]): ChartDataValueExtended[] => {
  return data.map((entry) => {
    const { year } = getUtcDateAndTime(entry.xValue)
    const date = new Date(entry.xValue)
    const weekStartDay = 1

    const startOfWeekDate = new Date(date)
    const diffStart = date.getUTCDay() - weekStartDay
    startOfWeekDate.setUTCDate(date.getUTCDate() - (diffStart < 0 ? 6 : diffStart))

    const endOfWeekDate = new Date(startOfWeekDate)
    const diffEnd = 7 - startOfWeekDate.getUTCDay()
    endOfWeekDate.setUTCDate(startOfWeekDate.getUTCDate() + diffEnd)
    const startDay = startOfWeekDate.getUTCDate()
    const startMonth = startOfWeekDate.getUTCMonth() + 1

    const endDay = endOfWeekDate.getUTCDate()
    const endMonth = endOfWeekDate.getUTCMonth() + 1
    const endYear = endOfWeekDate.getUTCFullYear()

    return {
      xValue: entry.xValue,
      yValue: entry.yValue,
      numerator: entry.numerator,
      denominator: entry.denominator,
      start: `${year}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
      end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
    }
  })
}

export const filterDateByWeekWithStartEnd = (data: ChartDataValue[], start: string, end: string): ChartDataValue[] => {
  const startTime = new Date(start).getTime()
  const endTime = new Date(end).getTime()

  return data.filter((e) => {
    const currentDate = getUtcDateAndTime(e.xValue).utcFormattedDate
    const currentTime = new Date(currentDate).getTime()
    const currentDayTime = new Date(currentTime).getTime()

    return currentDayTime >= startTime && currentDayTime <= endTime
  })
}

export const filterDateByWeek = (data: ChartDataValue[], day: string): ChartDataValue[] => {
  const date = new Date(day)
  const weekStartDay = 1
  const dayOfWeek = dayjs(date).weekday()

  const startOfWeekDate = new Date(date)
  const diffStart = date.getUTCDay() - weekStartDay
  if (dayOfWeek !== 1) {
    startOfWeekDate.setUTCDate(date.getUTCDate() - diffStart)
  }

  const endOfWeekDate = new Date(startOfWeekDate)
  const diffEnd = 7 - startOfWeekDate.getUTCDay()
  endOfWeekDate.setUTCDate(startOfWeekDate.getUTCDate() + diffEnd)

  return data.filter((n) => {
    const current = new Date(n.xValue)

    return current >= startOfWeekDate && current <= endOfWeekDate
  })
}

export const filterDateByDay = (data: ChartDataValue[], day: string): ChartDataValue[] => {
  const currentDate = new Date(day).getUTCDate()

  return data.filter((e: any) => {
    const thisDate = new Date(e.xValue).getUTCDate()

    return thisDate === currentDate
  })
}

export const findNearestXIndex = (array: D3Position[], targetX: number): DataPoint | null => {
  let minDiff = Infinity
  let nearestObject = null

  for (let i = 0; i < array.length; i++) {
    const diff = Math.abs(array[i].x - targetX)

    if (diff < minDiff) {
      minDiff = diff
      nearestObject = {
        x: array[i].x,
        y: array[i].y,
        value: array[i].value,
        index: i,
        monthIndex: array[i].monthIndex
      }
    }
  }

  return nearestObject
}

export const findUniqueMonths = ({
  filteredData,
  groupedByWeeks,
  selectedTimePeriod
}: {
  filteredData: ChartDataValue[]
  groupedByWeeks: ChartDataExtended | null
  selectedTimePeriod: CPITimePeriod
}): string[] => {
  const uniqueMonths: string[] = []

  if (selectedTimePeriod === CPITimePeriod.Week) {
    filteredData.forEach((item) => {
      const { month, year, day } = getUtcDateAndTime(item.xValue)
      const monthKey = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  } else if (selectedTimePeriod === CPITimePeriod.Month && groupedByWeeks) {
    return groupedByWeeks.values.map((n) => n.xValue.slice(0, 10))
  } else {
    filteredData.forEach((item) => {
      const { month, year } = getUtcDateAndTime(item.xValue)
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  }

  return uniqueMonths
}

export const findUniqueMonthsPredictions = ({ filteredData }: { filteredData: PredictionDataExtended[] }): string[] => {
  const uniqueMonths: string[] = []

  filteredData.forEach((item) => {
    const { month, year } = getUtcDateAndTime(item.xValue)
    const monthKey = `${year}-${month.toString().padStart(2, '0')}`

    if (!uniqueMonths.includes(monthKey)) {
      uniqueMonths.push(monthKey)
    }
  })

  return uniqueMonths
}

export const findUniqueMonthsInitializing = (
  filteredData: ChartDataValue[],
  timePeriod: CPITimePeriod,
  data?: ChartData
): string[] => {
  const uniqueMonths: string[] = []

  if (timePeriod === CPITimePeriod.Week) {
    filteredData.forEach((item: any) => {
      const date = new Date(item.xValue)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const monthKey = `${year}-${month.toString().padStart(2, '0')}-${day}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  } else if (timePeriod === CPITimePeriod.Month && data) {
    return filteredData
      .map((n) => dayjs(n.xValue).format('YYYY-MM-DD'))
      .sort((a, b) => (new Date(b).getTime() > new Date(a).getTime() ? -1 : 1))
  } else {
    filteredData.forEach((item: any) => {
      const date = new Date(item.xValue)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  }

  return uniqueMonths.sort((a, b) => (new Date(b).getTime() > new Date(a).getTime() ? -1 : 1))
}

/*
 * Function to filter data for the given number of months
 * */
export const filterData = (months: number, data: any) => {
  const today = new Date()
  const utcStartDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - months, 1))

  return data.values.filter((item: any) => {
    const itemDate = new Date(item.xValue)
    const utcItemDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()))

    return utcItemDate >= utcStartDate
  })
}

export const filterPredictionData = (months: number, data: PredictionChartData): PredictionDataExtended[] => {
  const today = new Date(data.values[data.values.length - 1].xValue)
  const utcStartDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - months, 1))

  return data.values.filter((item) => {
    const itemDate = new Date(item.xValue)
    const utcItemDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()))

    return utcItemDate >= utcStartDate
  })
}

export const getChartWidth = (
  isSmall: boolean,
  isLaptop: boolean,
  isLargeScreen: boolean,
  isExtraLargeScreen: boolean
) => {
  return isSmall ? 700 : isLaptop ? 800 : isLargeScreen ? 920 : isExtraLargeScreen ? 1100 : 1638
}

export const getChartHeight = (isSmallHeight: boolean, isMediumHeight: boolean, isBigHeight: boolean) => {
  return isSmallHeight ? 499 : isMediumHeight ? 500 : isBigHeight ? 567 : 567
}

export const dateStringToUTC = (date: string) => {
  const d = new Date(date)
  const day = d.getUTCDate()
  const month = d.getUTCMonth() + 1
  const year = d.getUTCFullYear()

  return `${year}-${month}-${day}`
}

export const formatTooltipDate = (
  start: string,
  end: string,
  frequency: CPIFrequency,
  timePeriod: CPITimePeriod,
  dataStartsAt: string
) => {
  if (frequency === CPIFrequency.Daily) {
    const res = dateStringToUTC(start)
    return dayjs(res).format('MMMM DD, YYYY')
  }

  if (frequency === CPIFrequency.Weekly) {
    const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(frequency, dataStartsAt, start, end)

    return !rangeToFormatted ? rangeFromFormatted : `${rangeFromFormatted}-${rangeToFormatted}`
  }

  if (frequency === CPIFrequency.Monthly) {
    return dayjs(start).format('MMMM YYYY')
  }

  return ''
}

export const getRangeFromToFormattedInsights = (start: string, end: string) => {
  const utcDateStart = getUtcDateAndTime(start)
  const utcDateEnd = getUtcDateAndTime(end)
  const rangeFrom = utcDateStart.labelFormatDate
  const rangeFromMonth = utcDateStart.month
  const rangeFromYear = utcDateStart.year
  const rangeToMonth = utcDateEnd.month
  const rangeToYear = utcDateEnd.year
  const isSameYear = rangeFromYear === rangeToYear
  const isSameMonth = rangeFromMonth === rangeToMonth
  const rangeFromFormatted = dayjs(rangeFrom).format(`MMM D${!isSameYear ? ', YYYY' : ''}`)
  let rangeToFormatted = dayjs(end).format(`${!isSameMonth ? 'MMM ' : ''}D${isSameYear ? ', YYYY' : ''}`)

  if (rangeFromFormatted.slice(-2) === rangeToFormatted) {
    rangeToFormatted = ''
  }

  return {
    rangeFromFormatted,
    rangeToFormatted
  }
}

export const formatTooltipDateInsights = (start: string, end: string) => {
  const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormattedInsights(start, end)

  return !rangeToFormatted ? rangeFromFormatted : `${rangeFromFormatted}-${rangeToFormatted}`
}

export const getPathAndPositionsOC = ({
  uniqueMonths,
  stripeWidth,
  height,
  filteredData,
  groupedByWeeks,
  selectedTimePeriod,
  selectedFrequency,
  yAxisDomain,
  isReady,
  chartHeightStart
}: {
  uniqueMonths: string[]
  stripeWidth: number
  height: number
  filteredData: ChartDataValue[]
  groupedByWeeks: ChartDataExtended | null
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  yAxisDomain: [number, number]
  isReady: boolean
  chartHeightStart?: number
}): [string, Array<D3Position>, D3Position] => {
  let pathStr = ``
  const positions: D3Position[] = []
  let hasDummyData = false
  let pathStartPoint = { x: 0, y: 0 }
  let weeklyGroupedValues: ChartDataValueExtended[] = []
  if (groupedByWeeks) {
    weeklyGroupedValues = groupedByWeeks.values
  }
  let filteredDataStringified = [...filteredData]
  if (typeof filteredDataStringified[0].xValue !== 'string') {
    filteredDataStringified = filteredDataStringified.map((n) => {
      return {
        ...n,
        xValue: dayjs(n.xValue).format(dateFormat.customLabel)
      }
    })
  }

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    let groupedMonths: ChartDataValue[] = []
    if (selectedTimePeriod === CPITimePeriod.Week) {
      const currentDate = getUtcDateAndTime(uniqueMonths[i])
      groupedMonths = filterDateByDay(filteredDataStringified, currentDate.utcFormattedDate)
    } else if (selectedTimePeriod === CPITimePeriod.Month && groupedByWeeks) {
      if (selectedFrequency === CPIFrequency.Daily) {
        let preparedData = addWeekBreakpoints(filteredDataStringified)
        if (typeof preparedData[0].xValue !== 'string') {
          preparedData = preparedData.map((n) => {
            return {
              ...n,
              xValue: dayjs(n.xValue).format(dateFormat.customLabel)
            }
          })
        }

        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))

        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(filteredDataStringified, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(filteredDataStringified, uniqueMonths[i])
        }
      } else {
        const preparedData = addWeekBreakpoints(weeklyGroupedValues)
        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))
        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(weeklyGroupedValues, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(weeklyGroupedValues, uniqueMonths[i])
        }
      }
    } else {
      const month = dayjs(uniqueMonths[i]).format('YYYY-MM')
      groupedMonths = filterDateByMonth(
        selectedFrequency === CPIFrequency.Weekly ? weeklyGroupedValues : filteredDataStringified,
        month
      )
    }

    if (!groupedMonths.length) {
      positions.push({
        x: 0,
        y: 0,
        value: 0
      })
      pathStr += `M0 ${height}`
      continue
    }

    const stripeMiddle = !isReady && i === 0 ? 0 : stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d: ChartDataValue) => d.xValue))
      .range([stripeWidthStart, stripeWidthEnd])
      .padding(0)
    const yScaleEach = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .nice()
      .range([height, chartHeightStart ?? 0])

    groupedMonths.forEach((each: ChartDataValue, index: number) => {
      let startString = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
      }
      const add = isReady ? stripeMiddle : stripeMiddle * 2
      const isoString = new Date(each.xValue).toISOString()
      const d = isoString.slice(0, 10) || dayjs(each.xValue).format('YYYY-MM-DD')
      const position = {
        x: (xScaleEach(each.xValue) || 0) + add,
        y: yScaleEach(each.yValue),
        value: each.yValue,
        // date: each.xValue,
        date: dayjs(d).format('YYYY-MM-DD'),
        monthIndex: i,
        hasDummyData
      }
      if (i === 0 && index === 0) {
        startString = 'M'
        pathStartPoint = position
      }
      if (each.dummy) {
        hasDummyData = true
      } else {
        if (hasDummyData) {
          pathStartPoint = position
          startString = 'M'
          hasDummyData = false
        }
        pathStr += `${startString}${position.x} ${position.y}`
      }

      positions.push(position)
      pathStr += `${startString}${position.x} ${position.y}`
    })
  }

  return [pathStr, positions, pathStartPoint]
}

/*
 *  Populate each stripe with data points for that month
 * */
export const getPathAndPositions = ({
  uniqueMonths,
  stripeWidth,
  height,
  filteredData,
  groupedByWeeks,
  selectedTimePeriod,
  selectedFrequency,
  yAxisDomain,
  dataStatus,
  chartHeightStart
}: {
  uniqueMonths: string[]
  stripeWidth: number
  height: number
  filteredData: ChartDataValue[]
  groupedByWeeks: ChartDataExtended | null
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  yAxisDomain: [number, number]
  chartHeightStart?: number
  dataStatus: DataStatus
}): [string, Array<D3Position>] => {
  let pathStr = ``
  const positions: D3Position[] = []
  let weeklyGroupedValues: ChartDataValueExtended[] = []
  if (groupedByWeeks) {
    weeklyGroupedValues = groupedByWeeks.values
  }

  const lastValue = filteredData[filteredData.length - 1].xValue
  const lastValueDay = dayjs(lastValue).get('date')
  const daysInLastValue = dayjs(lastValue).daysInMonth()
  const lastStripeDivider = daysInLastValue / lastValueDay

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    let groupedMonths: ChartDataValue[] = []
    if (dataStatus === DataStatus.Initializing) {
      groupedMonths = filteredData
    } else if (selectedTimePeriod === CPITimePeriod.Week) {
      const currentDate = getUtcDateAndTime(uniqueMonths[i])
      groupedMonths = filterDateByDay(filteredData, currentDate.utcFormattedDate)
    } else if (selectedTimePeriod === CPITimePeriod.Month && groupedByWeeks) {
      if (selectedFrequency === CPIFrequency.Daily) {
        const preparedData = addWeekBreakpoints(filteredData)
        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))

        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(filteredData, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(filteredData, uniqueMonths[i])
        }
      } else {
        const preparedData = addWeekBreakpoints(weeklyGroupedValues)
        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))
        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(weeklyGroupedValues, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(weeklyGroupedValues, uniqueMonths[i])
        }
      }
    } else {
      const month = dayjs(uniqueMonths[i]).format('YYYY-MM')
      groupedMonths = filterDateByMonth(filteredData, month)
    }

    if (!groupedMonths.length) {
      positions.push({
        x: 0,
        y: 0,
        value: 0
      })
      pathStr += `M0 ${height}`
      continue
    }

    const stripeMiddle = dataStatus !== DataStatus.Ready && i === 0 ? 0 : stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d: ChartDataValue) => d.xValue))
      .range([
        stripeWidthStart,
        dataStatus === DataStatus.Ready && i === uniqueMonths.length - 1 && lastValueDay < daysInLastValue
          ? stripeWidthEnd - stripeWidth + stripeWidth / lastStripeDivider
          : stripeWidthEnd
      ])
    const yScaleEach = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .nice()
      .range([height, chartHeightStart ?? 0])

    groupedMonths.forEach((each: ChartDataValue, index: number) => {
      let startString = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
      }
      const add = dataStatus === DataStatus.Ready ? stripeMiddle : stripeMiddle * 2
      const d =
        typeof each.xValue !== 'string'
          ? dayjs(each.xValue).format('YYYY-MM-DD')
          : each.xValue.slice(0, 10) || dayjs(each.xValue).format('YYYY-MM-DD')
      const position = {
        x: (xScaleEach(each.xValue) || 0) + add,
        y: yScaleEach(each.yValue),
        value: each.yValue,
        monthIndex: i,
        date: dayjs(d).format('YYYY-MM-DD')
      }
      positions.push(position)
      pathStr += `${startString}${position.x} ${position.y}`
    })
  }

  const lastPosition = positions[positions.length - 1]
  if (lastPosition.x === 0 && lastPosition.y === 0) {
    positions.splice(-1)

    return [pathStr, positions]
  }
  return [pathStr, positions]
}

export const getPathAndPositionsPredictions = ({
  uniqueMonths,
  stripeWidth,
  height,
  filteredData,
  groupedByWeeks,
  selectedTimePeriod,
  selectedFrequency,
  yAxisDomain,
  dataStatus,
  chartHeightStart,
  predictionCalculation = false
}: {
  uniqueMonths: string[]
  stripeWidth: number
  height: number
  filteredData: ChartDataValue[]
  groupedByWeeks: ChartDataExtended | null
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  yAxisDomain: [number, number]
  dataStatus: DataStatus
  chartHeightStart?: number
  predictionCalculation?: boolean
}): [string, Array<D3Position>, string] => {
  let pathStr = ``
  let pathStrPrediction = ``
  const positions: D3Position[] = []
  let weeklyGroupedValues: ChartDataValueExtended[] = []
  if (groupedByWeeks) {
    weeklyGroupedValues = groupedByWeeks.values
  }
  const lastValue = filteredData[filteredData.length - 1].xValue
  const lastValueDay = dayjs(lastValue).get('date')
  const daysInLastValue = dayjs(lastValue).daysInMonth()
  const lastStripeDivider = daysInLastValue / lastValueDay

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    let groupedMonths: ChartDataValue[] = []
    if (dataStatus === DataStatus.Initializing) {
      groupedMonths = filteredData
    } else if (selectedTimePeriod === CPITimePeriod.Week) {
      const currentDate = getUtcDateAndTime(uniqueMonths[i])
      groupedMonths = filterDateByDay(filteredData, currentDate.utcFormattedDate)
    } else if (selectedTimePeriod === CPITimePeriod.Month && groupedByWeeks) {
      if (selectedFrequency === CPIFrequency.Daily) {
        const preparedData = addWeekBreakpoints(filteredData)
        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))

        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(filteredData, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(filteredData, uniqueMonths[i])
        }
      } else {
        const preparedData = addWeekBreakpoints(weeklyGroupedValues)
        const d = preparedData.find((n) => n.start === dayjs(uniqueMonths[i]).format(dateFormat.customLabel))
        if (d) {
          groupedMonths = filterDateByWeekWithStartEnd(weeklyGroupedValues, (d as any).start, (d as any).end)
        } else {
          groupedMonths = filterDateByWeek(weeklyGroupedValues, uniqueMonths[i])
        }
      }
    } else {
      const month = dayjs(uniqueMonths[i]).format('YYYY-MM')
      groupedMonths = filterDateByMonth(filteredData, month)
    }

    if (!groupedMonths.length) {
      if (!predictionCalculation) {
        positions.push({
          x: 0,
          y: 0,
          value: 0
        })
      }
      pathStr += `M0 ${height}`
      continue
    }

    const stripeMiddle = dataStatus !== DataStatus.Ready && i === 0 ? 0 : stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d: ChartDataValue) => d.xValue))
      .range([
        stripeWidthStart,
        dataStatus === DataStatus.Ready && i === uniqueMonths.length - 1 && lastValueDay < daysInLastValue
          ? stripeWidthEnd - stripeWidth + stripeWidth / lastStripeDivider
          : stripeWidthEnd
      ])
      .padding(0)
    const yScaleEach = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .nice()
      .range([height, chartHeightStart ?? 0])

    groupedMonths.forEach((each: ChartDataValue, index: number) => {
      let startString = 'L'
      let pathStrPredictionStart = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
        pathStrPredictionStart = 'M'
      }
      const add = dataStatus === DataStatus.Ready ? stripeMiddle : stripeMiddle * 2
      const d =
        typeof each.xValue !== 'string'
          ? dayjs(each.xValue).format('YYYY-MM-DD')
          : each.xValue.slice(0, 10) || dayjs(each.xValue).format('YYYY-MM-DD')
      const position = {
        x: (xScaleEach(each.xValue) || 0) + add,
        y: yScaleEach(each.yValue),
        value: each.yValue,
        monthIndex: i,
        date: dayjs(d).format('YYYY-MM-DD'),
        prediction: (each as any).prediction
      }
      positions.push(position)
      pathStr += `${startString}${position.x} ${position.y}`
      if ((each as any).prediction) {
        pathStrPrediction += `${pathStrPredictionStart}${position.x} ${position.y}`
      }
    })
  }

  return [pathStr, positions, pathStrPrediction]
}

export const getTodayTimeUTC = () => {
  const today = new Date()
  const offset = dayjs(today).format('Z')
  const offsetDirection = offset[0] === '-' ? 'negative' : 'positive'
  const offsetValue = parseInt(offset.slice(1, 3), 10)

  if (offsetValue !== 0) {
    if (offsetDirection === 'negative') {
      today.setHours(today.getHours() + offsetValue)
    } else {
      today.setHours(today.getHours() - offsetValue)
    }
  }

  return today.getTime()
}

export const getRangeFromToFormatted = (
  selectedFrequency: CPIFrequency,
  dataStartsAt: string,
  start: string,
  end: string,
  withYear = true
) => {
  const dataStartTime = new Date(dataStartsAt).getTime()
  const currentStartTime = new Date(start).getTime()
  const currentEndTime = new Date(end).getTime()
  const utcDateStart = getUtcDateAndTime(start)
  const utcDateEnd = getUtcDateAndTime(end)
  const rangeFrom = utcDateStart.labelFormatDate
  const rangeTo = utcDateEnd.labelFormatDate
  const rangeFromMonth = utcDateStart.month
  const rangeFromYear = utcDateStart.year
  const rangeToMonth = utcDateEnd.month
  const rangeToYear = utcDateEnd.year
  const isSameYear = rangeFromYear === rangeToYear
  const isSameMonth = rangeFromMonth === rangeToMonth
  let rangeFromFormatted = dayjs(rangeFrom).format(`MMM D${!isSameYear ? ', YYYY' : ''}`)
  let rangeToFormatted = dayjs(rangeTo).format(`${!isSameMonth ? 'MMM ' : ''}D${!isSameYear ? ', YYYY' : ''}`)
  const todayTime = getTodayTimeUTC()
  const endDayTime = new Date(rangeTo).getTime()

  /* Don't include year in format, only months and days */
  if (!withYear) {
    const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'
    rangeFromFormatted = dayjs(rangeFrom).format('MMM D')
    rangeToFormatted = dayjs(rangeTo).format(rangeToFormat)

    if (currentStartTime < dataStartTime && selectedFrequency === CPIFrequency.Daily) {
      rangeFromFormatted = dayjs(dataStartsAt).format('MMM D')
    }

    if (todayTime < endDayTime) {
      rangeToFormatted = dayjs(todayTime).format(rangeToFormat)
    }
    const today = new Date()
    const todayDate = today.getUTCDate()
    const todayMonth = today.getUTCMonth() + 1
    const todayYear = today.getUTCFullYear()
    if (utcDateStart.day === todayDate && todayMonth === utcDateStart.month && todayYear === utcDateStart.year) {
      rangeToFormatted = ''
    }

    return {
      rangeFromFormatted,
      rangeToFormatted
    }
  }

  if (currentStartTime < dataStartTime && selectedFrequency === CPIFrequency.Daily) {
    rangeFromFormatted = dayjs(dataStartsAt).format('MMM D')
  }
  if (currentEndTime > todayTime) {
    rangeToFormatted = dayjs().format(`${!isSameMonth ? 'MMM ' : ''}D${!isSameYear ? ', YYYY' : ''}`)
  }
  if (rangeFromFormatted.slice(-2) === rangeToFormatted) {
    rangeToFormatted = ''
  }

  return {
    rangeFromFormatted,
    rangeToFormatted
  }
}

export const formatXLabel = ({
  date,
  selectedTimePeriod,
  selectedFrequency,
  filteredData,
  dataStartsAt
}: {
  date: string
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  filteredData: ChartDataValue[]
  dataStartsAt: string
}) => {
  if (selectedTimePeriod === CPITimePeriod.Week) {
    return dayjs(date).format('MMM D')
  }
  if (selectedTimePeriod === CPITimePeriod.Month) {
    const d = filteredData.find((n: any) => n.xValue.slice(0, 10) === dayjs(date).format(dateFormat.customLabel))

    if (d) {
      const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
        selectedFrequency,
        dataStartsAt,
        (d as any).start,
        (d as any).end,
        false
      )
      const today = dayjs().format('YYYY-MM-DD')
      if (dayjs((d as any).end) >= dayjs(today)) return rangeFromFormatted

      if (!rangeToFormatted) return rangeFromFormatted
      return `${rangeFromFormatted}-${rangeToFormatted}`
    }

    return dayjs(date).format('MMM DD')
  }
  if (selectedTimePeriod === CPITimePeriod.ThreeMonths || selectedTimePeriod === CPITimePeriod.SixMonths) {
    if (selectedFrequency === CPIFrequency.Weekly) {
      const groupedDays = filterDateByMonth(filteredData, date)

      if (groupedDays.length < 1) return dayjs(date).format('MMM DD')

      const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
        selectedFrequency,
        groupedDays[0].xValue,
        (groupedDays as any)[0].start,
        (groupedDays as any)[groupedDays.length - 1].end
      )

      return `${rangeFromFormatted}-${rangeToFormatted}`
    }

    const currentDate = convertDateToUTCString(new Date(date))

    /*
     * This used to be range from-to, but changed because:
     * ONY-2585 */
    return dayjs(currentDate).format(`MMM 'YY`)
  }
  if (selectedTimePeriod === CPITimePeriod.PastYear) {
    return dayjs(date).format("MMM 'YY")
  }
  if (selectedTimePeriod === CPITimePeriod.Custom) {
    return dayjs(date).format("MMM 'YY")
  }

  return ''
}

/*
 * Find the stripe position based on where the user clicked using mouse event.x position
 * and calculating for offset on any screen size
 * * */
const findStripe = (numberOfStripes: number, stripeWidth: number, offsetLeft: number, x: number) => {
  let stripeNo = 0
  const stripesArr = Array.from(Array(numberOfStripes).keys())

  stripesArr.forEach((stripe) => {
    const stripeStartX = stripe * stripeWidth + offsetLeft
    const stripeEndX = stripeStartX + stripeWidth

    if (x >= stripeStartX && x < stripeEndX) {
      stripeNo = stripe + 1
    }
  })

  return stripeNo
}

/*
 * Receive the end date of date range and return either the received date if its before today,
 * or today if end date is after today
 * */
export const getEndDateOrToday = (suggestedEndDate: string) => {
  const suggestedEndTime = new Date(suggestedEndDate).getTime()
  const today = new Date().getTime()

  return suggestedEndTime > today ? new Date().toISOString() : suggestedEndDate
}

/*
 * If the suggested start date is before the dataset start date, return the dataset start date
 * */
export const getStartDateOrFirstDataDate = (suggestedStartDate: string, dataStartsAt: string) => {
  const suggestedStart = new Date(suggestedStartDate)
  const dataStartDate = new Date(dataStartsAt)

  return suggestedStart < dataStartDate ? dataStartsAt : suggestedStartDate
}

export const getCustomDatesForTimePeriod = (
  x: number,
  offsetLeft: number,
  stripeWidth: number,
  selectedTimePeriod: CPITimePeriod,
  selectedFrequency: CPIFrequency,
  uniqueMonths: string[],
  filteredData: any[],
  dataStartsAt: string
): {
  date: string
  start: string
  end: string
} => {
  let start = ''
  let end = ''
  const numberOfStripes = uniqueMonths.length
  const stripeClicked = findStripe(numberOfStripes, stripeWidth, offsetLeft, x)
  const date = uniqueMonths[stripeClicked - 1]

  if (selectedTimePeriod === CPITimePeriod.Week) {
    start = dayjs(date).format(dateFormat.customLabel)
    end = ''
  }
  if (selectedTimePeriod === CPITimePeriod.Month) {
    const groupedDays = filterDateByWeek(filteredData, date)
    const startDate = getStartDateOrFirstDataDate((groupedDays as any)[0].start, dataStartsAt)
    const endDate = getEndDateOrToday((groupedDays as any)[0].end)
    start = dayjs(startDate).format(dateFormat.customLabel)
    end = dayjs(endDate).format(dateFormat.customLabel)
  }
  if ([CPITimePeriod.ThreeMonths, CPITimePeriod.SixMonths, CPITimePeriod.PastYear].includes(selectedTimePeriod)) {
    const groupedDays = filterDateByMonth(filteredData, date)
    if (selectedFrequency === CPIFrequency.Weekly) {
      const startDate = (groupedDays as any)[0].start
      const endDate = getEndDateOrToday((groupedDays as any)[groupedDays.length - 1].end)
      start = dayjs(startDate).format(dateFormat.customLabel)
      end = dayjs(endDate).format(dateFormat.customLabel)
    } else if (selectedFrequency === CPIFrequency.Monthly) {
      const { year, month } = getUtcDateAndTime(groupedDays[0].xValue)
      const monthFormatted = month < 10 ? `0${month}` : month
      const firstDayOfMonth = '01'
      const lastDayOfMonth = dayjs(groupedDays[0].xValue).daysInMonth()
      const endDayParsed = getUtcDateAndTime(`${year}-${monthFormatted}-${lastDayOfMonth}`).labelFormatDate
      const endDate = getEndDateOrToday(endDayParsed)
      start = `${year}-${monthFormatted}-${firstDayOfMonth}`
      end = getUtcDateAndTime(endDate).labelFormatDate
    } else {
      start = getUtcDateAndTime(groupedDays[0].xValue).labelFormatDate
      end = getUtcDateAndTime(groupedDays[groupedDays.length - 1].xValue).labelFormatDate
    }
  }

  return { date, start, end }
}

export const findDateRangeForDate = (
  date: string,
  timePeriod: CPITimePeriod,
  frequency: CPIFrequency,
  filteredData: ChartDataValue[]
): {
  date: string
  start: string
  end: string
} => {
  if (timePeriod === CPITimePeriod.Week) {
    return {
      date,
      start: dayjs(date).format(dateFormat.customLabel),
      end: ''
    }
  }
  if (timePeriod === CPITimePeriod.Month) {
    const groupedDays = filterDateByWeek(filteredData, date)

    if (frequency === CPIFrequency.Weekly) {
      if (!groupedDays.length) {
        const d = filteredData.find((n: any) => n.start && n.start === date)
        if (d) {
          return {
            date,
            start: dayjs((d as any).start).format(dateFormat.customLabel),
            end: dayjs((d as any).end).format(dateFormat.customLabel)
          }
        }
        return {
          date,
          start: dayjs(date).format(dateFormat.customLabel),
          end: ''
        }
      }
      const rangeFrom = (groupedDays as any)[0].start
      let rangeTo = (groupedDays as any)[0].end

      const today = new Date().getTime()
      const end = new Date(rangeTo).getTime()
      if (today < end) {
        rangeTo = new Date(today)
      }

      return {
        date,
        start: rangeFrom,
        end: rangeTo
      }
    }

    if (groupedDays.length < 2) {
      if (groupedDays.length === 1) {
        return {
          date,
          start: dayjs(groupedDays[0].xValue).format(dateFormat.customLabel),
          end: ''
        }
      }
      return {
        date,
        start: dayjs(date).format(dateFormat.customLabel),
        end: ''
      }
    }

    const rangeFrom = groupedDays[0].xValue
    const rangeTo = groupedDays[groupedDays.length - 1].xValue

    return {
      date,
      start: rangeFrom,
      end: rangeTo
    }
  }
  if (
    timePeriod === CPITimePeriod.ThreeMonths ||
    timePeriod === CPITimePeriod.SixMonths ||
    timePeriod === CPITimePeriod.PastYear
  ) {
    if (frequency === CPIFrequency.Weekly) {
      const groupedDays = filterDateByMonth(filteredData, date.slice(0, 7))

      if (groupedDays.length < 1) {
        return {
          date,
          start: dayjs(date).format(dateFormat.customLabel),
          end: ''
        }
      }

      const filledStripe = groupedDays
        .map((item: any) =>
          fillDatesBetweenRange(new Date(item.start), new Date(item.end)).map((n) =>
            dayjs(n).format(dateFormat.customLabel)
          )
        )
        .flat()

      return {
        date,
        start: filledStripe[0],
        end: filledStripe[filledStripe.length - 1]
      }
    }
    if (frequency === CPIFrequency.Monthly) {
      const groupedDays = filterDateByMonth(
        filteredData.map((n) => ({ ...n, xValue: dayjs(n.xValue).format(dateFormat.customLabel).slice(0, 7) })),
        date.slice(0, 7)
      )
      const firstDayOfMonth = '01'
      const d = getUtcDateAndTime(date)
      const lastDayOfMonth = dayjs(d.isoFormattedDate).daysInMonth()
      const start = new Date(`${d.year}-${d.month}-${firstDayOfMonth}`)
      const end = new Date(`${d.year}-${d.month}-${lastDayOfMonth}`)
      if (groupedDays.length < 1) {
        return {
          date,
          start: dayjs(date).format(dateFormat.customLabel),
          end: ''
        }
      }

      return {
        date,
        start: dayjs(start).format(dateFormat.customLabel),
        end: dayjs(end).format(dateFormat.customLabel)
      }
    }

    const groupedDays = filterDateByMonth(filteredData, date)
    if (groupedDays.length < 1) {
      return {
        date,
        start: dayjs(date).format(dateFormat.customLabel),
        end: ''
      }
    }

    const rangeFrom = groupedDays[0].xValue.slice(0, 10)
    const rangeTo = groupedDays[groupedDays.length - 1].xValue.slice(0, 10)

    return {
      date,
      start: rangeFrom,
      end: rangeTo
    }
  }

  return {
    date,
    start: date,
    end: ''
  }
}

const findOverlappingLabel = (visibleLabels: VisibleLabel[], slaYPosition: number) => {
  let hasLabelOnStart = false
  let activeLabelIndex = 0
  const shortHeight = 98
  const longHeight = 102
  const overlappingLabels: VisibleLabel[] = []
  const slaYPositionStart = slaYPosition - 28
  const slaYPositionEnd = slaYPosition + 28

  visibleLabels.forEach((label, index) => {
    const y = index + 1
    const yPosition = y === 1 ? 101.4 * 3 + 30 : y === 2 ? 101.4 * 2 + 25 : y === 3 ? 101.4 + 20 : 9
    const cardHeight = label.isShortLabel ? shortHeight : longHeight
    const cardWithHeight = yPosition + cardHeight

    if (slaYPosition > yPosition && slaYPosition < cardWithHeight && label.x < 400) {
      activeLabelIndex = index
      hasLabelOnStart = true
      overlappingLabels.push(label)
    } else if (slaYPositionEnd > yPosition && slaYPositionEnd < cardWithHeight && label.x < 400) {
      overlappingLabels.push(label)
      activeLabelIndex = index
    } else if (slaYPositionStart > yPosition && slaYPositionStart < cardWithHeight && label.x < 400) {
      overlappingLabels.push(label)
      activeLabelIndex = index
    }
  })

  if (overlappingLabels.length > 0) {
    const rightMostLabel = Math.max(...overlappingLabels.map((o) => o.x))
    const label = overlappingLabels.find((n) => n.x === rightMostLabel)

    return label as VisibleLabel
  }
  return hasLabelOnStart ? visibleLabels[activeLabelIndex] : false
}

export const getSlaGroupProps = (
  height: number,
  healthBorder: number,
  visibleLabels: VisibleLabel[],
  slaRectWidth: number
) => {
  const slaRectHeight = 56
  let slaMarginLeft = 55

  const overflowValuePadding = 8
  const maxHighValue = height - slaRectHeight / 2
  const maxLowValue = slaRectHeight / 2
  const slaYPositionValue = healthBorder
  const slaYPosition =
    slaYPositionValue >= maxHighValue
      ? maxHighValue - overflowValuePadding
      : slaYPositionValue <= maxLowValue
      ? maxLowValue + overflowValuePadding
      : slaYPositionValue

  const activeLabel = findOverlappingLabel(visibleLabels, slaYPosition)
  if (activeLabel) {
    const { x, isShortLabel } = activeLabel
    slaMarginLeft = x < 150 ? (isShortLabel ? 150 : 280) : x + (isShortLabel ? 94 : 133)
  }

  return {
    slaYPosition,
    slaYPositionValue,
    overflowValuePadding,
    slaMarginLeft,
    slaRectHeight,
    slaRectWidth
  }
}

export const getSingularSectionsName = (sectionsName: string) => {
  let result = ''
  switch (sectionsName) {
    case 'severities':
      result = 'Severity'
      break
    case 'devices':
      result = 'Device'
      break
    case 'security_categories':
      result = 'Security Categories'
      break
    default:
      result = sectionsName
      break
  }
  return result
}

interface DummyObjectBenchmarking {
  date: string
  start: string
  end: string
  less: number
  equal: number
  more: number
  dummy: boolean
}

interface DummyObject {
  xValue: string
  yValue: number
  numerator: number
  denominator: number
  dummy: boolean
}

interface DummyObjectBenchmarking {
  date: string
  less: number
  more: number
  equal: number
  dummy: boolean
}

function generateDatesArray(startDate: Date, endDate: Date): DummyObject[] {
  const datesArray: DummyObject[] = []
  const currentDate: Date = new Date(startDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    datesArray.push({
      xValue: new Date(currentDate).toISOString(),
      yValue: 0,
      numerator: 0,
      denominator: 0,
      dummy: true
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray
}

function generateStringDatesArrayBenchmarking(
  startDate: string | Date,
  endDate: string | Date
): DummyObjectBenchmarking[] {
  const datesArray: DummyObjectBenchmarking[] = []
  const currentDate: Date = new Date(startDate)
  const endDay = new Date(endDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDay) {
    datesArray.push({
      date: new Date(currentDate).toISOString(),
      start: new Date(currentDate).toISOString(),
      end: '',
      less: 0,
      equal: 0,
      more: 0,
      dummy: true
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray.map((n) => {
    return {
      ...n,
      date: dayjs(n.date).format(dateFormat.customLabel),
      start: dayjs(n.start).format(dateFormat.customLabel)
    }
  })
}

export function generateStringDatesArray(startDate: string, endDate: string): DummyObject[] {
  const datesArray: DummyObject[] = []
  const currentDate: Date = new Date(startDate)
  const endDay = new Date(endDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDay) {
    datesArray.push({
      xValue: new Date(currentDate).toISOString(),
      yValue: 0,
      numerator: 0,
      denominator: 0,
      dummy: true
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray
}

export function fillDatesBetweenRangeStrings(startDate: string, end: string): string[] {
  const datesArray: string[] = []
  const currentDate: Date = new Date(startDate)
  const endDate = new Date(end)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate).toISOString())
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray
}

export function fillDatesBetweenRange(startDate: Date, endDate: Date): string[] {
  const datesArray: string[] = []
  const currentDate: Date = new Date(startDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate).toISOString())
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray
}

export const getSelectedPeriodNumber = (timePeriod: CPITimePeriod): string => {
  if (timePeriod === CPITimePeriod.Week) {
    return '4'
  }
  if (timePeriod === CPITimePeriod.PastYear) return '12'

  return timePeriod.replace('M', '')
}

export const fillBlankData = (
  values: ChartDataValue[] | HistoryPerformanceDataExtended[],
  selectedTimePeriod: CPITimePeriod
): ChartDataValue[] | HistoryPerformanceDataExtended[] => {
  const [initialValue, finalValue] = [values[0], values.slice(-1)[0]]
  const date = new Date(initialValue.xValue)
  date.setDate(date.getDate() - 1)
  const subtractedDate = date
  const [initialMonth, finalMonth] = [new Date(subtractedDate).getMonth(), new Date(finalValue.xValue).getMonth()]
  const fMonth = finalMonth === 0 ? 12 : finalMonth
  const selectedMonths = parseInt(getSelectedPeriodNumber(selectedTimePeriod))
  const monthDiff = initialMonth === finalMonth ? selectedMonths : fMonth - initialMonth
  let dummyArray: DummyObject[] = []

  if (selectedMonths >= monthDiff) {
    const fakeMonthsGap = selectedMonths === monthDiff ? selectedMonths - 1 : selectedMonths - monthDiff
    const initDate = new Date(initialValue.xValue)
    if (selectedTimePeriod === CPITimePeriod.Month) {
      const existingStripes = values.length
      const stripesGap = 5 - existingStripes
      if (stripesGap <= 0) return values
      const startValue = values[0].xValue
      let start = new Date(startValue)

      const dummiesArray: any[] = []
      Array.from(Array(stripesGap).keys()).forEach(() => {
        const prevWeekDay = new Date(start.getTime() - 7 * 24 * 60 * 60 * 1000)

        const startOfWeekDate = startOfWeek(prevWeekDay, { weekStartsOn: 1 })
        const startDay = startOfWeekDate.getDate()
        const startMonth = startOfWeekDate.getMonth() + 1
        const startYear = startOfWeekDate.getFullYear()

        const endOfWeekDate = endOfWeek(prevWeekDay, { weekStartsOn: 1 })
        const endYear = prevWeekDay.getFullYear()
        const endDay = endOfWeekDate.getDate()
        const endMonth = endOfWeekDate.getMonth() + 1

        const formattedStart = dayjs(`${startYear}-${startMonth}-${startDay}`).format('YYYY-MM-DD')
        const formattedEnd = dayjs(`${endYear}-${endMonth}-${endDay}`).format('YYYY-MM-DD')
        const result = generateStringDatesArray(formattedStart, formattedEnd)
        const res = {
          ...result[3],
          xValue: dayjs(result[3].xValue).format('YYYY-MM-DD'),
          start: result[0].xValue.slice(0, 10),
          end: result[result.length - 1].xValue.slice(0, 10)
        }
        dummiesArray.push(res)
        start = new Date(prevWeekDay)
      })

      return [...dummiesArray, ...values].sort((a, b) =>
        new Date(b.xValue).getTime() >= new Date(a.xValue).getTime() ? -1 : 1
      )
    } else {
      const dataUntilMonths = initDate.getMonth() - fakeMonthsGap
      const startDate: Date = new Date(initDate.getFullYear(), dataUntilMonths, new Date().getDate())
      const endDate: Date = new Date(subtractedDate)
      dummyArray = generateDatesArray(startDate, endDate)
    }
  }
  ;(values as any) = [...dummyArray, ...values]

  return values
}

export const fillBlankDataBenchmarking = (
  values: HistoryBenchmarkDataValues[],
  selectedTimePeriod: CPITimePeriod
): HistoryBenchmarkDataValues[] => {
  const [initialValue, finalValue] = [values[0], values.slice(-1)[0]]
  const date = new Date(initialValue.date)
  date.setDate(date.getDate() - 1)
  const subtractedDate = date
  const [initialMonth, finalMonth] = [new Date(subtractedDate).getMonth(), new Date(finalValue.date).getMonth()]
  const fMonth = finalMonth === 0 ? 12 : finalMonth
  const selectedMonths = parseInt(getSelectedPeriodNumber(selectedTimePeriod))
  const monthDiff =
    initialMonth === finalMonth
      ? selectedMonths
      : finalMonth < initialMonth
      ? Math.abs(finalMonth + 12 - initialMonth + 12) - 12
      : fMonth - initialMonth
  let dummyArray: DummyObjectBenchmarking[] = []

  if (selectedTimePeriod === CPITimePeriod.Month) {
    const existingStripes = values.length
    const stripesGap = 5 - existingStripes
    if (stripesGap <= 0) return values
    const startValue = values[0].date
    let start = new Date(startValue)

    const dummiesArray: any[] = []
    Array.from(Array(stripesGap).keys()).forEach(() => {
      const prevWeekDay = new Date(start.getTime() - 7 * 24 * 60 * 60 * 1000)

      const startOfWeekDate = startOfWeek(prevWeekDay, { weekStartsOn: 1 })
      const startDay = startOfWeekDate.getDate()
      const startMonth = startOfWeekDate.getMonth() + 1
      const startYear = startOfWeekDate.getFullYear()

      const endOfWeekDate = endOfWeek(prevWeekDay, { weekStartsOn: 1 })
      const endYear = prevWeekDay.getFullYear()
      const endDay = endOfWeekDate.getDate()
      const endMonth = endOfWeekDate.getMonth() + 1

      const formattedStart = dayjs(`${startYear}-${startMonth}-${startDay}`).format('YYYY-MM-DD')
      const formattedEnd = dayjs(`${endYear}-${endMonth}-${endDay}`).format('YYYY-MM-DD')
      const result = generateStringDatesArrayBenchmarking(formattedStart, formattedEnd)
      const res = {
        ...result[3],
        xValue: dayjs(result[3].date).format('YYYY-MM-DD'),
        start: result[0].date.slice(0, 10),
        end: result[result.length - 1].date.slice(0, 10)
      }
      dummiesArray.push(res)
      start = new Date(prevWeekDay)
    })

    return [...dummiesArray, ...values].sort((a, b) =>
      new Date(b.date).getTime() >= new Date(a.date).getTime() ? -1 : 1
    )
  }

  if (selectedMonths >= monthDiff) {
    const fakeMonthsGap = selectedMonths === monthDiff ? selectedMonths - 1 : selectedMonths - monthDiff
    const initDate = new Date(initialValue.date)
    const dataUntilMonths = initDate.getMonth() - fakeMonthsGap
    const startDate: Date = new Date(initDate.getFullYear(), dataUntilMonths, new Date().getDate())
    const endDate: Date = new Date(subtractedDate)
    dummyArray = generateStringDatesArrayBenchmarking(startDate, endDate)
    return [...dummyArray, ...values]
  }

  return values
}

export const sortLabels = (a: Label, b: Label) => {
  const isCustomLabel = a.action === CUSTOM_LABEL_ID || b.action === CUSTOM_LABEL_ID
  const dateA = isCustomLabel && a.data.date ? a.inserted_at : a.created_at
  const dateB = isCustomLabel && b.data.date ? b.inserted_at : b.created_at

  return new Date(dateB).getTime() > new Date(dateA).getTime() ? -1 : 1
}

const getLabelPadding = ({
  selectedTimePeriod,
  numberOfStripes,
  monthIndex,
  uniqueMonths
}: {
  selectedTimePeriod: CPITimePeriod
  numberOfStripes: number
  monthIndex: number
  uniqueMonths: string[]
}) => {
  if (selectedTimePeriod === CPITimePeriod.Month) {
    return numberOfStripes === 7 ? 120 : numberOfStripes === 9 ? 70 : 105
  }
  if (selectedTimePeriod === CPITimePeriod.ThreeMonths) {
    return 129 + 64
  }
  if (selectedTimePeriod === CPITimePeriod.SixMonths) {
    if (monthIndex === uniqueMonths.length - 1) {
      return 80
    }
    return 120
  }
  if (selectedTimePeriod === CPITimePeriod.PastYear) {
    if (monthIndex === uniqueMonths.length - 1) {
      return -20
    }
    if (monthIndex === 0) {
      return 90
    }
    return 60
  }
  return 0
}

export const findVisibleLabelsXPosition = (pinnedLabels: Label[], positions: D3Position[], width: number) => {
  const res = pinnedLabels
    .map((label) => {
      const isCustomLabel = label.action === CUSTOM_LABEL_ID
      const description = isCustomLabel ? label.data.description : label.description
      const date = getUtcDateAndTime(isCustomLabel ? (label.data.date as string) : label.inserted_at).labelFormatDate
      const formattedDateWeekly = dayjs(date).format(dateFormat.customLabel)
      const foundPosition = positions.find((n) => n.date && n.date === formattedDateWeekly)

      let pos = foundPosition ? foundPosition.x : -1
      if (pos > width - 129) {
        pos = pos - 54
      }

      return {
        ...label,
        x: pos,
        isShortLabel: description.length <= 25
      }
    })
    .filter((n) => n.x >= 0)
  const result = res.length > 4 ? res.slice(-4) : res

  return result.sort(sortLabels)
}

export const findVisibleLabelsXPositionMonthly = (
  pinnedLabels: Label[],
  width: number,
  uniqueMonths: string[],
  selectedTimePeriod: CPITimePeriod
) => {
  const res = pinnedLabels
    .map((label) => {
      const stripeWidth = width / uniqueMonths.length
      const numberOfStripes = width / stripeWidth
      const isCustomLabel = label.action === CUSTOM_LABEL_ID
      const description = isCustomLabel ? label.data.description : label.description
      const date = getUtcDateAndTime(isCustomLabel ? (label.data.date as string) : label.inserted_at).labelFormatDate
      const formattedDateMonthly = dayjs(date).format('YYYY-MM')
      const formattedDateWeekly = dayjs(date).format(dateFormat.customLabel)
      let monthIndex = uniqueMonths.indexOf(formattedDateMonthly)

      if (selectedTimePeriod === CPITimePeriod.Month) {
        const mappedWeeks = uniqueMonths.map((n, i) => {
          const d = new Date(uniqueMonths[i + 1])
          d.setDate(d.getDate() - 1)
          const lastDate = i + 1 >= uniqueMonths.length ? new Date() : new Date(d)

          return fillDatesBetweenRange(new Date(n), lastDate)
        })
        mappedWeeks.forEach((week, weekIndex) => {
          week.forEach((c) => {
            const formattedMappedDate = dayjs(c).format(dateFormat.customLabel)
            if (formattedDateWeekly === formattedMappedDate) {
              monthIndex = weekIndex
            }
          })
        })
      }

      const padding = getLabelPadding({
        selectedTimePeriod,
        numberOfStripes,
        monthIndex,
        uniqueMonths
      })

      return {
        ...label,
        x: (width / uniqueMonths.length) * monthIndex + padding,
        isShortLabel: description.length <= 25
      }
    })
    .filter((n) => n.x >= 0)
  const result = res.length > 4 ? res.slice(-4) : res

  return result.sort(sortLabels)
}

export const getLabelsDateRange = ({
  selectedTimePeriod,
  selectedFrequency
}: {
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
}) => {
  const currentDate = dayjs()
  let start: any = ''
  let end: any = ''

  const range = {
    minDate: '',
    maxDate: ''
  }

  if (selectedTimePeriod === CPITimePeriod.Week) {
    end = currentDate
    start = end.subtract(7, 'day')
  }
  if (selectedTimePeriod === CPITimePeriod.Month) {
    end = selectedFrequency === 'monthly' ? currentDate.startOf('month') : currentDate
    start = currentDate.subtract(1, 'month').startOf('month')
  }
  if (selectedTimePeriod === CPITimePeriod.ThreeMonths) {
    end = selectedFrequency === 'monthly' ? currentDate.endOf('week') : currentDate
    start = selectedFrequency === 'monthly' ? end.subtract(3, 'month').startOf('month') : end.subtract(3, 'month')
  }
  if (selectedTimePeriod === CPITimePeriod.SixMonths) {
    end = selectedFrequency === 'monthly' ? currentDate.endOf('week') : currentDate
    start = selectedFrequency === 'monthly' ? end.subtract(6, 'month').startOf('month') : end.subtract(6, 'month')
  }
  if (selectedTimePeriod === CPITimePeriod.PastYear) {
    end = currentDate.endOf('week')
    start =
      selectedFrequency === 'monthly'
        ? end.subtract(1, 'day').subtract(12, 'month').startOf('month')
        : end.subtract(1, 'day').subtract(12, 'month')
  }

  range.minDate = dayjs(start).format('YYYY-MM-DD')
  range.maxDate = dayjs(end).format('YYYY-MM-DD')

  return range
}

export const formatCustomTimePickerValue = (dateFrom: string, dateTo: string) => {
  const yearFrom = dateFrom.slice(0, 4)
  const yearTo = dateTo.slice(0, 4)
  let fromFormatted = dayjs(dateFrom).format('MMM')
  if (yearFrom !== yearTo) {
    fromFormatted = dayjs(dateFrom).format("MMM 'YY")
  }
  const toFormatted = dayjs(dateTo).format("MMM 'YY")

  return `${fromFormatted}-${toFormatted}`
}

export function createPerformanceScoreData(
  cpiDisplayName: string,
  date: string,
  value: number,
  cpiName: string,
  cpiTitle: string,
  section: string,
  dataSources: PerformanceScoreCategoryDetailsDataSources[],
  weight: number,
  sla: number,
  recentFinding: string,
  score: number,
  unit: string,
  status: DataStatus
): PerformanceScoreCpi {
  return {
    cpiDisplayName,
    date,
    value,
    cpiName,
    cpiTitle,
    section,
    dataSources,
    weight,
    sla,
    recentFinding,
    score,
    unit,
    status
  }
}
