import { JiraUser } from 'models'

export const emptyFormikField = {
  apiIdField: '',
  apiName: '',
  apiNameField: '',
  displayName: '',
  id: '',
  inputType: '',
  jqlField: '',
  listType: ''
}

export const emptyDataFilter = {
  apiName: '',
  field: '',
  operator: '',
  id: '',
  values: []
}

export const emptyUser: JiraUser = {
  accountId: '',
  accountType: '',
  active: false,
  avatarUrls: {
    '48x48': '',
    '24x24': '',
    '16x16': '',
    '32x32': ''
  },
  displayName: '',
  emailAddress: '',
  locale: '',
  self: '',
  timeZone: ''
}

export const emptyDataMappings = {
  closed_status: [],
  critical_severity: [],
  high_severity: [],
  low_severity: [],
  medium_severity: []
}
