import React, { FC, ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, IconButton, SelectChangeEvent, Tab, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import moment from 'moment'
import axios from 'lib/axios'

/* Utils */
import { useAppDispatch } from 'store/hooks'
import {
  CPILibraryConfigurationIntegration,
  CPILibraryItem,
  IntegrationInstance,
  IntegrationStatusMessage,
  ManifestIntegration
} from 'models'
import { ModalType, openModal } from 'store/modals/modalSlice'
import {
  defaultIntegrationsCategories,
  integrationsInstancesOptions,
  integrationsSortOptions
} from './integrations.constants'

/* Components */
import {
  DetailsOpenContainer,
  InstanceDetailsContainer,
  IntegrationsContainer,
  IntegrationsHeaderWrapper,
  IntegrationsTabs,
  IntegrationsTabsWrapper,
  SelectorsWrapper
} from './integrations.styles'
import { ClearFilters, LibraryTabPanel } from '../../library/library.styles'
import IntegrationsSelectField from './components/form-components/IntegrationsSelectField'
import { ErrorIcon, IntegrationsCloseIcon, IntegrationsDeleteIcon, IntegrationsEditIcon } from '../../../components/svg'
import IntegrationsList from './components/IntegrationsList'
import InstancesList from './components/InstancesList'
import IntegrationsSearch from './components/form-components/IntegrationsSearch'
import EmptyState from './components/EmptyState'
import AssetAnalyzer from '../../../components/svg/AssetAnalyzer'
import {
  CPIChip,
  DeleteInstanceCPICardWrapper as CPIChipWrapper
} from '../../../components/modal/variants/delete-integration-instance/deleteIntegrationInstance.styles'
import HeaderComponent from '../../../components/header/HeaderComponent'
import ToolbarComponent from '../../../components/header/ToolbarComponent'
import { breadcrumbs } from '../../../../lib/utils'
import ClearIcon from '../../../components/svg/ClearIcon'
import ContentWrapper from '../../../components/header/ContentWrapper'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  exists: number
}

const Integrations: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [initialLoad, setInitialLoad] = useState(true)
  const [supportedIntegrations, setSupportedIntegrations] = useState<ManifestIntegration[]>([])
  const [configuredIntegrations, setConfiguredIntegrations] = useState<IntegrationInstance[]>([])
  const [instances, setInstances] = useState<IntegrationInstance[]>([])
  const [tabValue, setTabValue] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [category, setCategory] = useState<string[]>([''])
  const [instanceFilter, setInstanceFilter] = useState<string[]>([''])
  const [sortBy, setSortBy] = useState(integrationsSortOptions[0])
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [selectedInstance, setSelectedInstance] = useState<IntegrationInstance | null>(null)
  const [affectedCpis, setAffectedCpis] = useState<CPILibraryItem[][]>([])
  const [affectedCpisLoading, setAffectedCpisLoading] = useState(true)
  const [cpis, setCpis] = useState<CPILibraryItem[]>([])
  const [categoryOptions, setCategoryOptions] = useState<string[]>(defaultIntegrationsCategories)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [key, setKey] = useState('key1')

  const fetchCpis = async () => {
    try {
      const res = await axios.get('/api/v3/cpis')

      if (res.status === 200) {
        const {
          data: {
            data: { cpis }
          }
        } = res
        setCpis(cpis)

        return cpis
      }
    } catch (err) {
      console.error(err)
    }

    return []
  }

  const fetchSupported = async () => {
    try {
      const res = await axios.get('/api/v3/integrations/supported?sort=desc')

      if (res.status === 200) {
        return res.data.integrations
      }
    } catch (err) {
      console.error(err)
    }

    return []
  }

  const fetchConfigured = async () => {
    try {
      const res = await axios.get<{ instances: IntegrationInstance[] }>(
        '/api/v3/integrations/configured?sort=desc&loadStatus=true'
      )

      if (res.status === 200) {
        return res.data.instances
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }

    return []
  }

  const fetchInstances = async () => {
    setLoading(true)
    setPageLoading(true)

    try {
      const acaSupportedIntegrationNames: string[] = []

      const supported = await fetchSupported()
      const integrationCategories: string[] = []
      supported.forEach((integration: IntegrationInstance) => {
        const integrationsCategories = integration.categories

        if (integrationsCategories && integrationsCategories.length > 0) {
          integrationsCategories.forEach((integrationsCategory: string) => {
            if (!integrationCategories.includes(integrationsCategory)) {
              integrationCategories.push(integrationsCategory)
            }
          })
        }
      })
      setCategoryOptions(integrationCategories)
      setSupportedIntegrations(supported)

      const filteredIntegrations: ManifestIntegration[] = supported.filter(
        (n: ManifestIntegration) => n.is_supports_aca
      )
      filteredIntegrations.forEach((integration) => acaSupportedIntegrationNames.push(integration.name))

      const configured = await fetchConfigured()
      setConfiguredIntegrations(configured)
      let acaInstances: IntegrationInstance[] = configured
      if (acaSupportedIntegrationNames.length > 0) {
        acaInstances = acaInstances.filter((n) => acaSupportedIntegrationNames.includes(n.integration_name))
      }
      setInstances(acaInstances)

      const state = location.state
      if (state && (state as any).instanceId) {
        const instanceId = (state as any).instanceId
        if (instanceId) {
          const instance = configured.find((n) => n.id === instanceId)
          if (instance) {
            setTabValue(1)
            setSelectedInstance(instance)
            setDetailsOpen(true)
            setTimeout(() => {
              navigate(location.pathname, {})
            }, 0)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      setPageLoading(false)
    }
  }

  useEffect(() => {
    if (initialLoad) {
      fetchInstances().finally(() => setInitialLoad(false))
    }
  }, [initialLoad])

  const handleSearch = async (event: SyntheticEvent, value: string) => {
    const sortByFormatted = sortBy === integrationsSortOptions[0] ? 'desc' : 'asc'
    const categories = category.filter((c) => c !== '')
    const numOfInstances =
      instanceFilter[0] === ''
        ? ''
        : instanceFilter[0] === integrationsInstancesOptions[3]
        ? '>'
        : String(integrationsInstancesOptions.indexOf(instanceFilter[0] as string))

    let baseUrl = `/api/v3/integrations/supported?search=${value}&instances=${numOfInstances}&sort=${sortByFormatted}&categories=[${categories.map(
      (n) => `"${n}"`
    )}]`
    if (tabValue === 1) {
      baseUrl = `/api/v3/integrations/configured?search=${value}&sort=${sortByFormatted}&categories=[${categories.map(
        (n) => `"${n}"`
      )}]`
    }

    const res = await axios.get(baseUrl)

    if (res.status === 200) {
      const { integrations, instances } = res.data

      if (tabValue === 0) {
        setSupportedIntegrations(integrations)
      }
      if (tabValue === 1) {
        setConfiguredIntegrations(instances)
      }
    }
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [category, instanceFilter, tabValue, sortBy])

  const handleChangeSort = async (event: SelectChangeEvent<typeof sortBy>) => {
    const { value } = event.target
    setSortBy(value)
    const categories = category.filter((c) => c !== '')
    const sortByFormatted = value === integrationsSortOptions[0] ? 'desc' : 'asc'
    const numOfInstances =
      instanceFilter[0] === ''
        ? ''
        : instanceFilter[0] === integrationsInstancesOptions[3]
        ? '>'
        : String(integrationsInstancesOptions.indexOf(instanceFilter[0] as string))

    let baseUrl = '/api/v3/integrations/supported'
    if (tabValue === 1) {
      baseUrl = '/api/v3/integrations/configured'
    }

    const url = `${baseUrl}?search=${searchValue}&instances=${numOfInstances}&sort=${sortByFormatted}&categories=[${categories.map(
      (n) => `"${n}"`
    )}]`

    const res = await axios.get(url)

    if (res.status === 200) {
      const { integrations, instances } = res.data

      if (tabValue === 0) {
        setSupportedIntegrations(integrations)
      }
      if (tabValue === 1) {
        setConfiguredIntegrations(instances)
      }
    }
  }

  const handleChangeCategory = async (event: SelectChangeEvent<typeof category>) => {
    setLoading(true)
    const {
      target: { value }
    } = event
    const categories: string[] = typeof value === 'string' ? value.split(',') : value
    setCategory(categories)
    const filterable = categories.filter((c) => c !== '')
    const sortByFormatted = sortBy === integrationsSortOptions[0] ? 'desc' : 'asc'
    const numOfInstances =
      instanceFilter[0] === ''
        ? ''
        : instanceFilter[0] === integrationsInstancesOptions[3]
        ? '>'
        : String(integrationsInstancesOptions.indexOf(instanceFilter[0] as string))

    try {
      let baseUrl = '/api/v3/integrations/supported'
      if (tabValue === 1) {
        baseUrl = '/api/v3/integrations/configured'
      }

      if (!filterable.length) {
        const res = await axios.get(
          `${baseUrl}?search=${searchValue}&instances=${numOfInstances}&sort=${sortByFormatted}`
        )

        if (res.status === 200) {
          if (tabValue === 0) {
            setSupportedIntegrations(res.data.integrations)
          } else {
            setConfiguredIntegrations(res.data.instances)
          }
        }

        return
      }

      const url = `${baseUrl}?search=${searchValue}&instances=${numOfInstances}&sort=${sortByFormatted}&categories=[${filterable.map(
        (n) => `"${n}"`
      )}]`

      const res = await axios.get(url)

      if (res.status === 200) {
        if (tabValue === 0) {
          setSupportedIntegrations(res.data.integrations)
        } else {
          setConfiguredIntegrations(res.data.instances)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeInstances = async (event: SelectChangeEvent<typeof instanceFilter>) => {
    const {
      target: { value }
    } = event
    const instanceFilter: string[] = typeof value === 'string' ? value.split(',') : value
    setInstanceFilter(instanceFilter)
    const sortByFormatted = sortBy === integrationsSortOptions[0] ? 'desc' : 'asc'
    const categories = category.filter((n) => n !== '')
    const numOfInstances =
      value === integrationsInstancesOptions[3] ? '>' : String(integrationsInstancesOptions.indexOf(value as string))

    let baseUrl = '/api/v3/integrations/supported'
    if (tabValue === 1) {
      baseUrl = '/api/v3/integrations/configured'
    }
    const url = `${baseUrl}?search=${searchValue}&instances=${numOfInstances}&sort=${sortByFormatted}&categories=[${categories.map(
      (n) => `"${n}"`
    )}]`

    const res = await axios.get(url)

    if (res.status === 200) {
      const { integrations } = res.data

      setSupportedIntegrations(integrations)
    }
  }

  const handleClearFilters = async () => {
    setSearchValue('')
    setKey(new Date().toISOString())
    setCategory([''])
    setInstanceFilter([''])
    setSortBy(integrationsSortOptions[0])

    let baseUrl = '/api/v3/integrations/supported?sort=desc'
    if (tabValue === 1) {
      baseUrl = '/api/v3/integrations/configured?sort=desc'
      const res = await axios.get(baseUrl)

      if (res.status === 200) {
        const { instances } = res.data

        setConfiguredIntegrations(instances)
      }
    } else {
      const res = await axios.get(baseUrl)

      if (res.status === 200) {
        const { integrations } = res.data

        setSupportedIntegrations(integrations)
      }
    }
  }

  const handleOpenDetails = () => {
    setDetailsOpen(!detailsOpen)
  }

  const handleTabChange = async (event: SyntheticEvent, newValue: number) => {
    setLoading(true)
    const sortByFormatted = sortBy === integrationsSortOptions[0] ? 'desc' : 'asc'
    const categories = category.filter((n) => n !== '')
    const numOfInstances =
      instanceFilter[0] === ''
        ? ''
        : instanceFilter[0] === integrationsInstancesOptions[3]
        ? '>'
        : String(integrationsInstancesOptions.indexOf(instanceFilter[0] as string))

    if (newValue === 0) {
      setDetailsOpen(false)
      setSelectedInstance(null)
    }
    setTabValue(newValue)

    let url = `/api/v3/integrations/supported?search=${searchValue}&instances=${numOfInstances}&sort=${sortByFormatted}&categories=[${categories.map(
      (n) => `"${n}"`
    )}]`
    if (newValue === 1) {
      url = `/api/v3/integrations/configured?loadStatus=true&search=${searchValue}&sort=${sortByFormatted}&categories=[${categories.map(
        (n) => `"${n}"`
      )}]`
    }

    try {
      const res = await axios.get(url)

      if (res.status === 200) {
        if (newValue === 0) {
          setSupportedIntegrations(res.data.integrations)
        } else {
          setConfiguredIntegrations(res.data.instances)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const deleteInstanceCallback = async () => {
    try {
      const configured = await fetchConfigured()
      setConfiguredIntegrations(configured)
      const state = location.state
      if (state && (state as any).instanceId) {
        const instanceId = (state as any).instanceId
        if (instanceId) {
          const instance = configured.find((n) => n.id === instanceId)
          if (instance) {
            setTabValue(1)
            setSelectedInstance(instance)
            setDetailsOpen(true)
            setTimeout(() => {
              navigate(location.pathname, {})
            }, 0)
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeleteInstance = () => {
    if (selectedInstance) {
      dispatch(
        openModal({
          type: ModalType.deleteIntegrationInstance,
          props: {
            isAnalyzer: !!instances.find((item) => item.integration_name === selectedInstance.integration_name),
            integrationInstance: selectedInstance,
            callback: deleteInstanceCallback,
            closeCallback: () => {
              setLoading(true)
              setDetailsOpen(false)
            }
          }
        })
      )
    }
  }

  const getDescription = () => {
    if (selectedInstance?.description) {
      return selectedInstance.description
    }

    const selectedIntegrationName = selectedInstance?.integration_name
    if (selectedIntegrationName) {
      const integration = supportedIntegrations.find((n) => n.name === selectedIntegrationName)
      if (integration) {
        return integration.description
      }
    }

    return ''
  }

  const handleCloseDetails = () => {
    setDetailsOpen(false)
    setSelectedInstance(null)
  }

  const areFiltersClearable = () => {
    return (
      category.length === 1 &&
      category[0] === '' &&
      instanceFilter.length === 1 &&
      instanceFilter[0] === '' &&
      !searchValue.length
    )
  }

  const findAffectedCpis = async (cpis: CPILibraryItem[], instanceId: string) => {
    try {
      const affectedCpis: CPILibraryItem[] = []

      cpis.forEach((n) => {
        if (n.configuration) {
          const hasIntegration = n.configuration.integrations.find(
            (c: CPILibraryConfigurationIntegration) => c.id === instanceId
          )
          if (hasIntegration) {
            affectedCpis.push(n)
          }
        }
      })

      return affectedCpis
    } catch (e) {
      console.error(e)
    } finally {
      setAffectedCpisLoading(false)
    }
  }

  const getAllAffectedCpis = async () => {
    try {
      const cpis = await fetchCpis()
      const filteredCpis = cpis.filter((n: CPILibraryItem) => !!n.configuration)

      const promises: Promise<CPILibraryItem[]>[] = configuredIntegrations.map(async (n) => {
        const resp = await findAffectedCpis(filteredCpis, n.id)
        if (resp) {
          return resp
        }
        return []
      })

      const resolved: any = await Promise.all(promises)
      setAffectedCpis(resolved)
    } catch (err) {
      console.error(err)
    } finally {
      setAffectedCpisLoading(false)
    }
  }

  useEffect(() => {
    if (configuredIntegrations.length > 0 && affectedCpisLoading) {
      getAllAffectedCpis().catch((e) => e)
    }
  }, [configuredIntegrations, affectedCpisLoading])

  const renderAffectedCpis = () => {
    if (!affectedCpis || !selectedInstance || !cpis.length) return null

    const withConfig: CPILibraryItem[] = cpis.filter((n: CPILibraryItem) => !!n.configuration)
    const theseCpis: CPILibraryItem[] = []

    withConfig.forEach((n) => {
      if (n.configuration) {
        const hasIntegration = n.configuration.integrations.find(
          (c: CPILibraryConfigurationIntegration) => c.id === selectedInstance.id
        )
        if (hasIntegration) {
          theseCpis.push(n)
        }
      }
    })
    const findItem = instances.find((item) => item.integration_name === selectedInstance.integration_name)

    if (!theseCpis.length && !findItem) return null
    return (
      <Box className="right-side-box">
        <Typography className="box-title">CPIs</Typography>
        {theseCpis?.length ? (
          <Typography className="box-sub-title">Elements configured with this instance:</Typography>
        ) : null}

        {findItem ? (
          <Box className="analyzer-block">
            <AssetAnalyzer />
            <Typography className="title">Asset Coverage Analyzer</Typography>
          </Box>
        ) : (
          ''
        )}

        <CPIChipWrapper noBg={true}>
          {theseCpis.map((n, i) => (
            <CPIChip key={i} justifyCenter={false}>
              <Typography className="cpi">{n.display_name}</Typography>

              <Typography className="name">{n.title}</Typography>
            </CPIChip>
          ))}
        </CPIChipWrapper>
      </Box>
    )
  }

  return (
    <IntegrationsContainer>
      <HeaderComponent breadcrumbs={breadcrumbs.integrations} />

      <ToolbarComponent>
        {detailsOpen ? (
          <IntegrationsHeaderWrapper>
            <Box className="header-inner">
              <Box>
                <SelectorsWrapper>
                  <Box className="filters-wrapper">
                    <IntegrationsSearch
                      key={key}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      onInputChange={debouncedHandleSearch}
                      placeholder="Search Integrations"
                    />

                    <IntegrationsSelectField
                      label=""
                      options={categoryOptions}
                      placeholder="Category"
                      value={category}
                      multiple
                      handleChange={handleChangeCategory}
                    />
                    {tabValue === 0 && (
                      <IntegrationsSelectField
                        label=""
                        options={integrationsInstancesOptions}
                        placeholder="Instances"
                        value={instanceFilter}
                        handleChange={handleChangeInstances}
                      />
                    )}
                    <ClearFilters onClick={handleClearFilters} disabled={areFiltersClearable()}>
                      Clear Filters
                    </ClearFilters>
                  </Box>
                  <Box className="sort-wrapper">
                    <Typography className="sort-description">Sort:</Typography>
                    <IntegrationsSelectField
                      label=""
                      options={integrationsSortOptions}
                      placeholder="Sort"
                      value={sortBy}
                      handleChange={handleChangeSort}
                    />
                  </Box>
                </SelectorsWrapper>
              </Box>

              <IntegrationsTabsWrapper>
                <IntegrationsTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  disabled={loading}
                  aria-label="integrations tabs"
                  TabIndicatorProps={{
                    style: { display: 'none' }
                  }}
                >
                  <Tab label="Integrations" {...a11yProps(0)} />
                  <Tab label="Instances" {...a11yProps(1)} />
                </IntegrationsTabs>
              </IntegrationsTabsWrapper>
            </Box>
          </IntegrationsHeaderWrapper>
        ) : (
          <IntegrationsHeaderWrapper>
            <Box className="header-inner">
              <>
                <SelectorsWrapper>
                  <Box className="filters-wrapper">
                    <IntegrationsSearch
                      key={key}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      onInputChange={debouncedHandleSearch}
                      placeholder="Search..."
                    />

                    <Box className="filters-block">
                      <IntegrationsSelectField
                        placeholder="None"
                        label="Category"
                        options={categoryOptions}
                        value={category}
                        multiple
                        handleChange={handleChangeCategory}
                      />
                      {tabValue === 0 && (
                        <IntegrationsSelectField
                          placeholder="None"
                          label="Instances"
                          options={integrationsInstancesOptions}
                          value={instanceFilter}
                          handleChange={handleChangeInstances}
                        />
                      )}
                      <ClearFilters onClick={handleClearFilters} disabled={areFiltersClearable()}>
                        <ClearIcon />
                        Clear
                      </ClearFilters>
                    </Box>
                  </Box>
                  <Box className="sort-wrapper">
                    <IntegrationsSelectField
                      isSort={true}
                      noBorder={true}
                      label=""
                      options={integrationsSortOptions}
                      placeholder="Sort"
                      value={sortBy}
                      handleChange={handleChangeSort}
                    />
                    <Typography className="results-text">
                      {tabValue === 0 ? supportedIntegrations.length : configuredIntegrations.length} result
                    </Typography>
                  </Box>
                </SelectorsWrapper>
              </>
              <IntegrationsTabsWrapper>
                <IntegrationsTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  disabled={loading}
                  aria-label="library tabs"
                  TabIndicatorProps={{
                    style: { display: 'none' }
                  }}
                >
                  <Tab label="Integrations" {...a11yProps(0)} />
                  <Tab label="Instances" {...a11yProps(1)} />
                </IntegrationsTabs>
              </IntegrationsTabsWrapper>
            </Box>
          </IntegrationsHeaderWrapper>
        )}
      </ToolbarComponent>

      {detailsOpen ? (
        <DetailsOpenContainer>
          <Box sx={{ height: `calc(100vh - 323px)` }} className="left-side">
            {/* Integrations Tab */}
            <TabPanel value={tabValue} index={0} exists={supportedIntegrations.length}>
              {pageLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress size="40px" color="primary" />
                </Box>
              ) : !pageLoading && !supportedIntegrations.length ? (
                <EmptyState clear={handleClearFilters} />
              ) : (
                <IntegrationsList
                  instances={configuredIntegrations}
                  integrations={supportedIntegrations}
                  resultsLoading={loading}
                  handleOpenDetails={handleOpenDetails}
                  detailsOpen={detailsOpen}
                  setTabValue={setTabValue}
                  setSelectedInstance={setSelectedInstance}
                />
              )}
            </TabPanel>
            {/* Instances Tab */}
            <TabPanel value={tabValue} index={1} exists={configuredIntegrations.length}>
              {loading ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size="40px" color="primary" />
                </Box>
              ) : configuredIntegrations.length ? (
                <InstancesList
                  instances={configuredIntegrations}
                  affectedCpis={affectedCpis}
                  loading={affectedCpisLoading}
                  resultsLoading={loading}
                  handleOpenDetails={handleOpenDetails}
                  detailsOpen={detailsOpen}
                  selectedInstance={selectedInstance}
                  setSelectedInstance={setSelectedInstance}
                />
              ) : (
                <EmptyState clear={handleClearFilters} />
              )}
            </TabPanel>
          </Box>
          {selectedInstance && (
            <Box id="selected-instance-wrapper">
              <InstanceDetailsContainer>
                <Box className="right-side__title">
                  <Box className="right-side__title-wrapper">
                    <Box className="header-container">
                      <Box className="header-main">
                        <Typography className="title">{selectedInstance.name}</Typography>
                        <IconButton
                          className="icon-button"
                          onClick={() =>
                            navigate(
                              `/settings/integrations/${selectedInstance?.integration_name}/${selectedInstance.id}/edit`
                            )
                          }
                        >
                          <IntegrationsEditIcon />
                        </IconButton>
                        <IconButton className="icon-button" onClick={handleDeleteInstance}>
                          <IntegrationsDeleteIcon />
                        </IconButton>
                        <IconButton className="icon-button-close" onClick={handleCloseDetails}>
                          <IntegrationsCloseIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    {selectedInstance &&
                      selectedInstance.syncStatus &&
                      selectedInstance.syncStatus.status === IntegrationStatusMessage.failed && (
                        <Box className="tooltip-wrapper">
                          <Box className="tooltip-icon">
                            <ErrorIcon />
                          </Box>
                          <Box className="text-container">
                            <Typography className="tooltip-title">{selectedInstance.name}</Typography>
                            <Box className="description-container">
                              <Typography className="tooltip-text">
                                {/*       {selectedInstance.syncStatus.errorMessage} */}
                                The data synchronization for the data source has encountered a failure. Potential root
                                causes may include:
                                <ul>
                                  <li>Expired API key</li>
                                  <li>Insufficient permissions associated with the API key for data collection</li>
                                  <li>Inaccessibility of the data source</li>
                                </ul>
                                Please review the integration and API key configurations to resolve the issue.
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                </Box>
                <Typography className="subtitle">Description</Typography>
                <Typography className="body">{getDescription()}</Typography>
                <Box className="right-side-box">
                  <Typography className="box-title">Meta Data</Typography>
                  <Typography className="box-description">
                    Date created: {moment(selectedInstance.created_at).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography className="box-description">Created by: {selectedInstance.created_by}</Typography>
                  <Typography className="box-description spacing-top">
                    Date edited: {moment(selectedInstance.updated_at).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography className="box-description">Latest edit by: {selectedInstance.updated_by}</Typography>
                </Box>

                {renderAffectedCpis()}

                {selectedInstance.jiraProjects && selectedInstance.jiraProjects.length > 0 && (
                  <Box className="right-side-box">
                    <Typography className="box-title">Connected Projects</Typography>
                    {selectedInstance.jiraProjects.map((projectName) => (
                      <Typography className="box-description" key={projectName}>
                        {projectName === 'appsec'
                          ? 'Application Security'
                          : projectName === 'vulnerabilities'
                          ? 'Vulnerabilities'
                          : projectName === 'incidents'
                          ? 'Security Incidents'
                          : projectName}
                      </Typography>
                    ))}
                  </Box>
                )}
              </InstanceDetailsContainer>
            </Box>
          )}
        </DetailsOpenContainer>
      ) : (
        <ContentWrapper line={2} scrollHeight={226} marginTop={24}>
          <>
            {/* Integrations Tab */}
            <TabPanel value={tabValue} index={0} exists={supportedIntegrations.length}>
              {pageLoading ? (
                <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                  <CircularProgress size="40px" color="primary" />
                </Box>
              ) : !pageLoading && !supportedIntegrations.length ? (
                <EmptyState clear={handleClearFilters} />
              ) : (
                <IntegrationsList
                  instances={configuredIntegrations}
                  integrations={supportedIntegrations}
                  resultsLoading={loading}
                  handleOpenDetails={handleOpenDetails}
                  detailsOpen={detailsOpen}
                  setTabValue={setTabValue}
                  setSelectedInstance={setSelectedInstance}
                />
              )}
            </TabPanel>
            {/* Instances Tab */}
            <TabPanel value={tabValue} index={1} exists={configuredIntegrations.length}>
              {pageLoading ? (
                <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                  <CircularProgress size="40px" color="primary" />
                </Box>
              ) : !pageLoading && !loading && !configuredIntegrations.length ? (
                <EmptyState clear={handleClearFilters} />
              ) : (
                <InstancesList
                  instances={configuredIntegrations}
                  affectedCpis={affectedCpis}
                  loading={affectedCpisLoading}
                  resultsLoading={loading}
                  handleOpenDetails={handleOpenDetails}
                  detailsOpen={detailsOpen}
                  selectedInstance={selectedInstance}
                  setSelectedInstance={setSelectedInstance}
                />
              )}
            </TabPanel>
          </>
        </ContentWrapper>
      )}
    </IntegrationsContainer>
  )
}

function a11yProps(index: number) {
  return {
    id: `integrations-tab-${index}`,
    'aria-controls': `integrations-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, exists, ...other } = props

  return (
    <LibraryTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`integrations-tabpanel-${index}`}
      aria-labelledby={`integrations-tab-${index}`}
      {...other}
      sx={{
        display: 'flex',
        justifyContent: exists ? 'center' : 'flex-start',
        width: '100%',
        maxWidth: exists ? '920px' : '100%',
        margin: '0 auto !important'
      }}
    >
      {value === index && children}
    </LibraryTabPanel>
  )
}

export default Integrations
