import { FC, useState } from 'react'
import {
  TrendsChartDescriptionFormControl,
  TrendsChartDescriptionSelect,
  CategoryMenuProps
} from './trendsChartDescription.styles'
import { SelectDropdownIcon } from '../../../../../../components/svg'
import { ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  noborder?: string
  labeltext?: string
  disabled: boolean
  hideSelected?: boolean
  raise?: boolean
}

const TrendsChartSelectField: FC<Props> = ({
  options,
  labeltext,
  handleChange,
  placeholder,
  noborder,
  minWidth,
  value,
  disabled,
  hideSelected,
  raise,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  return (
    <TrendsChartDescriptionFormControl
      sx={{ minWidth }}
      isselected={
        hideSelected
          ? 'false'
          : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
          ? 'true'
          : 'false'
      }
      is_opened={anchorEl ? 'true' : 'false'}
      is_disabled={disabled ? 'true' : 'false'}
    >
      <TrendsChartDescriptionSelect
        noborder={noborder}
        isselected={
          hideSelected
            ? 'false'
            : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
            ? 'true'
            : 'false'
        }
        labelId={`library-select-${labeltext}-label`}
        id={`library-select-${labeltext}`}
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          sx: {
            zIndex: raise ? 3001 : '',
            display: anchorEl ? 'block' : 'none'
          },
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: { maxHeight: 'unset', display: anchorEl ? 'block' : 'none', zIndex: raise ? 3001 : '' }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return `${pruned[0]} +${pruned.length - 1}`
            }
            return pruned[0]
          }

          if (labeltext) {
            return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
          }
          return typeof noborder !== 'undefined' ? `Sort by: ${selected}` : selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput onMouseDown={handleClick} />}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
              }
            }}
            disableRipple
          >
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </TrendsChartDescriptionSelect>
    </TrendsChartDescriptionFormControl>
  )
}

export default TrendsChartSelectField
