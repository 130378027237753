import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const DataTableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '16px 16px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '8px',
    border: '1px solid #998d96',
    background: '#4d444b',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)'
  },
  '.tooltip-list': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  '.tooltip-header': {
    color: '#FFF',
    fontFamily: "'Quicksand',sans-serif",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    marginBottom: '8px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',

    img: {
      marginRight: '8px'
    }
  },
  '.tooltip-title': {
    color: '#FFF',
    fontFamily: "'Quicksand',sans-serif",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: ' 12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
