import { Dispatch, forwardRef, SetStateAction, useRef } from 'react'
import TableToolbar from './TableToolbar'
import LoadingSkeleton from '../../../../coverage-analyzer/components/LoadingSkeleton'
import DataTable from './DataTable'
import EmptyState from '../../../../coverage-analyzer/components/EmptyState'
import { TableHeaders, IPreviewData, PreviewDataIssue } from 'models'

type Ref = HTMLDivElement | null

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  columns: TableHeaders[]
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  loadingPreviewData: boolean
  initialLoad: boolean
  rows: PreviewDataIssue[]
  setRows: Dispatch<SetStateAction<PreviewDataIssue[]>>
  previewData: IPreviewData
}

const TableContent = forwardRef<Ref, Props>(
  (
    { search, setSearch, columns, setColumns, loadingPreviewData, initialLoad, rows, setRows, previewData },
    contentWrapperRef
  ) => {
    const tableContainerVisibleRef = useRef<HTMLDivElement | null>(null)
    const tableContainerRef = useRef<HTMLDivElement | null>(null)

    const getLoadingSkeletonHeight = () => {
      if (!initialLoad) {
        if (tableContainerVisibleRef && tableContainerVisibleRef.current) {
          return `calc(100vh - 268px) !important`
        }
      }

      if (contentWrapperRef && (contentWrapperRef as any).current) {
        if (
          (contentWrapperRef as any).current.clientHeight < 425 &&
          tableContainerVisibleRef &&
          tableContainerVisibleRef.current
        ) {
          return `${(tableContainerVisibleRef as any).current.clientHeight}px !important`
        }
        return `${(contentWrapperRef as any).current.clientHeight - 57}px !important`
      }

      return 'calc(100vh - 268px) !important'
    }

    const handleSort = async ({ column, direction }: { column: string; direction: string }) => {
      const newRows = rows.sort((a: any, b: any) =>
        direction === 'ASC' ? (a[column] > b[column] ? -1 : 1) : a[column] < b[column] ? -1 : 1
      )
      setRows(newRows)
    }

    return (
      <>
        <TableToolbar
          search={search}
          setSearch={setSearch}
          columns={columns}
          setColumns={setColumns}
          previewData={previewData}
        />
        {loadingPreviewData && <LoadingSkeleton height={getLoadingSkeletonHeight()} />}
        <div ref={tableContainerVisibleRef} className="ref-wrapper-data-table">
          {!loadingPreviewData && (
            <>
              <DataTable
                columns={columns}
                setColumns={setColumns}
                handleSort={handleSort}
                ref={tableContainerRef}
                rows={rows}
                search={search}
              />
            </>
          )}
        </div>
        {!loadingPreviewData && !rows.length && (
          <EmptyState
            title="No Results Found"
            description="Your filter did not match any issues, try adjusting the settings for better results"
          />
        )}
      </>
    )
  }
)

export default TableContent
