import { Box, Table as MuiTable } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  overflow-x: auto;
  width: 100%;
`

export const Table = styled(MuiTable)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  border: 1px solid #342f32;
`

export const TableContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  border: 1px solid #342f32;
`
