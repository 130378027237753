import { FC } from 'react'
import { Wrapper } from './EmptyState.styles'
import { Box, Typography } from '@mui/material'
import ZeroState from '../../../components/svg/ZeroState'

interface Props {
  title?: string
  description?: string
}

const EmptyState: FC<Props> = ({ title, description }) => {
  return (
    <Wrapper>
      <Box className="content">
        <ZeroState />
        <Typography className="title">{title || 'No Results Found'}</Typography>
        <Typography className="description">
          {description || 'Try changing the filter values, or select different sources'}
        </Typography>
      </Box>
    </Wrapper>
  )
}

export default EmptyState
