import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { TextField } from '@mui/material'

/* Components */
import { StyledAutocomplete } from '../../settings/integrations/components/form-components/integrationsSearch.styles'

interface Props {
  search: string
  placeholder: string
  setSearch: Dispatch<SetStateAction<string>>
}

const InsightsSearch: FC<Props> = ({ search, placeholder, setSearch }) => {
  const inputHandler = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    setSearch(e.target.value)
  }

  return (
    <StyledAutocomplete
      freeSolo
      id="integrations-search-box"
      options={[]}
      onInputChange={inputHandler}
      clearIcon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
            fill="white"
          />
        </svg>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          value={search}
          onChange={(e) => {
            if (params?.inputProps?.onChange) {
              params.inputProps.onChange(e as ChangeEvent<HTMLInputElement>)
            }
            setSearch(e.target.value)
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.76 13.27L20.49 19L19 20.49L13.27 14.76C12.2 15.53 10.91 16 9.5 16C5.91 16 3 13.09 3 9.5C3 5.91 5.91 3 9.5 3C13.09 3 16 5.91 16 9.5C16 10.91 15.53 12.2 14.76 13.27ZM9.5 5C7.01 5 5 7.01 5 9.5C5 11.99 7.01 14 9.5 14C11.99 14 14 11.99 14 9.5C14 7.01 11.99 5 9.5 5Z"
                  fill="#CAC4D0"
                />
              </svg>
            )
          }}
        />
      )}
      sx={{
        width: '300px',
        minWidth: '300px !important',

        '.MuiOutlinedInput-root': {
          width: '300px',
          minWidth: '300px !important'
        }
      }}
    />
  )
}

export default InsightsSearch
