/* eslint camelcase: 0 */
import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'

export const HeaderContainer = styled(Box)`
  justify-content: flex-end;
  display: flex;
  width: 100%;

  .header-content-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .title-wrapper {
      .header-title {
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-transform: capitalize;
      }
    }

    .search-container {
      width: 300px;
    }

    .date-select-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: 24px;

      .filter-icon-block {
        position: relative;
        display: flex;
        border-radius: 50%;
        background: transparent;
        padding: 8px;

        .active-circle {
          position: absolute;
          top: 3px;
          right: 3px;
          background: #1e1a1d;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;

          .circle {
            width: 8px;
            height: 8px;
            background: #ffe082;
            border-radius: 50%;
          }
        }
      }

      .date-range-picker-container {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .MuiFormControl-root {
          min-width: 113px;
          height: 40px;
          border-radius: 100px;
          //border: 1px solid #998d96;

          .MuiOutlinedInput-root {
            min-width: 113px;
            height: 40px;
            border-radius: 100px;
            //border: 1px solid #998d96;

            color: #d0c3cc;
            text-align: left;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
          }
        }
      }
    }
  }
`

export const DateRangePickerContainer = styled(Box)`
  display: flex;
  position: relative;
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
  border-radius: 8px 8px 0 0;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #debdd9;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`
