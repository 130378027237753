import { FC, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, SelectChangeEvent, Typography } from '@mui/material'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useLocation, useNavigate } from 'react-router-dom'

/* Utils */
import axios from '../../../lib/axios'
import { breadcrumbs, groupInsights } from '../../../lib/utils'
import { CPITimePeriod, Insight, InsightSortBy, InsightSubType } from 'models'
import { LabelOption } from '../library/components/LibrarySelectLabelField'
import useDebounce from '../../../hooks/useDebounce'
import { useSearchInsights } from '../../../hooks/useSearchInsights'
import { getIntegrationLogo } from '../coverage-analyzer/utils'

/* Components */
import { Container, Content, NoResultsWrapper } from './onyxAIInsights.styles'
import MainSection from './sections/main-section'
import useSearchLabel from '../../../hooks/useSearchLabel'
import ContentWrapper from '../../components/header/ContentWrapper'
import InsightsList from './sections/insights-list'
import InsightsCustomHeader from './components/InsightsCustomHeader'
import FiltersToolbar from './components/FiltersToolbar'
import NoIntegrations from './sections/NoIntegrations'
import { MonthRangePicker } from '@khan_skadi/month-range-picker'
import { ViewData, viewDataStyles } from './components/insightsTimePicker.styles'
import ThemeButton from '../../components/buttons/primary/ThemeButton'
import { formatCustomTimePickerValue, monthDiff } from '../library/utils'

dayjs.extend(isBetween)

const getDaysIntMonth = (y: number, m: number) => new Date(y, m, 0).getDate()

type RangeParams = {
  startMonth: number
  startYear: number
  endMonth: number
  endYear: number
}

const headerBackgroundVariants = {
  green: '/insights/green-insights-bg.png',
  red: '/insights/red-insights-bg.png'
}

export interface Filters {
  time: string
  subType: string[]
  cpis: string[]
  dataSources: string[]
  severity: string[]
  sortBy: InsightSortBy
  sortDirection: 'ASC' | 'DESC'
}

const defaultFilters: Filters = {
  time: 'All Time',
  subType: [],
  cpis: [],
  dataSources: [],
  severity: [],
  sortBy: InsightSortBy.InsightDate,
  sortDirection: 'DESC'
}

const severityOptions = [
  { name: 'All', label: 'All Severities' },
  { name: 'Critical', label: 'Critical Severities' },
  { name: 'High', label: 'High Severities' },
  {
    name: 'Medium',
    label: 'Medium Severities'
  },
  { name: 'Low', label: 'Low Severities' }
]

const timeOptions: LabelOption[] = [
  { name: 'All Time', label: 'All Time' },
  { name: 'Today', label: 'Today' },
  { name: 'Last Month', label: 'Last Month' },
  // { name: 'From Last Session', label: 'From Last Session' },
  { name: 'Custom...', label: 'Custom...' }
]

const typeOptions: LabelOption[] = [
  {
    name: InsightSubType.Improvement,
    label: 'Data Trend Improvement'
  },
  {
    name: InsightSubType.CrossedIntoGreen,
    label: 'Data Trending into Green Zone'
  },
  {
    name: InsightSubType.Reduction,
    label: 'Data Trend Reduction'
  },
  {
    name: InsightSubType.CrossedIntoRed,
    label: 'Data Trending into Red Zone'
  },
  {
    name: InsightSubType.MissingAssetTypeCoverage,
    label: 'Missing Asset Type Coverage'
  },
  {
    name: InsightSubType.MissingCategoryCoverage,
    label: 'Missing Category Coverage'
  },
  {
    name: InsightSubType.MissingStrategicCoverage,
    label: 'Missing Strategic Coverage'
  },
  {
    name: InsightSubType.CpiSlaOptimization,
    label: 'SLA Optimization'
  }
]

const OnyxAIInsights: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [initialLoad, setInitialLoad] = useState(false)
  const [results] = useState<Insight[]>([])
  const [filtersToolbarOpen, setFiltersToolbarOpen] = useState(false)
  const [cpiOptions, setCpiOptions] = useState<LabelOption[]>([])
  const [dataSourceOptions, setDataSourceOptions] = useState<LabelOption[]>([])
  const [filters, setFilters] = useState<Filters>(defaultFilters)
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null)
  const filtersActive = useMemo(() => {
    return (
      (filters.time.length > 0 && filters.time !== 'All Time') ||
      filters.subType.length > 0 ||
      filters.cpis.length > 0 ||
      filters.dataSources.length > 0 ||
      filters.severity.length > 0
    )
  }, [filters])
  const convertedOptions = useMemo(
    () =>
      results.map((item) => ({
        label: item.data?.cpi_display_name || '',
        name: item.data?.cpi_name || ''
      })),
    []
  )
  const [customTimePeriodValue, setCustomTimePeriodValue] = useState<string>('')
  const checkReadStatus = async (integrationIds: string[]) => {
    const payload = {
      ids: integrationIds
    }

    try {
      const res = await axios.post('/api/v3/insights/check-read-status', payload)

      if (res.status.toString().includes('20')) {
        return res.data.data
      }
    } catch (err) {
      console.error(err)
    }
  }
  const changeReadStatus = async (integrationIds: string[], read: boolean) => {
    const payload = {
      ids: integrationIds
    }

    try {
      await axios.post(`/api/v3/insights/${read ? '' : 'un'}read`, payload)
      await refetch()
    } catch (err) {
      console.error(err)
    }
  }
  const { search, setSearch } = useSearchLabel(convertedOptions, null, true)
  const debouncedSearchValue = useDebounce(search, 350)
  const [lastChanged, setLastChanged] = useState('')
  const { refetch, data, loading } = useSearchInsights({
    value: debouncedSearchValue,
    filters,
    reset: false,
    dataSourceOptions,
    initialLoad,
    lastChanged,
    changeReadStatus,
    checkReadStatus,
    customTimePeriodValue,
    cpiOptions,
    staleTime: 1000 * 60,
    keepPreviousData: false,
    enabled: initialLoad
  })
  const [loadingMarking, setLoadingMarking] = useState(false)
  const [markedInitialRead, setMarkedInitialRead] = useState(false)
  const allCardsAreInsights = useMemo(
    () =>
      (data && data.every((c: Insight) => c.type === 'ssm_insight')) ||
      (filters.subType.length > 0 &&
        filters.subType.every(
          (subType) =>
            subType === InsightSubType.MissingAssetTypeCoverage ||
            subType === InsightSubType.MissingCategoryCoverage ||
            subType === InsightSubType.MissingStrategicCoverage
        )),
    [data, filters]
  )

  useEffect(() => {
    if (location && location.state && (location as any).state.types) {
      const types = (location as any).state.types
      if (types.length > 0) {
        setFilters((pre) => ({ ...pre, subType: types, time: 'Last Month' }))
      }

      navigate('/onyxai-insights', {})
    }
  }, [location])

  const getGroups = (data: Insight[]): Insight[][] => {
    if (data && data.length > 0) {
      return groupInsights(data, filters.sortBy, filters.sortDirection)
    }

    return []
  }

  const groupData = (d: Insight[]): Insight[][] => {
    if (d && d.length > 0) {
      return getGroups(d)
    }

    return []
  }

  const [groups, setGroups] = useState<Insight[][]>([])

  useEffect(() => {
    if (data) {
      const groupedData = groupData(data)
      setGroups(groupedData)

      if (!cpiOptions.length) {
        const displayNames = data.map((n: any) => n.data.cpi_display_name)
        const cpiList: LabelOption[] = []
        data.forEach((c: any) => {
          if (c.type === 'ssm_insight') return
          if (displayNames.includes(c.data.cpi_display_name)) {
            const found = cpiList.find((n) => n.label === c.data.cpi_display_name)

            if (!found) {
              cpiList.push({ name: c.data.cpi_name, label: c.data.cpi_display_name })
            }
          }
        })
        setCpiOptions(cpiList)
      }
    } else {
      const haveGroups = groups.flat()
      if (!loading && haveGroups.length > 0) {
        setGroups([])
      }
    }
  }, [data])

  const getConfiguredIntegrations = async () => {
    try {
      const res = await axios.get('/api/v3/integrations/configured')

      if (res.status.toString().includes('20')) {
        const data = res.data.instances
        const uniqueOptions: any[] = []
        const options = data.map((n: any) => ({
          icon: getIntegrationLogo(n.integration_name),
          integration_name: n.integration_name,
          name: n.name,
          id: n.id,
          label: n.integration_name
        }))
        options.forEach((c: any) => {
          if (!uniqueOptions.find((n) => n.integration_name === c.integration_name)) {
            uniqueOptions.push(c)
          }
        })

        setDataSourceOptions(uniqueOptions)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!initialLoad) {
      getConfiguredIntegrations().finally(() => setInitialLoad(true))
    }
  }, [initialLoad])

  useEffect(() => {
    if (groups && groups.flat().length > 0 && !selectedInsight) {
      setSelectedInsight(groups.flat()[0])
    }
  }, [groups, filters, selectedInsight])

  useEffect(() => {
    const g = groups.flat()
    if (g.length > 0 && !!selectedInsight) {
      const found = g.find((c) => c.id === selectedInsight.id)

      if (!found) {
        setSelectedInsight(g[0])
      }
    }
  }, [groups, debouncedSearchValue])

  useEffect(() => {
    if (data && selectedInsight && !selectedInsight.read && !markedInitialRead) {
      const thisInsight = (data as Insight[]).find((c) => c.id === selectedInsight.id)

      const timeout = setTimeout(async () => {
        if (thisInsight && selectedInsight.id === thisInsight.id) {
          setLoadingMarking(true)
          setSelectedInsight({ ...selectedInsight, read: true })

          try {
            await changeReadStatus([selectedInsight.id], true)
            await refetch()
          } catch (err) {
            console.error(err)
          } finally {
            setLoadingMarking(false)
            setMarkedInitialRead(true)
          }
        }
      }, 5000)

      return () => clearTimeout(timeout)
    }
  }, [data, selectedInsight, markedInitialRead])

  const handleChangeFilters = async (newValue: any, field: string) => {
    setSelectedInsight(null)
    const temp = { ...filters } as any
    temp[field] = newValue
    setFilters(temp)
    setLastChanged(`${Math.random()}`)
  }

  const getHeaderBackground = (text: string) => {
    if (text.includes('red')) return headerBackgroundVariants.red
    if (text.includes('green')) return headerBackgroundVariants.green
    if (text.includes('improvement')) return headerBackgroundVariants.green

    return headerBackgroundVariants.red
  }

  const handleResetFilters = () => {
    setFilters((pre) => ({
      ...pre,
      time: 'All Time',
      subType: [],
      cpis: [],
      dataSources: [],
      severity: []
    }))
    setCustomTimePeriodValue('')
  }

  const handleClickInsight = async (insightId: string) => {
    if (loadingMarking) return
    const foundInsight = (data as Insight[]).find((c) => c.id === insightId)

    if (foundInsight) {
      setSelectedInsight({ ...foundInsight, read: true })
      const newResults = (data as Insight[]).map((n) => (n.id === foundInsight.id ? { ...n, read: true } : n))
      const groups = getGroups(newResults)
      setGroups(groups)

      if (!foundInsight.read) {
        try {
          setLoadingMarking(true)
          await changeReadStatus([foundInsight.id], true)
        } catch (err) {
          console.error(err)
        } finally {
          setLoadingMarking(false)
        }
      }
    }
  }

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [viewDataDisabled, setViewDataDisabled] = useState(true)
  const [pickerOpen, setPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleCustomClick = () => {
    setPickerOpen(!pickerOpen)
  }

  const closeCustomPicker = () => {
    setPickerOpen(false)
  }

  const onRangeSelect = (params: RangeParams) => {
    const { startMonth, endMonth, startYear, endYear } = params
    const stringMonthStart = startMonth.toString().padStart(2, '0')
    const stringMonthEnd = endMonth.toString().padStart(2, '0')
    if (startMonth && startYear) {
      setStart(`${startYear}-${stringMonthStart}-01`)
    }
    if (endMonth && endYear) {
      const days = getDaysIntMonth(endYear, endMonth)
      setEnd(`${endYear}-${stringMonthEnd}-${days}`)
    }
  }

  const handleChangeTimePeriod = async (event: SelectChangeEvent | null, customPayload?: any) => {
    const period = event ? (event.target.value as CPITimePeriod) : CPITimePeriod.Custom
    handleChangeFilters(period, 'time')

    if (period === CPITimePeriod.Custom) {
      const { dateFrom, dateTo } = customPayload

      const formattedValue = formatCustomTimePickerValue(dateFrom, dateTo)
      setCustomTimePeriodValue(formattedValue)
    } else {
      setCustomTimePeriodValue('')
    }
  }

  const handleViewData = () => {
    const diff = monthDiff(new Date(start), new Date(end))
    const today = new Date()
    const endDate = new Date(end)
    const res = endDate.getTime() > today.getTime() ? today : endDate
    const resFormatted = dayjs(res).format('YYYY-MM-DD')
    handleChangeTimePeriod(null, {
      dateFrom: start,
      dateTo: resFormatted,
      stripes: diff
    })
    setAnchorEl(null)
    closeCustomPicker()
  }

  const onChangeCallback = (month: any, startMonth: any, endMonth: any) => {
    if (!startMonth && !endMonth && month) {
      setViewDataDisabled(true)
    } else if (!endMonth) {
      setViewDataDisabled(false)
    } else if (startMonth && endMonth && month) {
      setViewDataDisabled(true)
    }
  }

  return (
    <Container className="insights-container">
      <InsightsCustomHeader
        breadcrumbs={breadcrumbs.insights}
        search={search}
        setSearch={setSearch}
        filtersToolbarOpen={filtersToolbarOpen}
        setFiltersToolbarOpen={setFiltersToolbarOpen}
        filtersActive={filtersActive}
        hasIntegrations={initialLoad && dataSourceOptions.length > 0}
      />

      <ContentWrapper
        line={1}
        sx={{ paddingBottom: '0 !important', position: 'relative', overflow: 'hidden !important' }}
      >
        {initialLoad ? (
          <>
            {!dataSourceOptions.length ? (
              <NoIntegrations />
            ) : !cpiOptions ? (
              <NoIntegrations version="cpi" />
            ) : (
              <>
                <FiltersToolbar
                  typeOptions={typeOptions}
                  filtersToolbarOpen={filtersToolbarOpen}
                  timeOptions={timeOptions}
                  cpiOptions={cpiOptions}
                  dataSourceOptions={dataSourceOptions}
                  severityOptions={severityOptions}
                  filters={filters}
                  setFilters={setFilters}
                  handleChangeFilters={handleChangeFilters}
                  handleResetFilters={handleResetFilters}
                  customTimePeriodValue={customTimePeriodValue}
                  anchorEl={anchorEl}
                  closeCustomPicker={closeCustomPicker}
                  handleCustomClick={handleCustomClick}
                  setAnchorEl={setAnchorEl}
                  handleChangeTimePeriod={handleChangeTimePeriod}
                  allCardsAreInsights={allCardsAreInsights}
                />

                <MonthRangePicker
                  onRangeSelect={onRangeSelect}
                  calendarClassName={`month-picker-container ${anchorEl && pickerOpen && 'isopen'}`}
                  headerClassName="month-picker-header"
                  monthClassName="month-picker-month"
                  columns={2}
                  initialYear={2023}
                  onChangeCallback={onChangeCallback}
                />
                <ViewData className={anchorEl && pickerOpen ? 'isopen' : ''}>
                  <ThemeButton
                    themevariant="secondary"
                    sx={viewDataStyles}
                    onClick={handleViewData}
                    disabled={viewDataDisabled}
                  >
                    OK
                  </ThemeButton>
                </ViewData>

                {!loading && !data && (
                  <NoResultsWrapper>
                    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="2"
                        y="2"
                        width="124"
                        height="124"
                        rx="62"
                        stroke="#4D444B"
                        strokeWidth="4"
                        strokeDasharray="8 8"
                      />
                      <path
                        d="M58 82C58 83.1 58.9 84 60 84H68C69.1 84 70 83.1 70 82V80H58V82ZM64 44C56.28 44 50 50.28 50 58C50 62.76 52.38 66.94 56 69.48V74C56 75.1 56.9 76 58 76H70C71.1 76 72 75.1 72 74V69.48C75.62 66.94 78 62.76 78 58C78 50.28 71.72 44 64 44ZM69.7 66.2L68 67.4V72H60V67.4L58.3 66.2C55.6 64.32 54 61.26 54 58C54 52.48 58.48 48 64 48C69.52 48 74 52.48 74 58C74 61.26 72.4 64.32 69.7 66.2Z"
                        fill="#D9BFD4"
                      />
                    </svg>

                    <Box className="no-results-content">
                      <Typography className="no-results-header">No Insights Found</Typography>
                      <Box>
                        <Typography className="no-results-text">There are no available insights to display.</Typography>
                        <Typography className="no-results-text">
                          Allow for a few days for new insights to be generated, or reset the page filters to see all
                          available insights.
                        </Typography>
                      </Box>
                    </Box>
                  </NoResultsWrapper>
                )}

                {groups && groups.length > 0 && selectedInsight && (
                  <Content className="content" isFiltersOpen={filtersToolbarOpen ? 'true' : 'false'}>
                    <Box className="insights-list-wrapper" sx={{ height: '100%', overflowY: 'auto' }}>
                      <InsightsList
                        data={data}
                        search={search}
                        insightsGroups={groups}
                        selectedInsight={selectedInsight}
                        handleClickInsight={handleClickInsight}
                        changeReadStatus={changeReadStatus}
                        setSelectedInsight={setSelectedInsight}
                        loading={loading}
                        handleChangeFilters={handleChangeFilters}
                        filters={filters}
                        setGroups={setGroups}
                        loadingMarking={loadingMarking}
                        setLoadingMarking={setLoadingMarking}
                      />
                    </Box>

                    <Box
                      sx={{
                        width: '1px',
                        height: '100vh',
                        background: '#4D444B',
                        overflow: 'hidden'
                      }}
                    />

                    <MainSection
                      insight={selectedInsight}
                      getHeaderBackground={getHeaderBackground}
                      isFetching={loading}
                      loadingMarking={loadingMarking}
                      search={search}
                    />
                  </Content>
                )}
              </>
            )}
          </>
        ) : (
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '40px' }}
          >
            <CircularProgress size="36px" />
          </Box>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default OnyxAIInsights
