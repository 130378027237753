import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)<{ logoSize: number; isSquare?: boolean }>`
  .error-wrapper {
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid #ff8a80;
    box-sizing: content-box;
    margin-left: ${({ logoSize }) => (logoSize === 40 ? '6px' : '')};

    ${({ isSquare }) => isSquare && 'border-radius: 8px'};

    img {
      border-radius: 50%;
    }

    svg {
      position: absolute;
      bottom: -2px;
      left: 5px;

      &.error-content {
        bottom: 0;
        left: 7px;
      }
    }

    ${({ logoSize }) =>
      logoSize === 23 &&
      `
        svg {
          position: absolute;
          bottom: -2px;
          left: 10px;
    
          &.error-content {
            bottom: 0;
            left: 12px;
          }
        }
      `};

    ${({ isSquare }) =>
      isSquare &&
      `
        svg {
          bottom: -2px;
          right: -2px;
          left: unset;
    
          &.error-content {
            bottom: 0;
            right: 0;
            left: unset;
          }
        }
      `};

    &.no-base-icon {
      width: 14px;
      height: 14px;
      border: 0;

      svg {
        top: 0;
        left: 0;
        right: unset;

        &.error-content {
          bottom: unset;
          top: 2px;
          left: 2px;
          right: unset;
        }
      }
    }

    &.trends-chart-error {
      svg {
        top: 7px;
        left: 7px;

        &.error-content {
          bottom: unset;
          top: 9px;
          left: 9px;
        }
      }
    }
  }
`
