import { FC, Dispatch, SetStateAction, useMemo, useState } from 'react'
import {
  Box,
  FormControlLabel,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SelectProps,
  Typography
} from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from 'ui/components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './librarySelectField.styles'
import SelectSearch from './SelectSearch'
import ResetSearch from 'ui/components/form-components/ResetSearch'
import useSearchLabel from 'hooks/useSearchLabel'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
  action?: () => void
  id?: string
  integration_name?: string
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  iscustom?: string
  hideSearch?: boolean
  label?: string
  version?: string
  field?: string
  showActiveDataSources?: boolean
  activeDataSourcesList?: string[]
  allChecked?: boolean
  setAllChecked?: Dispatch<SetStateAction<boolean>>
}

const LibrarySelectLabelField: FC<Props> = ({
  version,
  label,
  options,
  handleChange,
  iscustom,
  handleReset,
  placeholder,
  minWidth,
  value,
  hideSearch = false,
  field,
  showActiveDataSources,
  activeDataSourcesList,
  allChecked,
  setAllChecked,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { search, setSearch, labelOptions, resetData, highlightMatch } = useSearchLabel(options, handleReset)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    const pruned = value.filter((s: string) => s !== '')
    const option = options.find((n) => n.name === pruned[0])

    if (option) {
      if (pruned.length === 1) {
        return option.label
      }

      if (placeholder && pruned.length === 0) {
        return placeholder
      }

      return `${pruned.length} selected`
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}

      <LibraryFormControl
        sx={{ minWidth }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length) ? 'true' : 'false'}
        iscustom={iscustom}
      >
        <LibrarySelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {!hideSearch && (
            <SelectSearch
              search={search}
              setSearch={setSearch}
              placeholder={version ? `Search ${version}` : 'Search...'}
            />
          )}
          {showActiveDataSources && (
            <>
              <FormControlLabel
                className="all-option-form-control"
                control={
                  <Checkbox
                    icon={<InstanceUncheckedIcon />}
                    checkedIcon={<InstanceCheckedIcon />}
                    checked={allChecked}
                    value={allChecked}
                    onChange={(event, checked) => {
                      if (setAllChecked) {
                        setAllChecked(checked)
                      }
                      handleChange(
                        {
                          target: { value: checked ? activeDataSourcesList || [] : [] }
                        },
                        field || ''
                      )
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="All Active Data Sources"
              />
              <Box className="divider" />
            </>
          )}

          <Box className="list-wrapper">
            {labelOptions.map((option) => (
              <MenuItem
                disableRipple
                autoFocus={false}
                key={option.name}
                value={option.name}
                onClick={() => {
                  let nextValue = [...value]
                  if (nextValue.includes(option.name)) {
                    nextValue = nextValue.filter((c) => c !== option.name)
                  } else {
                    nextValue = [...nextValue, option.name]
                  }
                  if (Array.isArray(activeDataSourcesList)) {
                    const length = nextValue.filter((c: any) => activeDataSourcesList.includes(c))
                    const thisChecked =
                      nextValue.length === activeDataSourcesList.length &&
                      length.length === activeDataSourcesList.length
                    if (setAllChecked) {
                      setAllChecked(thisChecked)
                    }
                  }
                  handleChange({ target: { value: nextValue } }, field || '')
                  if (!rest.multiple) {
                    setAnchorEl(null)
                  }
                }}
              >
                {rest.multiple && (
                  <Checkbox
                    checked={value.indexOf(option.name) > -1}
                    icon={<InstanceUncheckedIcon />}
                    checkedIcon={<InstanceCheckedIcon />}
                  />
                )}
                <ListItemText primary={highlightMatch(option.label)} />
              </MenuItem>
            ))}
          </Box>

          <Box className="divider" sx={{ marginTop: '12px !important' }} />
          <ResetSearch resetData={resetData} />
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default LibrarySelectLabelField
