import { FC } from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  activeSection: number
}

const MainSectionTitle: FC<Props> = ({ activeSection }) => (
  <Box className="main-section-header">
    <Typography className="main-section-title">
      {activeSection === 0 ? 'Application Security' : activeSection === 1 ? 'Security Incidents' : 'Vulnerabilities'}
    </Typography>
    <Typography className="main-section-subtitle">
      Connect projects handling application security so it can be measured by Onyxia CPIs
    </Typography>
  </Box>
)

export default MainSectionTitle
