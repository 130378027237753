import { ReactNode } from 'react'
import { TabPanelWrapper } from '../../../settings/integrations/integrations.styles'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  sx?: Record<string, unknown>
}

export function a11yProps(index: number) {
  return {
    id: `library-details-tab-${index}`,
    'aria-controls': `library-details-tabpanel-${index}`
  }
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <TabPanelWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`aca-tabpanel-${index}`}
      aria-labelledby={`aca-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabPanelWrapper>
  )
}
