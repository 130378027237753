import { Dispatch, FC, SetStateAction } from 'react'
import {
  // SelectChangeEvent,
  Toolbar,
  useMediaQuery
} from '@mui/material'

/* Utils */
import type { Filters } from '../OnyxAIInsights'

/* Components */
import { Wrapper, AppBar } from './filtersToolbar.styles'
import { ThemeButton } from '../../../components/buttons'
import OnyxiaSelect, { LabelOption } from './OnyxiaSelect'
import OnyxiaSelectSearch from './OnyxiaSelectSearch'
// import { CPITimePeriod } from '../../../../models'
// import { formatCustomTimePickerValue } from '../../library/utils'
import InsightsTimePicker from './InsightsTimePicker'

// import { LocalizationProvider } from '@mui/x-date-pickers-pro'
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
// import { DateRangePickerContainer } from './insightsHeader.styles'
// import {
//   AuditLogFormControl,
//   AuditLogSelect,
//   CategoryMenuProps
// } from '../../library/library-details/sections/trends-tab/components/TrendsChartSelectField.styles'
// import { SelectDropdownIcon } from '../../../components/svg'
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
// import dayjs, { Dayjs } from 'dayjs'
// import { dateRangePickerPopperSx } from '../onyxAIInsights.styles'

interface Props {
  typeOptions: LabelOption[]
  filtersToolbarOpen: boolean
  timeOptions: LabelOption[]
  cpiOptions: LabelOption[]
  dataSourceOptions: any
  severityOptions: LabelOption[]
  filters: Filters
  setFilters: Dispatch<SetStateAction<Filters>>
  handleChangeFilters: (newValue: any, field: string) => void
  handleResetFilters: () => void
  customTimePeriodValue: string
  handleChangeTimePeriod: any
  anchorEl: any
  closeCustomPicker: any
  handleCustomClick: any
  setAnchorEl: any
  allCardsAreInsights: boolean
}

const FiltersToolbar: FC<Props> = ({
  filtersToolbarOpen,
  typeOptions,
  timeOptions,
  cpiOptions,
  dataSourceOptions,
  severityOptions,
  filters,
  setFilters,
  handleChangeFilters,
  handleResetFilters,
  customTimePeriodValue,
  handleChangeTimePeriod,
  anchorEl,
  closeCustomPicker,
  handleCustomClick,
  setAnchorEl,
  allCardsAreInsights
}) => {
  const breakpoint = useMediaQuery('(max-width:1500px)')
  const breakpointSmall = useMediaQuery('(max-width:1300px)')

  const checkTimePeriodDisabled = (): boolean => {
    return false
  }

  return (
    <Wrapper className="filters-toolbar-wrapper" isFiltersOpen={filtersToolbarOpen ? 'true' : 'false'}>
      <AppBar isFiltersOpen={filtersToolbarOpen ? 'true' : 'false'}>
        <Toolbar>
          <InsightsTimePicker
            timePeriodValues={timeOptions.map((n) => n.name).filter((c) => c !== 'Custom...')}
            selectedTimePeriod={filters.time}
            handleChangeTimePeriod={handleChangeTimePeriod}
            customTimePeriodValue={customTimePeriodValue}
            checkTimePeriodDisabled={checkTimePeriodDisabled}
            hideSelected
            disabled={false}
            anchorEl={anchorEl}
            closeCustomPicker={closeCustomPicker}
            handleCustomClick={handleCustomClick}
            setAnchorEl={setAnchorEl}
            maxWidth={breakpointSmall ? '110px' : breakpoint ? '130px' : undefined}
          />
          <OnyxiaSelect
            label="Type"
            options={typeOptions}
            placeholder="All Types"
            value={filters.subType}
            handleChange={(e: any) => {
              handleChangeFilters(e.target.value, 'subType')
            }}
            handleReset={() => setFilters((pre) => ({ ...pre, subType: [] }))}
            iscustom="true"
            multiple
            maxWidth={breakpointSmall ? '110px' : breakpoint ? '130px' : undefined}
          />
          <OnyxiaSelectSearch
            label="CPI"
            options={cpiOptions.map((n) => n.label)}
            value={filters.cpis}
            placeholder="All CPIs"
            handleChange={(e: any) => {
              handleChangeFilters(e.target.value, 'cpis')
            }}
            handleReset={() => setFilters((pre) => ({ ...pre, cpis: [] }))}
            iscustom="true"
            width="250px"
            inputWidth="120px"
            multiple
            disabled={allCardsAreInsights}
          />
          <OnyxiaSelectSearch
            label="Data Sources"
            options={dataSourceOptions.map((n: any) => n.label)}
            placeholder="All Data Sources"
            value={filters.dataSources}
            handleChange={(e: any) => {
              handleChangeFilters(e.target.value, 'dataSources')
            }}
            handleReset={() => setFilters((pre) => ({ ...pre, dataSources: [] }))}
            iscustom="true"
            width="250px"
            maxWidth={breakpointSmall ? '110px !important' : breakpoint ? '130px !important' : undefined}
            multiple
            showIcons
            disabled={allCardsAreInsights}
          />
          <OnyxiaSelect
            label="Severity"
            options={severityOptions}
            placeholder="All Severities"
            value={filters.severity}
            handleChange={(e: any) => {
              handleChangeFilters(e.target.value, 'severity')
            }}
            handleReset={() => setFilters((pre) => ({ ...pre, severity: [] }))}
            iscustom="true"
            multiple
            maxWidth={breakpointSmall ? '110px' : breakpoint ? '130px' : undefined}
            disabled={allCardsAreInsights}
          />
          <ThemeButton themevariant="tertiary" onClick={handleResetFilters} sx={{ minWidth: '136px' }}>
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.05 4.9375C17.2375 3.125 14.75 2 11.9875 2C6.4625 2 2 6.475 2 12C2 17.525 6.4625 22 11.9875 22C16.65 22 20.5375 18.8125 21.65 14.5H19.05C18.025 17.4125 15.25 19.5 11.9875 19.5C7.85 19.5 4.4875 16.1375 4.4875 12C4.4875 7.8625 7.85 4.5 11.9875 4.5C14.0625 4.5 15.9125 5.3625 17.2625 6.725L13.2375 10.75H21.9875V2L19.05 4.9375Z"
                  fill="#FFA9FC"
                />
              </svg>
              Reset All
            </>
          </ThemeButton>
        </Toolbar>
      </AppBar>
    </Wrapper>
  )
}

export default FiltersToolbar
