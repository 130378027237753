import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CardSkeletonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const CardSkeleton = styled(Box)`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: #573f56;
    border-radius: 4px;

    &:after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`
