import * as d3 from 'd3'

export const raiseElements = (svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>) => {
  svg.selectAll('.sla-tooltip-wrapper').raise()
  svg.selectAll('.cartesian-grid-horizontal').raise()
  svg.selectAll('.sla-line-left').raise()
  svg.selectAll('.sla-line-right').raise()
  svg.selectAll('.recent-performance-line-right').raise()
  svg.selectAll('.filled-area-stroke').raise()
  svg.selectAll('.filled-area-stroke-2').raise()
  svg.selectAll('.filled-area-stroke-prediction').raise()
  svg.selectAll('.event-indicator-count').raise()
  svg.selectAll('.add-event-button').raise()
  svg.selectAll('.count-tooltip').raise()
  svg.selectAll('.add-button-tooltip').raise()
  svg.selectAll('.add-button').raise()
  svg.selectAll('.add-button-rect-hor').raise()
  svg.selectAll('.add-button-rect-ver').raise()
  svg.selectAll('.line-top').raise()
  svg.selectAll('.line-bottom').raise()
  svg.selectAll('.hover-circle-outer').raise()
  svg.selectAll('.hover-circle-inner').raise()
  svg.selectAll('.sla').raise()
}
