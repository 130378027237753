import { FC, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../../../components/svg'
import SortIcon from '../../../../../components/svg/SortIcon'
import {
  CategoryMenuProps,
  IntegrationsFormControl,
  IntegrationsSelect,
  Wrapper
} from './integrationsComponents.styles'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  label?: string
  noBorder?: boolean
  isSort?: boolean
}

const IntegrationsSelectField: FC<Props> = ({
  noBorder,
  isSort,
  options,
  handleChange,
  placeholder,
  minWidth,
  value,
  label,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Wrapper isSort={isSort}>
      {label && <Typography className="label-text">{label}</Typography>}
      {isSort && <SortIcon />}
      <IntegrationsFormControl
        isSort={isSort}
        noBorder={noBorder}
        sx={{
          minWidth
        }}
      >
        <IntegrationsSelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={(props) => <SelectDropdownIcon {...props} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned.length} selected`
            }

            return selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{
            minWidth
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
            >
              {rest.multiple && (
                <Checkbox
                  checked={value.indexOf(option) > -1}
                  icon={<InstanceUncheckedIcon />}
                  checkedIcon={<InstanceCheckedIcon />}
                />
              )}
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </IntegrationsSelect>
      </IntegrationsFormControl>
    </Wrapper>
  )
}

export default IntegrationsSelectField
