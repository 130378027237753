import { FC, Dispatch, SetStateAction, useMemo } from 'react'
import { Box, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

/* Utils */
import { ManifestIntegration, ManifestIntegrationConfigSchema } from 'models'
import { DialogProps, TestStatus } from '../SetupJiraInstance'
import { stepsImages } from '../../setupIntegration.constants'

/* Components */
import { ConnectionDetailsTooltip } from '../../components/ConnectionDetailsTooltip'
import ControlledTextFieldCustomLabel from 'ui/components/form-components/ControlledTextFieldCustomLabel'
import ControlledCustomTrendsSelect from 'ui/components/form-components/ControlledCustomTrendsSelect'
import { IntegrationsCheckIcon, IntegrationsFailIcon, VisibilityOffIcon, VisibilityOnIcon } from 'ui/components/svg'
import { ThemeButton } from 'ui/components/buttons'
import { EditIntegrationContainer } from '../../integrations.styles'

interface Props {
  formik: any
  testStatus: TestStatus
  setTestStatus: Dispatch<SetStateAction<TestStatus>>
  setAllowSave: Dispatch<SetStateAction<boolean>>
  instructionsOpen: boolean
  handleExpand: () => void
  integration: ManifestIntegration
  visibleValues: Record<string, boolean>
  setVisibleValues: Dispatch<SetStateAction<Record<string, boolean>>>
  loading: boolean
  testing: boolean
  integrationLoading: boolean
  instructions: RegExpMatchArray | null
  setDialogProps: Dispatch<SetStateAction<DialogProps>>
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  nameHelperText: string
  setNameHelperText: Dispatch<SetStateAction<string>>
}

const DataConnection: FC<Props> = ({
  formik,
  testStatus,
  setTestStatus,
  setAllowSave,
  instructionsOpen,
  handleExpand,
  integration,
  visibleValues,
  setVisibleValues,
  loading,
  testing,
  integrationLoading,
  instructions,
  setDialogProps,
  setDialogOpen,
  nameHelperText,
  setNameHelperText
}) => {
  const testDisabled = useMemo(() => {
    if (!integration) return true

    const values = formik.values
    const schema = integration.config.schema

    if (!values.name || !values.name.length) {
      return true
    }

    let disabled = false
    schema.forEach((item) => {
      if (item.required) {
        const found = values[item.name]
        if (typeof found !== 'undefined') {
          if (item.required && item.type === 'string') {
            if (found.length < 1) {
              disabled = true
            }
          }
        }

        if (!found) {
          disabled = true
        }
      }
    })

    return disabled
  }, [formik.values, integration])

  const sortIntegrationsSchemaByOrder = (items: ManifestIntegrationConfigSchema[]) => {
    return items.sort((a, b) => ((a as any).order > (b as any).order ? 1 : -1))
  }

  const handleClearAll = () => {
    const fields = formik.values
    const newValues: any = {}
    for (const key of Object.keys(fields)) {
      if (key === 'name' || key === 'description') {
        newValues[key] = fields[key]
      } else {
        newValues[key] = ''
      }
    }
    formik.setValues(newValues)
  }

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <EditIntegrationContainer
        testStatus={testStatus}
        className="sections-wrapper"
        isopen={instructionsOpen ? 'true' : 'false'}
      >
        <Box id="left-side" className="left">
          <Box className="info">
            <Box className="connection-details-wrapper">
              <Box className="connection-details-description">
                <Typography className="connection-details-title">Connection Details</Typography>
                <Typography className="connection-details-text">
                  Provide the necessary information to establish and configure your connection
                </Typography>
              </Box>
              <Box className="connection-details-action">
                <ConnectionDetailsTooltip title={instructionsOpen ? 'Less information' : 'More information'} arrow>
                  <IconButton
                    onClick={handleExpand}
                    sx={{
                      background: instructionsOpen ? '#590060' : '',

                      svg: {
                        path: {
                          fill: instructionsOpen ? '#D9BFD4' : '#FFA9FC'
                        }
                      },

                      '&:hover': {
                        background: '#544152',

                        svg: {
                          path: {
                            fill: '#FFA9FC'
                          }
                        }
                      }
                    }}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#FFA9FC"
                      />
                    </svg>
                  </IconButton>
                </ConnectionDetailsTooltip>
              </Box>
            </Box>

            <ControlledTextFieldCustomLabel
              name="name"
              label="Name"
              required
              onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
              value={formik.values.name}
              onChange={(e) => {
                formik.handleChange(e)

                const value = e.target.value
                const regex = /^[0-9a-zA-Z\-_]+$/
                if (!value.length) {
                  setNameHelperText('Please enter a name for the instance')
                  formik.setFieldError('name', 'Please enter a name for the instance')
                  setAllowSave(false)
                } else if (!value.match(regex)) {
                  setNameHelperText('Only alphanumeric, dash and hyphen characters are allowed')
                  formik.setFieldError('name', 'Only alphanumeric, dash and hyphen characters are allowed')
                  setAllowSave(false)
                } else {
                  setNameHelperText('')
                  formik.setFieldError('name', undefined)
                  setAllowSave(true)
                }
              }}
              disabled={loading || formik.isSubmitting}
              helperText={nameHelperText}
            />

            <ControlledTextFieldCustomLabel
              name="description"
              label="Description (Optional)"
              value={formik.values.description}
              onChange={(e) => {
                setAllowSave(true)
                formik.handleChange(e)
              }}
              onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
              disabled={loading || formik.isSubmitting}
            />

            {integration.config.schema &&
              sortIntegrationsSchemaByOrder(integration.config.schema).map((n) => {
                const hasToggleVisibility = !!n.airbyte_secret
                if (n.enum && n.enum.length > 0) {
                  return (
                    <ControlledCustomTrendsSelect
                      options={n.enum}
                      placeholder={n.title}
                      label="Region"
                      labeltext={n.title}
                      value={formik.values[n.name]}
                      onChange={(e) => {
                        setAllowSave(false)
                        formik.handleChange(e)
                      }}
                      name={n.name}
                      disabled={false}
                      multiple={false}
                      fullWidth
                      autoComplete="off"
                    />
                  )
                }
                return (
                  <ControlledTextFieldCustomLabel
                    data-private
                    key={n.name}
                    name={n.name}
                    label={n.title}
                    required={n.required}
                    placeholder={`Enter ${n.title}`}
                    onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                    autoComplete="off"
                    value={formik.values[n.name]}
                    onChange={(e) => {
                      setAllowSave(false)
                      formik.handleChange(e)
                    }}
                    type={
                      n.type === 'integer'
                        ? 'number'
                        : hasToggleVisibility
                        ? visibleValues[n.name]
                          ? 'text'
                          : 'password'
                        : 'text'
                    }
                    endAdornment={
                      hasToggleVisibility && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle Client Secret visibility"
                            onClick={() => setVisibleValues((pre) => ({ ...pre, [n.name]: !visibleValues[n.name] }))}
                            edge="end"
                          >
                            {!visibleValues[n.name] ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    disabled={loading || formik.isSubmitting}
                  />
                )
              })}
          </Box>

          <Box className="actions">
            <ThemeButton
              type="submit"
              disabled={
                formik.isSubmitting ||
                testDisabled ||
                !formik.isValid ||
                loading ||
                !integration?.config?.schema ||
                nameHelperText.length > 0
              }
              themevariant="secondary"
              startIcon={testing && <CircularProgress size="24px" />}
              sx={{
                minWidth: '190px',

                '.MuiCircularProgress-root': {
                  color: '#FFA9FC'
                },
                '&.Mui-disabled': {
                  borderRadius: '100px',
                  border: testStatus === TestStatus.unchecked && !testing ? '' : '1px solid #998D96 !important',
                  background: testStatus === TestStatus.unchecked && !testing ? '' : 'transparent !important',
                  color: testStatus === TestStatus.unchecked && !testing ? '#FFA9FC' : '#fff !important'
                }
              }}
            >
              <>
                {testStatus === TestStatus.unchecked && (
                  <>
                    {testing ? (
                      'Testing...'
                    ) : (
                      <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.5556 3L22 7.44444L17.5556 11.8889V8.55556H13.1111V6.33333H17.5556V3ZM9.77778 6.33333C9.16667 6.33333 8.66667 6.83333 8.66667 7.44444C8.66667 8.05556 9.16667 8.55556 9.77778 8.55556C10.3889 8.55556 10.8889 8.05556 10.8889 7.44444C10.8889 6.83333 10.3889 6.33333 9.77778 6.33333ZM5.33333 6.33333C4.72222 6.33333 4.22222 6.83333 4.22222 7.44444C4.22222 8.05556 4.72222 8.55556 5.33333 8.55556C5.94444 8.55556 6.44444 8.05556 6.44444 7.44444C6.44444 6.83333 5.94444 6.33333 5.33333 6.33333ZM6.44444 17.4444H10.8889V15.2222H6.44444V11.8889L2 16.3333L6.44444 20.7778V17.4444ZM14.2222 17.4444C14.8333 17.4444 15.3333 16.9444 15.3333 16.3333C15.3333 15.7222 14.8333 15.2222 14.2222 15.2222C13.6111 15.2222 13.1111 15.7222 13.1111 16.3333C13.1111 16.9444 13.6111 17.4444 14.2222 17.4444ZM18.6667 17.4444C19.2778 17.4444 19.7778 16.9444 19.7778 16.3333C19.7778 15.7222 19.2778 15.2222 18.6667 15.2222C18.0556 15.2222 17.5556 15.7222 17.5556 16.3333C17.5556 16.9444 18.0556 17.4444 18.6667 17.4444Z"
                            fill="#FFA9FC"
                          />
                        </svg>
                        Test Connection
                      </>
                    )}
                  </>
                )}
                {testStatus === TestStatus.successful && (
                  <>
                    <IntegrationsCheckIcon />
                    Test Successful
                  </>
                )}
                {testStatus === TestStatus.failed && (
                  <>
                    <IntegrationsFailIcon />
                    Test Failed
                  </>
                )}
              </>
            </ThemeButton>
            <ThemeButton
              themevariant="tertiary"
              type="button"
              sx={{ paddingLeft: '16px !important', paddingRight: '16px !important' }}
              onClick={handleClearAll}
              disabled={loading || formik.isSubmitting}
            >
              <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.2222 8.66667V19.7778H7.33333V8.66667H16.2222ZM14.5556 2H9L7.88889 3.11111H4V5.33333H19.5556V3.11111H15.6667L14.5556 2ZM18.4444 6.44444H5.11111V19.7778C5.11111 21 6.11111 22 7.33333 22H16.2222C17.4444 22 18.4444 21 18.4444 19.7778V6.44444Z"
                    fill="#FFA9FC"
                  />
                </svg>
                Clear All
              </>
            </ThemeButton>
          </Box>
        </Box>

        {instructionsOpen && (
          <Box id="right-side" className="right">
            <Box className="right-text-wrapper">
              <Typography className="instructions-text">Instructions</Typography>
              {integrationLoading ? (
                ''
              ) : (
                <>
                  {!instructions?.length ? (
                    <Typography className="instructions-description">N/A</Typography>
                  ) : (
                    <Typography className="instructions-description">
                      Follow these steps to successfully set up and configure your connection
                    </Typography>
                  )}
                </>
              )}
            </Box>

            {instructions?.map((instruction, index) => {
              const thisInstruction = instruction.slice(3)
              const idx = index + 1
              return (
                <Box className="instructions-inner" key={instruction}>
                  {stepsImages[integration.documentation.path] &&
                  stepsImages[integration.documentation.path].includes(idx) ? (
                    // <EnlargedImage index={index} integration={integration} />
                    <img
                      alt={`step_${idx}_image`}
                      src={`/${integration.documentation.path}_step_${idx}.png`}
                      width="160px"
                      className="instructions-image"
                      onClick={() => {
                        handleOpenDialog()
                        setDialogProps({
                          image: `/${integration.documentation.path}_step_${idx}.png`,
                          instruction,
                          index: idx
                        })
                      }}
                    />
                  ) : (
                    <Box className="empty-image" />
                  )}

                  <Box className="instructions-right-side">
                    <Typography className="step-text">STEP {idx}</Typography>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{thisInstruction}</ReactMarkdown>
                  </Box>
                </Box>
              )
            })}
          </Box>
        )}
      </EditIntegrationContainer>
    </form>
  )
}

export default DataConnection
