import { FC, useMemo, useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { DataMappingDefaultValues, JiraField, DataMappingField, DataMappingOption, FieldOption } from 'models'

/* Components */
import { Wrapper } from './dataMapping.styles'
import DataFilteringLoading from '../data-filtering/DataFilteringLoading'
import JiraMappingFields from './JiraMappingFields'

interface Props {
  dataMappingFields: DataMappingField[]
  dataMappingOptions: DataMappingOption[]
  jiraFields: JiraField[]
  dataMappingValues: any
  setDataMappingValues: any
  dataMappingDefaults: DataMappingDefaultValues[]
  dataMappingDefaultsValues: any
  setDataMappingDefaultsValues: any
  handleApplyDefaultValues: any
  fieldOptions: FieldOption[]
  jiraFiltersLoading: boolean
}

const DataMapping: FC<Props> = ({
  dataMappingFields,
  dataMappingOptions,
  dataMappingValues,
  setDataMappingValues,
  jiraFields,
  dataMappingDefaults,
  setDataMappingDefaultsValues,
  dataMappingDefaultsValues,
  handleApplyDefaultValues,
  fieldOptions,
  jiraFiltersLoading
}) => {
  const foundDefaults = dataMappingDefaults.find((n) => n.dataMappingField.id === 'closed_status')
  if (!foundDefaults) return null

  const thisDefaultValue = useMemo(
    () => dataMappingDefaultsValues[foundDefaults.jiraField.apiName],
    [dataMappingDefaultsValues, foundDefaults]
  )
  const thisCustomOptions: any = fieldOptions
    ? fieldOptions.find((n: any) => n.apiName === foundDefaults.jiraField.apiName)
    : []

  const thisApiName = jiraFields.find((n) => n.id === 'priority_id')?.apiName || ''
  const thisOptions = dataMappingOptions ? dataMappingOptions.find((c) => c.apiName === thisApiName) : undefined
  const [optionValues, setOptionValues] = useState<any[]>(
    thisOptions
      ? thisOptions.options
          .map((c: any) => ({
            id: c.id,
            name: c.name,
            label: c.name,
            disabled: false,
            enabledForField: ''
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : []
  )

  useEffect(() => {
    const newValues: any[] = []

    for (const [key, value] of Object.entries(dataMappingValues)) {
      if (key !== 'closed_status') {
        if ((value as any).length > 0) {
          ;(value as any).forEach((item: any) => {
            newValues.push({
              id: item.id || item,
              name: item.name || item,
              label: item.name || item,
              disabled: true,
              enabledForField: key
            })
          })
        }
      }
    }
    const selectedValues: string[] = newValues.map((c) => c.name)
    const addedValues: any[] =
      thisOptions?.options
        ?.filter((c) => !selectedValues.includes(c.name))
        .map((c) => ({
          id: c.id,
          name: c.name,
          label: c.name,
          disabled: false,
          enabledForField: ''
        })) || []
    newValues.push(...addedValues)
    setOptionValues(newValues.sort((a, b) => (a.name > b.name ? 1 : -1)))
  }, [dataMappingValues, thisOptions])

  const renderJiraList = useMemo(() => {
    if (jiraFiltersLoading) {
      return <DataFilteringLoading length={5} />
    }
    return (
      <JiraMappingFields
        foundDefaults={foundDefaults}
        thisCustomOptions={thisCustomOptions}
        thisDefaultValue={thisDefaultValue}
        handleApplyDefaultValues={handleApplyDefaultValues}
        setDataMappingDefaultsValues={setDataMappingDefaultsValues}
        optionValues={optionValues}
        dataMappingValues={dataMappingValues}
        setDataMappingValues={setDataMappingValues}
        setOptionValues={setOptionValues}
      />
    )
  }, [
    jiraFiltersLoading,
    optionValues,
    foundDefaults,
    thisCustomOptions,
    thisDefaultValue,
    handleApplyDefaultValues,
    setDataMappingDefaultsValues,
    dataMappingValues,
    setDataMappingValues,
    setOptionValues
  ])

  return (
    <Wrapper className="data-mapping-wrapper">
      <Box className="section">
        <Typography className="header">Onyxia</Typography>
        <Box className="list">
          {jiraFiltersLoading ? (
            <DataFilteringLoading length={5} />
          ) : (
            dataMappingFields.map((c, i) => (
              <>
                <Box className="list-item-onyxia" key={i}>
                  <Typography className="list-item-text">{c.displayName}</Typography>
                </Box>
                {i === 0 && <Box className="separator" />}
              </>
            ))
          )}
        </Box>
      </Box>

      <Box className="section">
        <Typography className="header">Jira</Typography>
        <Box className="list">{renderJiraList}</Box>
      </Box>
    </Wrapper>
  )
}

export default DataMapping
