import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const MainSectionContent = styled(Box)<{ bg: string }>`
  border-radius: 16px;
  background: transparent;
  width: 100%;
  flex-basis: 50%;
  padding: 24px 24px 0;
  overflow-y: auto;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 24px;
  }

  .main-section-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    .main-section-header__left {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 707px;

      .title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .description {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: pre-line;
      }

      .details-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        .details-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10.5px;

          &.second {
            //align-items: center;
            text-transform: capitalize;
          }

          &.last {
            //align-items: flex-end;
          }

          .details-item-title {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 510;
            line-height: normal;
            white-space: nowrap;
          }

          .details-item-text {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 20px;

            &.no-bold {
              font-weight: 400;
            }
          }
        }

        .section-divider {
          width: 1px;
          height: 51px;
          background: #4d444b;
          margin: 0;
        }
      }
    }

    .main-section-header__right {
      padding: 0 8px;

      .main-section-header__right-wrapper {
        a {
          padding: 8px 16px;
          border: 1px solid #998d96;
          background: #ffa9fc;
          border-radius: 100px;
          color: #590060;
          font-size: 16px;
          font-weight: 510;
          gap: 8px;
          text-decoration: none;
          display: flex;
          align-items: center;
          white-space: nowrap;

          &:hover {
            opacity: 0.9;
          }

          &:visited {
            color: #590060 !important;

            svg {
              path {
                fill: #590060 !important;
              }
            }
          }
        }
      }
    }
  }

  .MuiPaper-root.MuiAccordion-root {
    background: transparent;
    box-shadow: none !important;
    outline: 0 !important;
    margin-top: 40px;

    &::before {
      background: transparent;
    }

    &.Mui-expanded {
      margin: 40px 0 0;
    }
  }

  .MuiAccordion-root {
    background: transparent;

    .MuiAccordionSummary-root {
      background: transparent;
      border-bottom: 1px solid #4d444b;
      padding: 0;
      min-height: unset;

      .MuiAccordionSummary-content {
        margin: 0;

        .accordion-title {
          color: #fff;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 16px;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: #fff;
      }
    }

    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordionDetails-root {
            padding: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }
`

export const ContentInner = styled(Box)`
  padding: 40px 0 0 0;

  .performance-text {
    margin-bottom: 12.5px;
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .main-performance-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 32px;
  }
`

export const InsightsImageWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #292128;
  padding: 40px 0;
`
