import { Box, Popper as MuiPopper } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Popper = styled(MuiPopper)`
  position: absolute !important;
  top: 46px !important;
  z-index: 100000;
`

export const SearchVendorsContainer = styled(Box)<{ height?: string }>`
  border-radius: 8px;
  border: 1px solid #4d444b;
  background: #372a36;
  box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  //width: 420px;
  width: 300px;
  height: 100%;
  padding: 16px;
  overflow-y: hidden;
`

export const AutocompleteContainer = styled(Box)`
  position: relative;
  width: 100%;
`

export const SearchVendorsInputWrapper = styled(Box)<{ hasinputvalue?: string }>`
  width: 100%;

  & .MuiFormControl-root {
    width: 100%;
    border-radius: 4px;
    border: 0;
    outline: 0;
    height: 40px;

    & .MuiInputLabel-root {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;

      &.MuiInputLabel-shrink {
        font-family: Quicksand, sans-serif;

        &.Mui-focused {
          color: #ffa9fc;
          font-size: 12px;
          letter-spacing: 0.4px;
        }
      }
    }

    & .MuiOutlinedInput-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      height: 40px;
      padding: 0 8px;

      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        &:hover {
          cursor: ${({ hasinputvalue }) => (hasinputvalue && hasinputvalue === 'false' ? 'default' : 'pointer')};

          svg {
            path {
              fill: ${({ hasinputvalue }) => (hasinputvalue && hasinputvalue === 'false' ? '#867083' : '#fff')};
            }
          }
        }
      }

      input {
        padding-left: 0;
        margin-left: -2px;
      }

      fieldset {
        border: 1px solid #998d96;

        span {
          font-size: 8.5px;
        }
      }

      &:hover fieldset {
        border-color: #998d96;
      }

      &.Mui-focused {
        border: none;

        & fieldset {
          border: 2px solid #ffa9fc;
        }
      }
    }

    & .search-vendors-loading-indicator {
      color: #ffa9fc;
    }
  }
`

export const SearchVendorsResultsContainer = styled('div')`
  border-radius: 8px;
  z-index: 1;
  margin-top: 8px;

  .search-vendors-results-text {
    color: #867083;
    font-family: Quicksand, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 16px 0 8px;
  }

  .search-vendors-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 200px;

    .search-vendors-loading-progress {
      color: #ffa9fc;
    }
  }

  .search-vendors-empty-wrapper {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .search-vendors-empty-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .search-vendors-empty-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`

export const ResultsList = styled('ul')<{ hideborder?: string; items: number }>`
  font-family: Quicksand, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 8px;
  height: ${({ items }) => (items && items <= 5 ? `${40 * items}px` : !items ? 0 : '200px')};
  max-height: ${({ items }) => (items && items <= 5 ? `${40 * items}px` : !items ? 0 : '200px')};
  overflow-y: auto;
  height: auto;

  .no-options-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;

    .no-optons-text {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  ${({ hideborder }) =>
    hideborder &&
    hideborder === 'true' &&
    `
      border-radius: 0 0 8px 8px;
  `};

  ${({ items }) =>
    items > 5 &&
    `
    overflow-y: scroll;
  `};

  ::-webkit-scrollbar {
    width: 6px !important;
    background: #998d96;
  }

  ::-webkit-scrollbar-track {
    width: 6px !important;
    background: #998d96;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px !important;
    background: #ffa9fc;
    border-radius: 8px;
  }
`

export const NoResultsList = styled('ul')<{ hideborder?: string; length: number }>`
  box-sizing: border-box;
  margin-top: 0;
  padding: 0;

  .search-vendors-empty-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;

    .here-text {
      color: #ffa9fc;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .first {
    margin-bottom: 8px;
    margin-top: 16px;
  }
`

export const ResultListItem = styled('li')<{ iscustomvendor?: string }>`
  cursor: default;
  border-radius: 4px;

  .list-item-wrapper {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: default;
    list-style: none;
    background: transparent;
    height: 40px;
    border-radius: 4px;
    gap: 8px;
  }

  .option-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 274px;

    &.text-disabled {
      color: #7f747c;
    }
  }

  &:hover {
    cursor: pointer;
    background: #573f56;
  }

  ${({ iscustomvendor }) =>
    iscustomvendor &&
    iscustomvendor === 'true' &&
    `    
      &:hover {
        cursor: pointer;
        background: rgba(208, 188, 255, 0.08) !important;  
  
        &:last-of-type {
          box-shadow: none;
          background: transparent;
        }
      }
    
      &:last-of-type {
        background: transparent;
        
        &:hover {
          background: rgba(208, 188, 255, 0.08) !important;
        }
      }
    `}

  img {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
    object-fit: cover !important;
  }
`

export const IntegrationLogo = styled(Box)<{ logo: string }>`
  width: 24px;
  height: 24px;
  background: ${({ logo }) => `url("${logo}") no-repeat center`};
  background-size: cover;
  cursor: pointer;
`

export const NoIntegrationLogo = styled(Box)`
  border: 1px solid #342f32;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background: #d0c3cc;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1600px) and (max-height: 1079px) {
    width: 22px;
    height: 22px;
  }

  .no-integration-logo-letter {
    color: #342f32;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
