import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)``

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  gap: 40px;

  .header-item {
    display: flex;
    align-items: center;
    gap: 8px;

    .header-item-text {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;

      span {
        text-transform: capitalize;
      }
    }
  }
`

export const ChartWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  max-width: 1686px;
  margin-left: auto;
  margin-right: auto;

  .chart-container {
    width: 100%;
    height: 360px;
    max-width: 1724px;

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        background: #362e35;
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        border-radius: 4px;
        background: #372a36;
        z-index: 10000;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
          background-color: #372a36;
          position: absolute;
          left: 46%;
          top: 50px;
        }

        .tooltip-wrapper {
          padding: 8px;
          gap: 4px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;

          p {
            margin: 0;
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            white-space: nowrap;
          }

          .tooltip-title {
            font-weight: 590;
          }

          .tooltip-text {
            font-weight: 400;
          }
        }

        .prediction-text-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 232px;
          gap: 8px;
          margin-top: -4px;

          .tooltip-performance {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          svg {
            margin-left: -6px;
          }
        }

        .tooltip-units-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 232px;

          .tooltip-units-prediction {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-performance {
          margin: 8px 0;

          &.prediction-date {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-top: 2px;
          }
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`
