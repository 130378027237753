import { IUserProfile } from '@frontegg/rest-api'
import { Row } from 'react-table'
import {
  AnalyzerAnalysisItem,
  CategoryFramework,
  ChartDataValue,
  ConfiguredCPI,
  CPIManifest,
  HistoryPerformanceData,
  Insight,
  IntegrationConfigSyncStatus,
  NistFunction,
  OnyxiaFunction
} from '../models'
import dayjs from 'dayjs'
import axios from './axios'
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns'
import unique from 'lodash.uniq'

export enum Permission {
  /* User Management */
  'UsersSelfPasswordUpdate' = 'users.self.password.update',
  'UsersSelfProfileView' = 'users.self.profile.view',
  'UsersSelfProfileUpdate' = 'users.self.profile.update',

  /* Questionnaire */
  'QuestionnaireView' = 'questionnaire.view',
  'QuestionnaireUpdate' = 'questionnaire.update',

  /* News Feed */
  'InsightsFeedView' = 'insights.feed.view',

  /* Subscription Management */
  'SubscriptionUpdate' = 'subscription.update',
  'SubscriptionDelete' = 'subscription.delete',
  'UsersProfileView' = 'users.profile.view',
  'UsersProfileUpdate' = 'users.profile.update',
  'UsersInvite' = 'users.invite',
  'UsersRoleUpdate' = 'users.role.update',
  'UsersPasswordUpdate' = 'users.password.update',

  /* CPI Library */
  'CPILibraryView' = 'cpi.library.view',
  'CPIConfigView' = 'cpi.config.view',
  'CPIConfigIntegrationView' = 'cpi.config.integration.view',
  'CPIConfigIntegrationUpdate' = 'cpi.config.integration.update',

  /* CPI Dashboard */
  'DashboardCPIView' = 'dashboard.cpi.view',
  'DashboardCPIConfigView' = 'dashboard.cpi.config.view',
  'DashboardCPIConfigUpdate' = 'dashboard.cpi.config.update',
  'DashboardCPIDataExport' = 'dashboard.cpi.data.export',
  'DashboardViewChange' = 'dashboard.view.change',
  'DashboardViewExport' = 'dashboard.view.export',

  /* Configuration */
  'ConfigGlobalView' = 'config.global.view',
  'ConfigGlobalSecurityFrameworkUpdate' = 'config.global.security_framework.update',
  'ConfigIntegrationsList' = 'config.integrations.list',
  'ConfigIntegrationsConfigView' = 'config.integrations.config.view',
  'ConfigIntegrationsConfigEdit' = 'config.integrations.config.edit',
  'ConfigIntegrationsConfigAdd' = 'config.integrations.config.add',

  /* Audit Log */
  'AuditView' = 'audit.view',

  /* Account Management  */
  'AccountManagementView' = 'account.page.view',

  /* SSM */
  'SSMView' = 'ssm.view',
  'SSMEdit' = 'ssm.edit',

  /* Asset Coverage Analyzer */
  'ACAView' = 'aca.view',
  'ACAEdit' = 'aca.edit'
}

export enum Role {
  Default = 'default',
  Owner = 'owner',
  Admin = 'admin',
  CPIManager = 'cpi_manager',
  Viewer = 'viewer',
  Demo = 'demo'
}

export enum CPIColor {
  'Healthy' = 'rgba(174, 213, 129, 0.20)',
  'Problematic' = 'rgba(255, 138, 128, 0.20)',
  'Initializing' = 'rgba(255, 224, 130, 0.30)'
}

/**
 * Returns a promise that is resolved after given ms
 * @param ms Milliseconds after which the promise is resolved
 * @returns
 */
export const delay = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

/*
 * Capitalize the first letter of a string
 * */
export const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const customSortByOrder: any = (importOrder: string[]) => {
  return importOrder.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    }
  }, {})
}

export const sanitizeString = (str: string, char: string, replaceValue: string) => {
  return str.replace(char, replaceValue)
}

export const dateFormat = {
  long: 'MMMM DD, YYYY',
  short: 'MMM DD, YYYY',
  shortUnComma: 'MMM DD YYYY',
  simple: 'MMM DD',
  chartAxis: 'MMM. DD',
  chartAxisRange: 'MMM DD',
  pastYear: "MMM 'YY",
  pastYearLong: "MMMM 'YY",
  day: 'DD',
  historical: 'MM/YY',
  historicalDay: 'MM/DD/YY',
  auditLogDefault: 'MM/DD/YYYY',
  customLabel: 'YYYY-MM-DD',
  lastUpdate: 'ddd, MMM DD YYYY',
  lastUpdateHour: 'MMM DD, YYYY hh:mm A'
}

export const hasPermission = (requested: Permission, user: IUserProfile | null | undefined): boolean => {
  if (!user) {
    return false
  }

  const permissions = user.permissions
  const userHasPermission = permissions.find((n) => n.key === requested)

  return !!userHasPermission
}

export const hasAnyPermission = (requested: Permission[], user: IUserProfile | null | undefined): boolean => {
  if (!user) {
    return false
  }

  const userPermissions = user.permissions
  const userHasPermission = userPermissions.some((n) => requested.includes(n.key as Permission))

  return userHasPermission
}

export const hasRole = (requested: Role, user: IUserProfile | null | undefined): boolean => {
  if (!user) {
    return false
  }

  const roles = user.roles
  const userRole = roles.find((n) => n.key === requested)

  return !!userRole
}

export const hasAnyRole = (requested: Role[], user: IUserProfile | null | undefined): boolean => {
  if (!user) {
    return false
  }

  const userRoles = user.roles
  const userHasRole = userRoles.some((n) => requested.includes(n.key as Role))

  return userHasRole
}

export const isViewerRole = (user: IUserProfile | null | undefined): boolean => {
  return user?.roles[0].key === 'viewer'
}

export const stringOfNumberedListToArray = (input: string, name: string): RegExpMatchArray | null => {
  if (name === 'rapid7_vm') {
    const regex = /(\d+\.\s*(?:(?!<a\s[^>]*>|<\/a>)[\s\S])*?)(?=\d+\.|$)/g
    const matches = input.split(regex).filter((item) => item.trim() !== '') // Filter out empty items
    matches[9] = matches[9] + matches[10]
    matches.splice(-1)
    return matches
  }

  if (name === 'rapid7_appsec') {
    const regex = /(\d+\.\s*(?:(?!<a\s[^>]*>|<\/a>)[\s\S])*?)(?=\d+\.|$)/g
    const matches = input.split(regex).filter((item) => item.trim() !== '') // Filter out empty items
    matches[10] = matches[10] + matches[11]
    matches.splice(-1)
    return matches
  }

  if (name === 'knowbe4_kmsat') {
    const regex = /(\d+\.\s*(?:(?!<a\s[^>]*>|<\/a>)[\s\S])*?)(?=\d+\.|$)/g
    const arr = input.split(regex).filter((item) => item.trim() !== '')
    const firstItems = arr.slice(0, 4)
    const lastItemStr = arr.slice(Math.max(arr.length - 12, 0)).join('')
    return [...firstItems, lastItemStr]
  }

  const regex = /(\d+\.\d*)\s?(.*?)(?=\d+\.|$)/gs
  return input.match(regex)
}

export const formatCPIDisplayNameForURL = (name: string) => {
  return name.toLowerCase()
}

/*
 * Returns desc = red over green, or asc = green over red
 * */
export const getHealthDirection = (zoneColor: string, lastValue: number, sla: number) => {
  const isPositive = lastValue <= sla
  if (zoneColor === 'green' && isPositive) return 'desc'
  if (zoneColor === 'red' && isPositive) return 'asc'
  if (zoneColor === 'green' && !isPositive) return 'asc'
  if (zoneColor === 'red' && !isPositive) return 'desc'

  return 'desc'
}

export const checkIsGreen = (healthDirection: string, lastValue: number, sla: number) => {
  return (healthDirection === 'desc' && lastValue <= sla) || (healthDirection === 'asc' && lastValue > sla)
}

export const configuredActiveIntegrations = (integrations: any, manifest: any) => {
  const configuredIntegrationNames = integrations.map((item: { name: string }) => item.name)
  return manifest.supported_integrations.filter((item: { name: string }) =>
    configuredIntegrationNames.includes(item.name)
  )
}

export const months = [
  'January',
  'Feburary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const getTrendsRangeValues = (data: ChartDataValue[] | HistoryPerformanceData[]): number[] => {
  const arrStart = data[0]
  const arrStartMonthNumber = dayjs(arrStart.xValue).get('month')
  const arrStartMonth = months[arrStartMonthNumber]
  const arrStartYear = dayjs(arrStart.xValue).year()
  const startDate = new Date(`${arrStartMonth}/01/${arrStartYear}`)

  const arrEndValue = data[data.length - 1].xValue
  const daysInLastMonth = dayjs(arrEndValue).daysInMonth()
  const arrEndMonthNumber = dayjs(arrEndValue).get('month')
  const arrEndYear = dayjs(arrEndValue).year()
  const arrEndMonth = months[arrEndMonthNumber]
  const endDate = new Date(`${arrEndMonth}/${daysInLastMonth}/${arrEndYear}`)

  const s = new Date(startDate).getTime()
  const e = new Date(endDate).getTime()

  return [s, e]
}

export const getFirstLastWeekDay = (startDate: string, endDate: string) => {
  const n1 = dayjs(startDate).startOf('w')
  const n2 = dayjs(n1).add(1, 'day')
  const k1 = dayjs(n2).format(dateFormat.short)

  const n3 = dayjs(endDate).endOf('w')
  const n4 = dayjs(n3).add(1, 'day')
  const k2 = dayjs(n4).format(dateFormat.short)

  return [k1, k2]
}

export const removeDuplicates = (data: any) => {
  return data.filter((value: any, index: number) => data.indexOf(value) === index)
}

export const cpiCategoryColors: Record<string, { background: string; color: string }> = {
  'Detection & Response': {
    background: 'rgba(177, 92, 15, 1)',
    color: 'rgba(255, 255, 255, 1)'
  },
  'Vulnerability Management': {
    background: 'rgba(125, 117, 7, 1)',
    color: 'rgba(255, 255, 255, 1)'
  },
  'Training & Awareness': {
    background: 'rgba(120, 26, 126, 1)',
    color: 'rgba(255, 255, 255, 1)'
  },
  'Cloud Security': {
    background: 'rgba(250, 195, 100, 1)',
    color: 'rgba(30, 26, 29, 1)'
  },
  'Identity & Access Management': {
    background: 'rgba(142, 154, 255, 1)',
    color: 'rgba(30, 26, 29, 1)'
  },
  'Network Security': {
    background: 'rgba(71, 116, 130, 1)',
    color: 'rgba(255, 255, 255, 1)'
  }
}

export const getCpiCategoryColors = (
  category: string,
  framework: CategoryFramework = CategoryFramework.Onyxia
): { background: string; color: string } => {
  const defaultColors = {
    background: 'rgba(193, 183, 188, 1)',
    color: 'rgba(30, 26, 29, 1)'
  }

  const colorForReport = FunctionColorsForReports[framework][category]
  if (colorForReport) {
    return { background: colorForReport.category_background, color: colorForReport.category_color }
  }

  return defaultColors
}

export const OnyxiaCategoryColorsForReports: Record<string, { category_background: string; category_color: string }> = {
  [OnyxiaFunction.DetectionAndRespond]: {
    category_background: 'rgba(177, 92, 15, 1)',
    category_color: 'rgba(255, 255, 255, 1)'
  },
  [OnyxiaFunction.VulnerabilityManagement]: {
    category_background: 'rgba(125, 117, 7, 1)',
    category_color: 'rgba(255, 255, 255, 1)'
  },
  [OnyxiaFunction.TrainingAndAwareness]: {
    category_background: 'rgba(120, 26, 126, 1)',
    category_color: 'rgba(255, 255, 255, 1)'
  },
  [OnyxiaFunction.CloudSecurity]: {
    category_background: 'rgba(250, 195, 100, 1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [OnyxiaFunction.IAM]: {
    category_background: 'rgba(142, 154, 255, 1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [OnyxiaFunction.NetworkSecurity]: {
    category_background: 'rgba(71, 116, 130, 1)',
    category_color: 'rgba(255, 255, 255, 1)'
  },
  [OnyxiaFunction.DeviceManagement]: {
    category_background: 'rgba(219, 219, 219, 1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [OnyxiaFunction.MailAndWebSecurity]: {
    category_background: '#072845',
    category_color: '#fff'
  },
  [OnyxiaFunction.ApplicationSecurity]: {
    category_background: '#D279DF',
    category_color: '#1E1A1D'
  }
}

export const NistFunctionColorsForReports: Record<string, { category_background: string; category_color: string }> = {
  [NistFunction.Detect.toLowerCase()]: {
    category_background: 'rgba(255, 222, 106,1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [NistFunction.Govern.toLowerCase()]: {
    category_background: 'rgba(255, 158, 71, 1)',
    category_color: 'rgba(255, 255, 255, 1)'
  },
  [NistFunction.Identify.toLowerCase()]: {
    category_background: 'rgb(142, 154, 255,1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [NistFunction.Protect.toLowerCase()]: {
    category_background: 'rgba(199, 126, 218,1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [NistFunction.Recover.toLowerCase()]: {
    category_background: 'rgba(153, 253, 192, 1)',
    category_color: 'rgba(30, 26, 29, 1)'
  },
  [NistFunction.Respond.toLowerCase()]: {
    category_background: 'rgba(255, 127, 107,1)',
    category_color: 'rgba(30, 26, 29, 1)'
  }
}

export const FunctionColorsForReports = {
  [CategoryFramework.Onyxia]: OnyxiaCategoryColorsForReports,
  [CategoryFramework.NIST]: NistFunctionColorsForReports,
  [CategoryFramework.Custom]: OnyxiaCategoryColorsForReports
}

export const extractSeverityValues = (cpiConfig: ConfiguredCPI | null, manifest: CPIManifest | null) => {
  const values: any = {}

  if (cpiConfig) {
    if (cpiConfig.user_input.visualization_param.identical_section_values) {
      values.sla = cpiConfig.user_input.visualization_param.identical_value
      values.isNoSeveritiesCpi = true
      if (manifest?.ui.trend_chart.user_input.visualization_param.sections) {
        manifest.ui.trend_chart.user_input.visualization_param.sections.forEach(({ name, value }) => {
          values[name] = value
        })
      }
    }
    if (
      !cpiConfig.user_input.visualization_param.identical_section_values &&
      cpiConfig.user_input.visualization_param.sections
    ) {
      cpiConfig.user_input.visualization_param.sections.forEach(({ name, value }) => {
        values[name] = value
      })
    }
    if (cpiConfig.user_input.formula_params && cpiConfig.user_input.formula_params.length > 0) {
      if (cpiConfig.user_input.formula_params[0].sections.length > 0) {
        cpiConfig.user_input.formula_params[0].sections.forEach(({ name, value }) => {
          values[name] = value
        })
      } else if (cpiConfig.user_input.formula_params[0]?.value) {
        values[cpiConfig.user_input.formula_params[0].name] = cpiConfig.user_input.formula_params[0].value
      }
    }
  } else if (manifest) {
    if (manifest.ui.trend_chart.user_input.visualization_param.identical_section_values) {
      values.sla = manifest.ui.trend_chart.user_input.visualization_param.identical_value
      values.isNoSeveritiesCpi = true
      manifest.ui.trend_chart.user_input.visualization_param.sections.forEach(({ name, value }) => {
        values[name] = value
      })
    }
    if (!manifest.ui.trend_chart.user_input.visualization_param.identical_section_values) {
      manifest.ui.trend_chart.user_input.visualization_param.sections.forEach(({ name, value }) => {
        values[name] = value
      })
    }
    if (
      manifest.ui.trend_chart.user_input.formula_params &&
      manifest.ui.trend_chart.user_input.formula_params.length > 0
    ) {
      if (manifest.ui.trend_chart.user_input.formula_params[0].sections.length > 0) {
        manifest.ui.trend_chart.user_input.formula_params[0].sections.forEach(({ name, value }) => {
          values[name] = value
        })
      } else if (manifest.ui.trend_chart.user_input.formula_params[0]?.value) {
        values[manifest.ui.trend_chart.user_input.formula_params[0].name] =
          manifest.ui.trend_chart.user_input.formula_params[0].value
      }
    }
  }

  return values
}

export function alphanumericSort<T extends AnalyzerAnalysisItem>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
): 0 | 1 | -1 {
  const valueA = (rowA as any).original[columnId]
  const valueB = (rowB as any).original[columnId]

  if (!isNaN(valueA) || !isNaN(valueB)) {
    if (valueA > valueB) return 1
    if (valueB > valueA) return -1
    return 0
  } else {
    if (valueA.toLowerCase() > valueB.toLowerCase()) return 1
    if (valueB.toLowerCase() > valueA.toLowerCase()) return -1
    return 0
  }
}

export function chronologicalSort<T extends AnalyzerAnalysisItem>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
): number {
  const valueA = (rowA as any).original[columnId]
  const valueB = (rowB as any).original[columnId]

  return +new Date(valueA) - +new Date(valueB)
}

export enum AllCPIsReportType {
  GENERAL = 'general',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly'
}

export const breadcrumbs = {
  profile: [{ name: 'Your Profile' }],
  userManagement: [{ name: 'User Management' }],
  integrations: [{ name: 'Integrations' }],
  auditLog: [{ name: 'Audit Logs' }],
  accountManagement: [{ name: 'Account Settings' }],
  frameworksConfiguration: [{ name: 'Frameworks Configuration' }],
  SSM: [{ name: 'Security Stack Map' }],
  ACA: [{ name: 'Asset Coverage Analyzer' }],
  insights: [{ name: 'Insights' }],
  library: [{ name: 'Cyber Performance Indicator Library' }],
  dashboard: [{ name: 'Dashboard' }]
}

export const fetchSyncStatus = async (integrationId: string): Promise<IntegrationConfigSyncStatus | null> => {
  const res = await axios.get<{ data: IntegrationConfigSyncStatus }>(
    `/api/v3/integrations/config/${integrationId}/status`
  )

  if (res.status.toString().includes('20')) {
    return res.data.data
  }

  return null
}

export const equalPiecesGen = function* (count: number, value: number) {
  let mod = value % count
  const weight = (value - mod) / count
  for (let i = 0; i < count; i++) {
    yield mod > 0 ? weight + 1 : weight
    mod--
  }

  return weight
}
export const equalWeightsGen = function* (count: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield* equalPiecesGen(count, 100)
}

export const formatNumberCommas = (x: number | string) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export const camelToSnake = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1')

  return result.split(' ').join('_').toLowerCase()
}

export const groupInsightsByDate = (data: Insight[], sortDirection: string): Insight[][] => {
  const thisWeek: Insight[] = []
  const pastWeek: Insight[] = []
  const pastMonth: Insight[] = []
  const pastYear: Insight[] = []

  data.forEach((d) => {
    if (d.data) {
      const date = d.data.insight_date
      const startOfWeekDate = startOfWeek(new Date(date || ''), { weekStartsOn: 1 })
      const endOfWeekDate = endOfWeek(new Date(date || ''), { weekStartsOn: 1 })

      const isBetweenToday = dayjs().isBetween(startOfWeekDate, endOfWeekDate)

      if (isBetweenToday) {
        thisWeek.push(d)
      } else {
        const lastWeekDay = dayjs().subtract(7, 'days').format(dateFormat.customLabel)
        const lastWeekStart = startOfWeek(new Date(lastWeekDay || ''), { weekStartsOn: 1 })
        const lastWeekEnd = endOfWeek(new Date(lastWeekDay || ''), { weekStartsOn: 1 })
        const isBetweenLastWeek = dayjs(date).isBetween(lastWeekStart, lastWeekEnd)

        if (isBetweenLastWeek) {
          pastWeek.push(d)
        } else {
          const startOfMonthDate = startOfMonth(new Date(date || ''))
          const endOfMonthDate = endOfMonth(new Date(date || ''))

          const isBetweenLastMonth = dayjs().isBetween(startOfMonthDate, endOfMonthDate)

          if (isBetweenLastMonth) {
            pastMonth.push(d)
          } else {
            pastYear.push(d)
          }
        }
      }
    }
  })

  const result: Insight[][] = [thisWeek, pastWeek, pastMonth, pastYear]

  return sortDirection === 'DESC' ? result : result.reverse()
}

const groupInsightsByCpiId = (data: Insight[], sortDirection: string) => {
  const groups = unique(
    data.map((n) => (n.type === 'ssm_insight' ? 'No CPI' : (n.data?.cpi_display_name || '').slice(-3)))
  ).sort()
  const result = groups.map((group) =>
    data.filter((dataItem) =>
      dataItem.type === 'ssm_insight' ? group === 'No CPI' : (dataItem.data?.cpi_display_name || '').slice(-3) === group
    )
  )

  return sortDirection === 'DESC' ? result : result.reverse()
}

const groupInsightsByType = (data: Insight[], sortDirection: string) => {
  const groups = unique(data.map((n) => n.data?.header || '')).sort()
  const result = groups.map((group) => data.filter((dataItem) => (dataItem.data?.header || '') === group))

  return sortDirection === 'DESC' ? result : result.reverse()
}

export const groupInsights = (data: Insight[], sortBy: string, sortDirection: string): Insight[][] => {
  if (sortBy === 'insight_date') {
    return groupInsightsByDate(data, sortDirection)
  }
  if (sortBy === 'cpi_name') {
    return groupInsightsByCpiId(data, sortDirection)
  }
  if (sortBy === 'type') {
    return groupInsightsByType(data, sortDirection)
  }

  return [data]
}
