import { FC, useMemo, useState } from 'react'
import { ListItemText, Box, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { SelectDropdownIcon } from 'ui/components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './jiraDropdown.styles'
import useSearchLabel from 'hooks/useSearchLabel'
import JiraDropdownSearch from './JiraDropdownSearch'
import { getIntegrationLogo } from '../../../../coverage-analyzer/utils'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  maxHeight?: string
  hideSearch?: boolean
  label?: string
  inputWidth?: string
  width?: string
  showIcons?: boolean
  maxWidth?: string
  inputPadding?: string
  wrapperSx?: Record<string, unknown>
  disabledOptions?: string[]
}

const JiraDropdown: FC<Props> = ({
  label,
  options,
  inputWidth,
  handleChange,
  placeholder,
  maxHeight,
  handleReset,
  minWidth,
  value,
  showIcons,
  width,
  hideSearch = false,
  maxWidth,
  inputPadding,
  wrapperSx,
  disabledOptions,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const convertedOptions = useMemo(() => {
    return options.map((item) => {
      return {
        label: item,
        name: item
      }
    })
  }, [options])

  const { search, setSearch, labelOptions, highlightMatch } = useSearchLabel(convertedOptions, handleReset)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper className="jira-dropdown-wrapper" {...wrapperSx}>
      {label && <Typography className="label-text">{label}</Typography>}
      <LibraryFormControl
        sx={{ minWidth, maxWidth: maxWidth || 'unset' }}
        isselected={
          anchorEl || (placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')))
            ? 'true'
            : 'false'
        }
      >
        <LibrarySelect
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none',
                width: width || '',
                minWidth: minWidth || '',
                '.MuiList-root': {
                  ...CategoryMenuProps.PaperProps.sx['.MuiList-root'],
                  padding: hideSearch ? '8px !important' : '0 8px 8px !important'
                }
              },
              style: {
                ...CategoryMenuProps.PaperProps.style,
                maxHeight: maxHeight ?? CategoryMenuProps.PaperProps.style.maxHeight
              }
            },
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned.length} selected`
            }
            if (!selected && placeholder) return placeholder

            return `${selected}`
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{
            minWidth,
            width: inputWidth ?? 'auto',
            paddingRight: inputPadding || '',

            '.MuiOutlinedInput-input': {
              fontSize: !value && placeholder ? '16px !important' : '',
              fontWeight: !value && placeholder ? '400 !important' : '',
              color: !value && placeholder ? '#867083 !important' : '',
              opacity: !value && placeholder ? '1 !important' : ''
            }
          }}
          {...rest}
        >
          {!hideSearch && <JiraDropdownSearch search={search} setSearch={setSearch} placeholder="Search..." />}

          {!hideSearch && <Typography className="results-header-text">RESULTS ({labelOptions.length})</Typography>}

          <Box className="list-wrapper">
            {labelOptions.map((option) => (
              <MenuItem
                key={option.name}
                value={option.name}
                disabled={disabledOptions && disabledOptions.includes(option.name)}
                onClick={() => {
                  handleChange({ target: { value: option.name } })
                  setAnchorEl(null)
                }}
              >
                {showIcons && (
                  <img
                    src={getIntegrationLogo(option.name)}
                    alt=""
                    width="24px"
                    height="24px"
                    className="integration-icon"
                  />
                )}
                <ListItemText primary={highlightMatch(option.label)} />
              </MenuItem>
            ))}
          </Box>
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default JiraDropdown
