import { useRef, forwardRef, Dispatch, SetStateAction, useEffect } from 'react'

interface Props {
  width: number
  height: number
  margin: {
    top: number
    right: number
    bottom: number
    left: number
  }
  setWidth: Dispatch<SetStateAction<number>>
  setHeight: Dispatch<SetStateAction<number>>
}

const InsightsChart = forwardRef<any, Props>(({ setWidth, setHeight, width, height, margin }, ref) => {
  const { containerRef, svgRef } = ref as any
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!containerRef.current) return

    const observeTarget = containerRef.current
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.contentRect.width)
        setHeight(entry.contentRect.height - 61)
      })
    })
    resizeObserver.observe(observeTarget)

    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [containerRef])

  return (
    <>
      <div ref={tooltipRef} className="data-point-tooltip" />
      <svg
        ref={svgRef}
        id="svg-d3"
        className="svg-d3"
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      />
    </>
  )
})

export default InsightsChart
