import { FC } from 'react'
import { Typography, OutlinedInput, InputProps } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { FormControl } from './styles/controlledTextField.styles'

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-background-clip': 'text !important',
            '-webkit-text-fill-color': '#d0c3cc'
          },
          '&::placeholder': {
            overflow: 'hidden',
            color: '#867083',
            textOverflow: 'ellipsis',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            opacity: 1
          }
        }
      }
    }
  }
})

type Props = InputProps & {
  name: string
  label: string
  helperText?: string
}

const ControlledTextFieldCustomLabel: FC<Props> = ({ label, helperText, ...rest }) => (
  <ThemeProvider theme={theme}>
    <FormControl
      variant="standard"
      className="controlled-text-field-custom-label-form-control"
      iserrored={helperText ? 'true' : 'false'}
    >
      <Typography className="form-control-label">{label}</Typography>
      <OutlinedInput {...rest} />
      {helperText && <Typography className="helper-text">{helperText}</Typography>}
    </FormControl>
  </ThemeProvider>
)

export default ControlledTextFieldCustomLabel
