import { ChangeEvent, FC } from 'react'
import { Box } from '@mui/material'
import JiraDropdownDefaults from '../jira-dropdowns/JiraDropdownDefaults'
import JiraDropdownMultiple from '../jira-dropdowns/JiraDropdownMultiple'

interface Props {
  foundDefaults: any
  thisCustomOptions: any
  thisDefaultValue: any
  handleApplyDefaultValues: any
  setDataMappingDefaultsValues: any
  optionValues: any
  dataMappingValues: any
  setDataMappingValues: any
  setOptionValues: any
}

const JiraMappingFields: FC<Props> = ({
  foundDefaults,
  thisCustomOptions,
  thisDefaultValue,
  handleApplyDefaultValues,
  setDataMappingDefaultsValues,
  optionValues,
  dataMappingValues,
  setDataMappingValues,
  setOptionValues
}) => (
  <>
    {/* DEFAULTS */}
    <Box>
      <JiraDropdownDefaults
        options={foundDefaults.dataMappingDefaultValues.map((n: any) => ({
          id: n.id,
          name: n.name,
          label: n.name
        }))}
        customOptions={
          thisCustomOptions && thisCustomOptions.options.length > 0
            ? thisCustomOptions.options.map((n: any) => ({
                id: n.id,
                name: n.name,
                label: n.name,
                statusCategory: n.statusCategory
              }))
            : []
        }
        value={thisDefaultValue}
        multiple
        handleApply={handleApplyDefaultValues}
        handleChange={(e: any, isDefault?: boolean) => {
          setDataMappingDefaultsValues((pre: any) => ({
            ...pre,
            isDefault: Boolean(isDefault),
            [foundDefaults.jiraField.apiName]: e.target.value
          }))
        }}
        minWidth="250px"
      />
    </Box>

    <Box className="separator" />

    {/* CRITICAL */}
    <Box>
      <JiraDropdownMultiple
        options={optionValues}
        value={dataMappingValues.critical_severity.map((c: any) => (c.name ? c.name : c))}
        multiple
        handleChange={(e: ChangeEvent<any>) => {
          const mappedValue = dataMappingValues.critical_severity.filter((c: any) => !e.target.value.includes(c))
          setDataMappingValues((pre: any) => ({
            ...pre,
            critical_severity: e.target.value
          }))

          setOptionValues(
            optionValues
              .map((c: any) =>
                e.target.value.includes(c.name)
                  ? { ...c, disabled: true, enabledForField: 'critical_severity' }
                  : mappedValue.includes(c.name)
                  ? {
                      ...c,
                      disabled: mappedValue.length > 0 && !mappedValue.includes(c.name),
                      enabledForField: ''
                    }
                  : c
              )
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          )
        }}
        minWidth="250px"
        field="critical_severity"
      />
    </Box>

    {/* HIGH */}
    <Box>
      <JiraDropdownMultiple
        options={optionValues}
        value={dataMappingValues.high_severity.map((c: any) => (c.name ? c.name : c))}
        multiple
        handleChange={(e: ChangeEvent<any>) => {
          const mappedValue = dataMappingValues.high_severity.filter((c: any) => !e.target.value.includes(c))
          setDataMappingValues((pre: any) => ({
            ...pre,
            high_severity: e.target.value
          }))

          setOptionValues(
            optionValues
              .map((c: any) =>
                e.target.value.includes(c.name)
                  ? { ...c, disabled: true, enabledForField: 'high_severity' }
                  : mappedValue.includes(c.name)
                  ? {
                      ...c,
                      disabled: mappedValue.length > 0 && !mappedValue.includes(c.name),
                      enabledForField: ''
                    }
                  : c
              )
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          )
        }}
        minWidth="250px"
        field="high_severity"
      />
    </Box>

    {/* MEDIUM */}
    <Box>
      <JiraDropdownMultiple
        options={optionValues}
        value={dataMappingValues.medium_severity.map((c: any) => (c.name ? c.name : c))}
        multiple
        handleChange={(e: ChangeEvent<any>) => {
          const mappedValue = dataMappingValues.medium_severity.filter((c: any) => !e.target.value.includes(c))
          setDataMappingValues((pre: any) => ({
            ...pre,
            medium_severity: e.target.value
          }))

          setOptionValues(
            optionValues
              .map((c: any) =>
                e.target.value.includes(c.name)
                  ? { ...c, disabled: true, enabledForField: 'medium_severity' }
                  : mappedValue.includes(c.name)
                  ? {
                      ...c,
                      disabled: mappedValue.length > 0 && !mappedValue.includes(c.name),
                      enabledForField: ''
                    }
                  : c
              )
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          )
        }}
        minWidth="250px"
        field="medium_severity"
      />
    </Box>

    {/* LOW */}
    <Box>
      <JiraDropdownMultiple
        options={optionValues}
        value={dataMappingValues.low_severity.map((c: any) => (c.name ? c.name : c))}
        multiple
        handleChange={(e: ChangeEvent<any>) => {
          const mappedValue = dataMappingValues.low_severity.filter((c: any) => !e.target.value.includes(c))
          setDataMappingValues((pre: any) => ({
            ...pre,
            low_severity: e.target.value
          }))

          setOptionValues(
            optionValues
              .map((c: any) =>
                e.target.value.includes(c.name)
                  ? { ...c, disabled: true, enabledForField: 'low_severity' }
                  : mappedValue.includes(c.name)
                  ? {
                      ...c,
                      disabled: mappedValue.length > 0 && !mappedValue.includes(c.name),
                      enabledForField: ''
                    }
                  : c
              )
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
          )
        }}
        minWidth="250px"
        field="low_severity"
      />
    </Box>
  </>
)

export default JiraMappingFields
